import React, { FC, useEffect } from "react"

import {
  Form,
  Input,
  Modal, Row,

} from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { parseValuesBeforeInsertInForms } from "../../../utils/data"
import {showErrorMessage, showErrorNotification, showFormErrors, showSuccessMessage} from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  editData: { id:number,contact_info:string,name:string }
  filters: any
}

const EditModal: FC<Props> = ({ isOpen, setIsOpen, editData }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.item)
  const { suppliersUpdate, setSuppliersUpdate, setSuppliersLoading } =
    useActions()


  const editItem = (values: any) => {
    const data = {
      ...values,
      phone:values.phone === undefined ? null : values.phone,
      id:editData.id
    }
    suppliersUpdate(
      data,
      () => {
        showSuccessMessage("Поставщик успешно обновлен.")
        setIsOpen(false)
        setSuppliersUpdate(true)
      },
      (errors) => {
        const errorObject = errors as unknown as { code:number,message:string }
        try{
          if(errorObject.code === 253){
            showErrorNotification(errors.message)
          }else {
            showFormErrors(form, errors)
          }
        }catch(err){
          console.log(err)
        }
      }
    )
  }

  const getEditData = () => {
    setSuppliersLoading(true)
    const values = parseValuesBeforeInsertInForms({ ...editData,phone:editData.contact_info })
    form.setFields(values)
    setSuppliersLoading(false)
  }

  useEffect(() => {
    if (isOpen && editData.id) {
      getEditData()
    }
  }, [ isOpen ])

  return (
    <Modal
      title="Редактирование поставщика"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
      width={"30%"}
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={editItem}
        layout="vertical"
        requiredMark={true}
      >
        <Row gutter={16} style={{ columnGap:"25px",width:"100%" }}>
          <Form.Item
            label="Поставщик"
            name="name"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <Input maxLength={255} />
          </Form.Item>
          <Form.Item
            label="Номер телефона"
            name="phone"
          >
            <Input maxLength={30} />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditModal
