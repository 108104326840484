import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined, DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { showSuccessMessage } from "../../utils/ui"

const ExportPage: FC = () => {
  const { tableData, isLoading } = useTypedSelector((state) => state.export)
  const { getExportData, exportDeleteFile,exportDownloadFile } = useActions()

  const [ tempData, setTempData ] = useState(tableData)

  useEffect(() => {
    setTempData(tableData)
  }, [ tableData ])

  useEffect(() => {
    getExportData()
  }, [])

  const partnersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 20,

      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      width:110,
      render: (value) => <div style={{ width: 150 }}>{value}</div>
    },
    {
      title: "Название файла",
      dataIndex: "export_name",
      key: "export_name",
      width: 100,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Кто создал",
      dataIndex: "user_name",
      key: "user_name",
      width: 130,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 130,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val === 0 ? "Файл готовится" : "Файл готов"}>
          {val === 0 ? "Файл готовится" : "Файл готов"}
        </Tooltip>
      )
    },
    {
      title: "Дата готовности файла",
      dataIndex: "updated_at",
      key: "updated_at",
      width:110,
      render: (value) => <div style={{ width: 150 }}>{value}</div>
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 50,
      render: (value, record) => {
        return (
          record.status !== 0 && <Space style={{ display:"flex",justifyContent:"center" }} direction="horizontal">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                exportDownloadFile(record.id, record.export_name || "report.csv")
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: "Вы уверены что хотите удалить файл?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "Да",
                  okType: "danger",
                  cancelText: "Нет",
                  onOk() {
                    exportDeleteFile(record.id, () => {
                      showSuccessMessage("файл успешно удален.")
                      getExportData()
                    })
                  }
                })
              }}
              danger
            />
          </Space>
        )
      }
    }
  ]


  return (
    <MainLayout pageTitle="Экспорт">
      <div className="page-wrapper">
        <PageHead
          title="Экспорт"
          extra={
            <div>
            </div>
          }
        />
        <div className="page-content">
          <Table
              virtual={true}
            columns={partnersColumns}
            dataSource={tempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            size="small"
            bordered
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default ExportPage
