import React, { FC, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Form,
  Input,
  Row,
  Space,
  Spin,
  DatePicker, Switch,
  Col,
  Select
} from "antd"

import Counter from "../../../common/components/counter/Counter"
import AutoWidthSelect from "../../../common/components/selects/AutoWidthSelect"
import { defaultStockFBSFilters } from "../../../common/constants/filters"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { marketPlacePictureMap, removeObjectEmptyKeys } from "../../../utils/data"


interface Props {
  isLoading: boolean
  sorting: { [x: string]: string }
  setFilters: (v: any) => any
}

const FilterHeading = ({ text }:{text:string}) => (
  <p style={{ fontWeight:600,fontSize:16 }}>{text}</p>
)

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [ form ] = Form.useForm()
  const { pagesData, filters, filterList } = useTypedSelector((state) => state.stockFBS)
  const { stockFBSGetList } = useActions()
  const [ activeCards, setActiveCards ] = useState<any>({
    partner_id: null,
    marketplace_id: 1,
    status_id: null,
    not_collected: false,
    no_label: false,
    error_on_marketplace: false,
  })

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values)
    const data = {
      ...cleanedValues,
      ...(values.delivery_date && { delivery_date: values.delivery_date.format("YYYY-MM-DD") }),
      ...(values.arrival_date && { arrival_date: {
        start: cleanedValues.arrival_date[0].format("YYYY.MM.DD"),
        end: cleanedValues.arrival_date[1].format("YYYY.MM.DD"),
      } }),
      ...(values.assembly_date && { assembly_date: {
        start: cleanedValues.assembly_date[0].format("YYYY.MM.DD"),
        end: cleanedValues.assembly_date[1].format("YYYY.MM.DD"),
      } }),
      user_id: cleanedValues.user_id?.length ? cleanedValues.user_id : undefined,
    }

    setFilters(data)
    stockFBSGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: data
    })
  }

  const handleCardClick = (key: string, value: any) => {
    const marketPlaceValue = key === "marketplace_id" ?  value : null
    setActiveCards((prevState: any) => {
      form.setFieldsValue({ [key]: prevState[key] === value ? marketPlaceValue : value })
      return({
        ...prevState,
        [key]: prevState[key] === value ? marketPlaceValue : value
      })
    })
    form.submit()
  }
  const marketPlaces = [
    {
      id:1,
      title:"WILDBERRIES"
    },
    {
      id:2,
      title:"OZON"
    },
  ]

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Card style={{ width: "100%",backgroundColor:"rgba(0, 0, 0, 0.02)" }}>
        <Form form={form} onFinish={onSubmitFilter} name="basic">
          {
            filters?.partners?.length > 0 && <>
              <FilterHeading text={"ОРГАНИЗАЦИЯ:"} />
              <Row gutter={16}>
                <Form.Item name="partner_id">
                  <div style={{ display: "flex", rowGap: 20, columnGap: 10, flexWrap: "wrap" }}>
                    {
                      filters.partners.map((partner: {
                        partner_name: string,
                        records_count: number,
                        partner_id: number
                      }) => (
                        <Card
                          bodyStyle={{
                            padding:"12px"
                          }}
                          onClick={() => handleCardClick("partner_id", partner.partner_id)}
                          className={activeCards.partner_id === partner.partner_id ? "active-card" : "custom-card"}
                        >
                          {partner.partner_name}
                          <Counter count={partner?.records_count} />
                        </Card>
                      ))
                    }
                  </div>
                </Form.Item>
              </Row>
            </>
          }
          <FilterHeading text={"Даты:"} />
          <div style={{ marginLeft: "-7px", display: "flex", gap: "10px",flexWrap:"wrap" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              {
                activeCards.marketplace_id === 2 && <Form.Item name="delivery_date">
                  <DatePicker format="YYYY-MM-DD" placeholder={"Дата доставки"}/>
                </Form.Item>
              }
              <div className={"hide-datepicker-placeholder"}>

                <Form.Item name="arrival_date">
                  <DatePicker.RangePicker placeholder={[ "Дата заказа", "заказа" ]} style={{ width: "230px" }}
                    format="DD.MM.YYYY"/>
                </Form.Item>
              </div>
              <div className={"hide-datepicker-placeholder"}>
                <Form.Item name="assembly_date">
                  <DatePicker.RangePicker style={{ width: "234px" }} placeholder={[ "Дата сборки", "сборки" ]}
                    format="DD.MM.YYYY"/>
                </Form.Item>
              </div>

            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Item style={{ width: "max-content" }} name="order_id">
                <Input placeholder="Номер заказа" allowClear={true} />
              </Form.Item>
              <Form.Item style={{ width: "max-content" }} name="supply_id">
                <AutoWidthSelect showSearch={true} placeholder="Поставка" allowClear={true} options={filters?.supplies} />
              </Form.Item>
            </div>
          </div>
          <div style={{ display:"flex", gap:"15px", flexWrap:"wrap" }}>
            <div>
              <FilterHeading text={"Артикул цифровой:"} />
              <div style={{ marginLeft: "-7px", display: "flex", gap: "10px",flexWrap:"wrap" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item style={{ width: "max-content" }} name="nm_id">
                    <Input placeholder="Артикул цифровой" allowClear={true}/>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <FilterHeading text={"Артикул буквенный:"} />
              <div style={{ marginLeft: "-7px", display: "flex", gap: "10px",flexWrap:"wrap" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item style={{ width: "max-content" }} name="nm_name">
                    <Input placeholder="Артикул буквенный" allowClear={true}/>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <FilterHeading text={"Номер стикера:"} />
              <div style={{ marginLeft: "-7px", display: "flex", gap: "10px",flexWrap:"wrap" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item style={{ width: "max-content" }} name="sticker_number">
                    <Input placeholder="Номер стикера" allowClear={true}/>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <FilterHeading text={"Сотрудник:"} />
              <div style={{ marginLeft: "-7px", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item style={{ width: "200px" }} name="user_id">
                    <Select
                      placeholder="Сотрудник"
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) =>
                        option && option.children
                          ? (option.children as unknown as string)
                            .toLowerCase().indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      defaultActiveFirstOption={false}
                    >
                      {
                        (filterList.users_list || [])?.map((user: { id: number; name: string }) => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <FilterHeading text={"Поставщик:"} />
              <div style={{ marginLeft: "-7px", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item style={{ width: "200px" }} name="purveyor_id">
                    <Select
                      placeholder="Поставщик"
                      showSearch
                      filterOption={(input, option) =>
                        option && option.children
                          ? (option.children as unknown as string)
                            .toLowerCase().indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      defaultActiveFirstOption={false}
                      allowClear
                    >
                      {Object.entries(filterList?.suppliers_list || {}).map(([key, value]) => (
                        <Select.Option key={key} value={Number(key)}>
                          {value as string}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>     

          </div>
          <FilterHeading text={"МАРКЕТПЛЕЙС:"}/>
          <Row gutter={16}>
            <Form.Item name="marketplace_id" initialValue={1}>
              <div style={{ display: "flex", rowGap: 20, columnGap: 10 }}>
                {marketPlaces.map((market) => (
                  <Card
                    bodyStyle={{
                      padding: "12px"
                    }}
                    onClick={() => handleCardClick("marketplace_id", market.id)}
                    className={activeCards.marketplace_id === market.id ? "active-card" : "custom-card"}
                  >
                    <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "36px" }}
                      src={marketPlacePictureMap[market.id]}/> {market.title}
                    <Counter count={filters?.marketplace_count?.[market.id === 1 ? "wb" : "ozon"]}/>
                  </Card>
                ))}
              </div>
            </Form.Item>

          </Row>
          {
            filters?.statuses?.length > 0 && <>
              <FilterHeading text={"СТАТУС:"} />
              <Row gutter={16}>
                <Form.Item name="status_id">
                  <div style={{ display: "flex", rowGap: 20, columnGap: 10, flexWrap: "wrap" }}>
                    {
                      filters.statuses.map((status: {
                          name: string,
                          count: number,
                          id: number
                        }) => (
                        <Card
                          bodyStyle={{
                            padding:"12px"
                          }}
                          onClick={() => handleCardClick("status_id", status.id)}
                          className={activeCards.status_id === status.id ? "active-card" : "custom-card"}
                        >
                          {status.name}
                          <Counter count={status?.count} />
                        </Card>
                      ))
                    }
                  </div>
                </Form.Item>
              </Row>
            </>
          }
          <div style={{ marginLeft: "-8px",display:"flex",columnGap:"20px",alignItems:"center",rowGap:"10px",flexWrap:"wrap" }}>
            <Form.Item style={{ width: "max-content" }} name="not_collected" valuePropName="checked">
              <span style={{ marginRight:"10px" }}>Не собирался</span>
              <Switch
                checked={activeCards.not_collected}
                onChange={(checked) => handleCardClick("not_collected", checked)}
              />
            </Form.Item>
            <Form.Item style={{ width: "max-content" }} name="no_label" valuePropName="checked">
              <span style={{ marginRight:"10px" }}>Без этикетки</span>
              <Switch
                checked={activeCards.no_label}
                onChange={(checked) => handleCardClick("no_label", checked)}
              />
            </Form.Item>
            <Form.Item style={{ width: "max-content" }} name="error_on_marketplace" valuePropName="checked">
              <span style={{ marginRight:"10px" }}>Ошибка Маркетплейса</span>
              <Switch
                checked={activeCards.error_on_marketplace}
                onChange={(checked) => handleCardClick("error_on_marketplace", checked)}
              />
            </Form.Item>
          </div>
        </Form>
        <Space style={{ marginTop: "16px" }}>
          <Button className={"blueButton"} type="primary" style={{ background:"#00a2e8" }} onClick={() => form.submit()}>
              Применить
          </Button>
          <Button
            type="default"
            onClick={() => {
              form.resetFields()
              stockFBSGetList({
                page: pagesData.currentPage || 1,
                pageSize: pagesData.pageSize || 10,
                sorting: sorting,
                filters: defaultStockFBSFilters
              })
              setFilters(defaultStockFBSFilters)
            }}
          >
              Сбросить
          </Button>
        </Space>
      </Card>
    </Spin>
  )
}

export default FilterTable
