import { monitoringSliceActions } from "./monitoringSlice"
import { monitoringApi } from "../../../common/api/endpoints/monitoringApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const monitoringActions = {
  ...monitoringSliceActions,

  monitoringFetchPriceData: () => async (dispatch: TAppDispatch) => {
    dispatch(monitoringActions.setMonitoringLoading(true))
    dispatch(monitoringActions.setMonitoringPriceData([]))

    monitoringApi.fetchPriceData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(monitoringActions.setMonitoringPriceData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(monitoringActions.setMonitoringLoading(false))
      })
  },

  monitoringFetchOrderData: () => async (dispatch: TAppDispatch) => {
    dispatch(monitoringActions.setMonitoringLoading(true))
    dispatch(monitoringActions.setMonitoringOrderData([]))

    monitoringApi.fetchOrderData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(monitoringActions.setMonitoringOrderData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(monitoringActions.setMonitoringLoading(false))
      })
  },

  monitoringDownloadFile: (id: string | number, filename: string) => async () => {
    monitoringApi.downloadFile(id, filename)
  },

  monitoringDeleteFile: (id: string | number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
    monitoringApi.deleteFile(id)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
}
