import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropshippingState, initialState } from './types';
import dropshiping from '../../../pages/dropshiping';

const dropshippingSupplierSlice = createSlice({
  name:'dropshippingSupplier',
  initialState,
  reducers: {
    setItemLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setDropshippingSupplierData(state, action: PayloadAction<any[]>) {
      state.itemData = action.payload;
    },
    setSupplierDropshippingPagesData(state, action: PayloadAction<DropshippingState['pagesData']>) {
      state.pagesData = action.payload;
    },
    setDropshippingSupplierUpdate(state, action: PayloadAction<boolean>) {
      state.isUpdate = action.payload;
    },
  }
})

export const dropshippingSupplierSliceActions = dropshippingSupplierSlice.actions;
export const dropshippingSupplierSliceReducer = dropshippingSupplierSlice.reducer;
