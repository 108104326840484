import axios from "axios"

import { axiosConfigAuth } from "../middleware"
import {
  API_SUPPLIERS_ADD,
  API_SUPPLIERS_FILTER,
  API_SUPPLIERS_GET_DATA,
  API_SUPPLIERS_REMOVE,
  API_SUPPLIERS_UPDATE
  
} from "../urls"

export const suppliersApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_SUPPLIERS_GET_DATA, filters, axiosConfigAuth())
  },
  getFilter: async () => {
    return axios.get(API_SUPPLIERS_FILTER, axiosConfigAuth())
  },
  create: async (data: any) => {
    return axios.post(API_SUPPLIERS_ADD, data, axiosConfigAuth())
  },

  update: async (data: any) => {
    return axios.post(API_SUPPLIERS_UPDATE, data, axiosConfigAuth())
  },
  delete: async (id: string | number) => {
    return axios.get(`${API_SUPPLIERS_REMOVE}${id}`, axiosConfigAuth())
  },
}
