import { AxiosError } from "axios"

import { dropshippingSupplierSliceActions } from "./dropshippingSuppliers"
import { dropshippingSuppliersApi } from "../../../common/api/endpoints/dropshippingSuppliersApi"
import { handleResponseError } from "../../../common/api/middleware"
import supplier from "../../../pages/supplier"
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui"
import { TAppDispatch } from "../../store"


export const dropshippingSupplierActions = {
  ...dropshippingSupplierSliceActions,

  dropshippingSupplierGetList:(filters: any,isSocket=false) => async (dispatch: TAppDispatch) => {
    console.log("dropshippingGetList called with filters:", filters)
    !isSocket && dispatch(dropshippingSupplierActions.setItemLoading(true))

    try {
      const response = await dropshippingSuppliersApi.fetchList(filters)
      if (response.status === 200) {
        const data = response.data
        dispatch(dropshippingSupplierActions.setDropshippingSupplierData(
          data.rows.map((el: { id: number }) => ({ ...el, key: el.id }))
        ))
        dispatch(dropshippingSupplierActions.setSupplierDropshippingPagesData({
          total: data.total || 0,
          totalPages: data.totalPages || 0,
          start: data.start || 1,
          end: data.end || 0,
          currentPage: data.currentPage || 1,
          pageSize: data.pageSize || 10,
          header: data.header || { id: 0, name: "", phone: null },
          rows: data.rows || [],
        }))
      }
    } catch (err) {
      // handleResponseError(dispatch, err as AxiosError);
    } finally {
      !isSocket && dispatch(dropshippingSupplierActions.setItemLoading(false))
    }
  },

  dropshippingSupplierDelete: (supplierId: any, productId:any) =>(dispatch: TAppDispatch)=>{
    dropshippingSuppliersApi
      .delete(productId, supplierId)
      .then((response) => {
        if (response.status === 200){
          showSuccessMessage("Поставщик успешно удален.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },

  addProductPurchased: (data: any) => async (dispatch: TAppDispatch) => {
    try {
      await dropshippingSuppliersApi.addProductPurchased(data)
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError)
    }
  }
}
