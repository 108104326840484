import React, { FC, useState } from "react"

import { CopyOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  DatePicker
} from "antd"

import FilterPopover from "../../../common/components/FilterPopover/FilterPopover"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { removeObjectEmptyKeys } from "../../../utils/data"

interface Props {
    isLoading: boolean
    sorting: {[x: string]: string}
    setFilters: (v: any) => any
}

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [ form ] = Form.useForm()

  const { pagesData, filters } = useTypedSelector((state) => state.sber)
  const { sberGetList } = useActions()

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values)
    const data = {
      ...cleanedValues,
      data: cleanedValues.data && {
        start: cleanedValues.data[0]?.format("DD.MM.YYYY"),
        end: cleanedValues.data[1]?.format("DD.MM.YYYY")
      }
    }

    setFilters(data)
    sberGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: data
    })
  }

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Card style={{ width: "100%", backgroundColor:"rgba(0, 0, 0, 0.02)"  }}>
        <Form form={form} onFinish={onSubmitFilter} name="basic">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="data">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <DatePicker.RangePicker
                  format="DD.MM.YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="number">
                <Input placeholder="Номер заказа" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="company_id">
                <Select
                  mode={"multiple"}
                  placeholder="ИД компании"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {filters?.company_list?.map(({name,id}:{name:string,id:number}) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="product_name">
                <Input placeholder="Название товара" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Space style={{ marginTop:"16px" }}>
          <Button type="primary" style = {{background:"#00a2e8"}} onClick={() => form.submit()}>
                        Применить
          </Button>
          <Button
            type="default"
            onClick={() => {
              form.resetFields()
              sberGetList({
                page: pagesData.currentPage || 1,
                pageSize: pagesData.pageSize || 10,
                sorting: sorting,
                filters: {}
              })
              setFilters({})
            }}
          >
                        Сбросить
          </Button>
        </Space>
      </Card>
    </Spin>
  )
}

export default FilterTable
