import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined, DeleteOutlined, MenuOutlined, FileExcelOutlined } from "@ant-design/icons"
import { render } from "@testing-library/react"
import { Button, Table, Tooltip, Space, Image, Collapse } from "antd"
import { ColumnsType } from "antd/es/table"

import { purchaseApi } from "../../../common/api/endpoints/purchaseApi"
import { API_WS_URL } from "../../../common/api/urls"
import ColumnSettings from "../../../common/components/modals/ColumnSettings"
import { defaultItemsFilters } from "../../../common/constants/filters"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { PurchaseFilters } from "../../../redux/slices/purchase/types"
import { getAccessToken } from "../../../utils/auth"
import { marketPlacePictureMap, vk_image } from "../../../utils/data"
import { formatSummaryPayments } from "../../../utils/formatters"
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui"
import FilterTable from "../components/Filter"

type FilterFields = PurchaseFilters["filters"];

const PurchaseTable: FC = () => {
  const [ filters, setFilters ] = useState<FilterFields>(defaultItemsFilters)
  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const [ sorting, setSorting ] = useState<{ [key: string]: "DESC" | "ASC" }>({ purchase_date: "DESC" })
  const { isLoading, pagesData,isUpdate } = useTypedSelector((state) => state.purchase)
  const { getPurchaseList, deletePurchaseItem, setPurchaseUpdate } = useActions()
  const [ isSocket,setIsSocket ] = useState(false)
  const [ pagination,setPagination ] = useState({ page:1,pageSize:10 })
  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-dropshipping"]?.status === true) {
            setPurchaseUpdate(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }


  useEffect(() => {
    getPurchaseList({
      page: pagination.page,
      pageSize: pagination.pageSize,
      sorting: sorting,
      filters
    })
    connectWebSocket()
  }, [])

  useEffect(()=>{
    if(isUpdate){
      getPurchaseList({
        page: pagination.page,
        pageSize: pagination.pageSize,
        sorting: sorting,
        filters
      })
      setIsSocket(false)
      setPurchaseUpdate(false)
    }
  },[ isSocket,isUpdate ])


  const handleTableChange = (paginationData: any, tableFilters: any, sorter: any) => {
    const currentSort: { [key: string]: "DESC" | "ASC" } = sorter.order
      ? {
        [sorter.columnKey]: sorter.order === "ascend" ? "ASC" : "DESC",
      }
      : { purchase_date: "DESC" }

    setSorting(currentSort)
    setPagination({
      page: paginationData.current,
      pageSize: paginationData.pageSize,
    })

    getPurchaseList({
      page: Number(paginationData.current) || 1,
      pageSize: Number(paginationData.pageSize) || 10,
      sorting: currentSort,
      filters
    })
  }


  const handleImportExcel = async () => {
    try {
      await purchaseApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
    } catch (err) {
      console.log(err)
      const responseErrorMessage = (err as unknown as { response: { data: { message: string } } }).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  const handleDelete = (record: any) => {
    deletePurchaseItem(record.product_id, record.supplier_id, record.user_id, record.purchase_date)
  }

  const handleResetColumns = () => {
    setColumns(defaultColumns)
    localStorage.setItem("savedPurchaseCols",JSON.stringify(defaultColumns))
    showSuccessMessage("Порядок успешно возвращен.")
  }


  const defaultColumns = [
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      minWidth: 150,
      render: (val: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            alt="Фото"
            style={{ width: "60px", borderRadius: "8px" }}
            src={val}
          />
        </div>
      ),
    },
    {
      title: "Количество",
      dataIndex: "quantity",
      key: "quantity",
      sorter: true,
      render:(val:any)=>(
        <div style={{ display: "flex", justifyContent: "center" }}>
          {val}
        </div>
      )
    },
    {
      title: "Дата последней закупки",
      dataIndex: "purchase_date",
      key: "purchase_date",
      sorter: true,
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
      width: 250,
      render: (val: any, record: any) => {
        return (
          <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
            <Tooltip placement="topLeft" title={val}>
              {val}
            </Tooltip>
          </div>
        )}
    },
    {
      title: "Артикул",
      dataIndex: "sku",
      key: "sku",
      sorter: true,
      width: 200,
    },
    {
      title: "Ссылки",
      dataIndex: "links",
      key: "links",
      width: 150,
      render: (val: any) => (
        <Space style={{ display: "flex", justifyContent: "center" }}>
          {val.product_url && (
            <a href={val.product_url} target="_blank" rel="noopener noreferrer">
              <img alt="Продукт" src={marketPlacePictureMap[val.marketplace_id]} style={{ borderRadius: "50%", width: "24px", marginRight: "8px", marginLeft:"8px" }} />
            </a>
          )}
          {val.vk_url && (
            <a href={val.vk_url} target="_blank" rel="noopener noreferrer">
              <img alt="VK" style={{ borderRadius: "50%", width: "24px" }} src={vk_image} />
            </a>
          )}
        </Space>
      ),
    },
    {
      title: "Закупочная цена",
      dataIndex: "purchase_price",
      key: "purchase_price",
      minWidth: 150,
      sorter: true,
      render:(val:any)=>(
        <div style={{ display: "flex", justifyContent: "center" }}>
          {val}
        </div>
      )
    },
    {
      title: "Реальная цена",
      dataIndex: "real_price",
      key: "real_price",
      sorter: true,
      width: 200,

      render:(val:any)=>(
        <div style={{ display: "flex", justifyContent: "center" }}>
          {val}
        </div>
      )
    },
    {
      title: "Поставщик",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sorter: true,
      ellipsis: true,
      render:(val:any)=>(
        <div style={{ display: "flex", justifyContent: "center" }}>
          {val}
        </div>
      )
    },
    {
      title: "Штрих-код",
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
    },
    {
      title: "Сотрудник",
      dataIndex: "user_name",
      key: "user_name",
      sorter: true,
    },
    {
      title: "Действия",
      key: "actions",
      fixed: "right" as const,
      render: (_:any, record:any) => (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "10px",
          rowGap: "10px",
          flexWrap: "wrap",
        }}>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              handleDelete(record)
            }}
          />
        </div>
      ),
    },
  ]

  const [ columns, setColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...defaultColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return defaultColumns
  })


  return (
    <>
      <Collapse>
        <Collapse.Panel header="Фильтр закупок" key="1">
          <FilterTable
            isLoading={false}
            setFilters={setFilters}
            sorting={sorting}
          />
        </Collapse.Panel>
      </Collapse>
      <div style={{ float: "right", paddingTop: "14px", paddingBottom: "14px" }}>
        <Button
          type="primary"
          icon={<FileExcelOutlined />}
          onClick={handleImportExcel}
          style={{
            backgroundColor: "#28a745",
            borderColor: "#28a745",
            borderRadius: "4px",
            color: "#fff",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          СКАЧАТЬ В EXCEL
        </Button>
      </div>
      <Table
        virtual={true}
        title={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Space>
              <Button
                style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                onClick={() => setIsManageColumnsVisible(true)}
              >
                <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }} />
                СТОЛБЦЫ
              </Button>
              <span style={summarySpanStyles}>
                  Общая сумма: {formatSummaryPayments(pagesData?.total_purchase_amount, "₽")}
              </span>
              <span style={summarySpanStyles}>
                Общая реальная сумма: {formatSummaryPayments(pagesData?.total_real_purchase_amount, "₽")}
              </span>
            </Space>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                onClick={handleResetColumns}
              >
                ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
              </Button>
            </div>
          </div>
        )}
        columns={columns}
        dataSource={pagesData?.rows || []}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        }}
        scroll={{ x: 1800 }}
        pagination={{
          position: [ "bottomLeft" ],
          showSizeChanger: true,
          pageSizeOptions: [ "10", "25", "50", "100" ],
          defaultCurrent: 1,
          defaultPageSize: 10,
          total: pagesData.total || 0,
          showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} записей`,
        }}
        onChange={handleTableChange}
        size="small"
        bordered
      />
      {isManageColumnsVisible && (
        <ColumnSettings
          setVisible={setIsManageColumnsVisible}
          defaultColumns={defaultColumns}
          columns={columns}
          localStorageKey={"savedPurchaseCols"}
          setColumns={setColumns}
        />
      )}
    </>
  )
}

const summarySpanStyles = {
  marginLeft: 10,
  paddingLeft: 10,
  borderLeft: "1px solid #d4d4d4",
  fontWeight:600
}

export default PurchaseTable
