import axios, { AxiosResponse } from "axios"

import { getAccessToken } from "../../../utils/auth"
import { axiosConfigAuth } from "../middleware"
import {
  API_MONITORING_ORDER_FETCH,
  API_MONITORING_PRICE_FETCH,
  API_MONITORING_STATUS,
  API_MONITORING_UPDATE,
  API_MONITORING_FETCH,
  API_MONITORING_DELETE_FILE,
  API_MONITORING_DOWNLOAD_FILE,
  API_MONITORING_GET_IMPORTS, API_MONITORING_IMPORT
} from "../urls"

export const monitoringApi = {
  fetchData: async (): Promise<AxiosResponse> => {
    return axios.get(API_MONITORING_FETCH, axiosConfigAuth())
  },

  fetchPriceData: async (): Promise<AxiosResponse> => {
    return axios.get(API_MONITORING_PRICE_FETCH, axiosConfigAuth())
  },

  fetchOrderData: async (): Promise<AxiosResponse> => {
    return axios.get(API_MONITORING_ORDER_FETCH, axiosConfigAuth())
  },

  updateStatus: async (id: string, value: boolean): Promise<AxiosResponse> => {
    return axios.post(API_MONITORING_STATUS, { id, status: value }, axiosConfigAuth())
  },

  updateData: async (data: { id: number, minutes: string }): Promise<AxiosResponse> => {
    return axios.post(API_MONITORING_UPDATE, data, axiosConfigAuth())
  },

  downloadFile: (id: string | number, filename: string): void => {
    const a = document.createElement("a")

    a.href = `${API_MONITORING_DOWNLOAD_FILE}/${id}/${getAccessToken()}`
    a.download = filename

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  },

  deleteFile: async (id: string | number): Promise<AxiosResponse> => {
    return axios.get(`${API_MONITORING_DELETE_FILE}/${id}`, axiosConfigAuth())
  },

  fetchImports: async (): Promise<AxiosResponse> => {
    return axios.get(API_MONITORING_GET_IMPORTS, axiosConfigAuth())
  },

  importFile: async (type: 1 | 2 | 3, file: File | Blob | string): Promise<AxiosResponse> => {
    const data = new FormData()
    data.append("type_id", `${type}`)
    data.append("file_v", file)

    return axios.post(API_MONITORING_IMPORT, data, axiosConfigAuth())
  },
}
