import { FC, useEffect, useState } from "react"

import { FileExcelOutlined, LoadingOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Space, Select, Pagination, Collapse } from "antd"
import BaseTable, { Column } from "react-base-table"
import { useDispatch } from "react-redux"
import "react-base-table/styles.css"
import { ThunkDispatch } from "redux-thunk"

import InitialColumns from "./components/AnalyticsColumns"
import FilterTable from "./components/Filter"
import { analyticsApi } from "../../common/api/endpoints/analyticsApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import MainLayout from "../../common/components/layouts/MainLayout"
import Loading from "../../common/components/loading/Loading"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import HeaderCellSorting from "../../common/components/table/HeaderCellSorting"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import useTableWidth from "../../common/hooks/useTableWidth"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { analyticsActions } from "../../redux/slices/analytics/actions"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { columnsWithSortingHeader } from "../../utils/function"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

import { AnyAction } from "@reduxjs/toolkit"
const sortedColumns = [ "product_count", "marketplace_id", "name", "nm_id", "product_info", "barcodes", "price_without_discount", "discount_price_spp", "purchase_price_gardener" ]
const { Option } = Select

const Analytics: FC = () => {
  const dispatch = useDispatch()
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ productId, setProductId ] = useState<number | null>(null)
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })
  const isMobile = screen.width < 768

  const tableWidth = useTableWidth()
  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const handleSort = (columnKey: string) => {
    setSorting((prevSorting) => {
      if (prevSorting[columnKey]) {
        return {
          [columnKey]: prevSorting[columnKey] === "ASC" ? "DESC" : "ASC"
        }
      } else {
        return {
          [columnKey]: "ASC",
        }
      }
    })
  }

  const setColumnsState = (sorting: any) => {
    const savedColumns = localStorage.getItem("savedAnalyticsColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el: { key: string }) => el.key)
      const sortedDefaultColumns = [ ...InitialColumns({ setIsAddOpen, setProductId }) ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return columnsWithSortingHeader(sortedDefaultColumns.filter(el => [ ...columnsKeys ].includes(el.key)), sorting, sortedColumns, handleSort)
    }
    return columnsWithSortingHeader(InitialColumns({ setIsAddOpen, setProductId }), sorting, sortedColumns, handleSort)
  }
  const [ columns, setColumns ] = useState<Column[]>(() => setColumnsState(sorting))

  const [ activeMarketplace, setActiveMarketplace ] = useState<number | null>(null)

  const { isLoadingFilters, isLoadingOrders, orderData, filterList, pagesData } = useTypedSelector((state) => state.analytics)
  const { fetchOrdersByPeriod } = useActions()
  const [ isSocket, setIsSocket ] = useState(false)
  const [ suppliersOptions, setSuppliersOptions ] = useState<{ id: string; name: string }[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ selectedValue, setSelectedValue ] = useState<string | null>(null)

  useEffect(() => {
    (dispatch as ThunkDispatch<any, any, AnyAction>)(analyticsActions.fetchFilterList())
  }, [ dispatch ])

  useEffect(() => {
    fetchOrdersByPeriod({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters,
    })
  }, [ sorting ])

  useEffect(() => {
    setColumns(setColumnsState(sorting))
  }, [ sorting ])

  const handlePagination = (page: number, pageSize: number) => {
    fetchOrdersByPeriod({
      page: page,
      pageSize: pageSize,
      sorting,
      filters,
    })
  }

  const handleImportExcel = async () => {
    try {
      await analyticsApi.downloadOrdersReportExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters,
      })
    } catch (err) {
      console.log(err)
      const responseErrorMessage = (err as unknown as { response: { data: { message: string } } }).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  return (
    <MainLayout pageTitle="Аналитика">
      <div className="page-wrapper">
        <PageHead titleStyles={{ fontWeight: "600" }} title="ЗАКАЗЫ ЗА ПЕРИОД" extra={<div></div>} />
        <div className="page-content">
          <Collapse>
            <Collapse.Panel header="Фильтр" key="1">
              <FilterTable
                isLoading={isLoadingFilters || isLoadingOrders}
                setFilters={setFilters}
                sorting={sorting}
              />
            </Collapse.Panel>
          </Collapse>
          <Space
            style={{ justifyContent: "flex-end", width: "100%" }}>
            <Button
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={handleImportExcel}
              style={{
                backgroundColor: "#28a745",
                borderColor: "#28a745",
                borderRadius: "4px",
                color: "#fff",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
                            СКАЧАТЬ В EXCEL
            </Button>
          </Space>
          <div className="universal-table-settings" style={{ width: tableWidth }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
              <Button
                style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                onClick={() => setIsManageColumnsVisible(true)}
              >
                <MenuOutlined
                  style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
                />
                                СТОЛБЦЫ
              </Button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => {
                    localStorage.setItem(
                      "savedAnalyticsColumns",
                      JSON.stringify(InitialColumns({ setIsAddOpen, setProductId })))
                    setColumns(setColumnsState(sorting))
                    showSuccessMessage("Порядок успешно возвращен.")
                  }}
                >
                                    ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
                </Button>
              </div>
            </div>
            <Loading loading={isLoadingOrders}>
              <BaseTable
                columns={columns as any}
                data={orderData}
                loading={isLoadingOrders}
                fixed
                width={tableWidth}
                height={600}
                disabled={isLoadingOrders}
                rowKey="product_id"
                estimatedRowHeight={70}
                onEndReachedThreshold={300}
              />
            </Loading>
          </div>
          <Pagination
            disabled={isLoadingOrders}
            style={{ width: isMobile ? "100vw" : "100%" }}
            onChange={handlePagination}
            size={"small"}
            current={pagesData.currentPage}
            showSizeChanger={true}
            pageSizeOptions={[ "10","50", "100", "200", "500","1000" ]}
            defaultCurrent={1}
            defaultPageSize={10}
            total={pagesData.total}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${total} карточек`}
          />
        </div>

        {isManageColumnsVisible && (
          <ColumnSettings
            setVisible={setIsManageColumnsVisible}
            defaultColumns={columnsWithSortingHeader(InitialColumns({ setIsAddOpen, setProductId }), sorting, sortedColumns, handleSort)}
            columns={columns}
            setColumns={setColumns}
            localStorageKey={"savedAnalyticsColumns"}
          />
        )}
      </div>
    </MainLayout>
  )
}

export default Analytics
