import React from "react"

import { Select, Checkbox, Divider } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import { marketPlacePictureMap } from "../../../utils/data"

const { Option } = Select

interface AdvancedSelectProps {
    items: { id: number; name: string,marketplace_id?:number }[];
    selectedIds: number[];
    setSelectedIds: (ids: number[]) => void;
    placeholder?: string;
    minWidth?: string;
    defaultActiveFirstOption?: boolean
}

const AdvancedSelect: React.FC<AdvancedSelectProps> = ({
  items,
  selectedIds,
  setSelectedIds,
  placeholder = "",
  minWidth = "190px",
  defaultActiveFirstOption=false
}) => {
  const handleSelectionChange = (selectedIds: number[]) => {
    setSelectedIds(selectedIds)
  }

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    setSelectedIds(checked ? items.map((item) => item.id) : [])
  }

  return (
    <Select
      mode="multiple"
      placeholder={placeholder}
      showSearch
      value={selectedIds}
      onChange={handleSelectionChange}
      style={{ minWidth }}
      filterOption={(input, option) => {
        let condition = false
        if(option && Array.isArray(option?.children)){
          condition = option && option?.children
            ? option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
            : false
        }else{
          condition = option && option?.children
            ? (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            : false
        }
        return condition
      }
      }
      defaultActiveFirstOption={defaultActiveFirstOption}
      dropdownRender={(menu) => (
        <>
          <div style={{ display: "flex", alignItems: "center", padding: "8px" }}>
            <Checkbox
              onChange={handleSelectAllChange}
              checked={selectedIds.length === items.length}
              indeterminate={selectedIds.length > 0 && selectedIds.length < items.length}
            >
                            Выбрать все
            </Checkbox>
          </div>
          <Divider style={{ margin: 0 }} />
          {menu}
        </>
      )}
    >
      {items?.map((item) => (
        <Option key={item.id} style={{ ...(item?.marketplace_id && { marginBottom:2 }) }} value={item.id}>
          {item?.marketplace_id && <img alt={"Маркетплейс"}
            style={{ borderRadius: "50%", width: "25px",marginRight:4 }}
            src={marketPlacePictureMap[item.marketplace_id]} />}
          {item.name}
        </Option>
      ))}
    </Select>
  )
}

export default AdvancedSelect
