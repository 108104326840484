import React, { FC, useEffect, useState } from "react"

import {
  MenuOutlined,
} from "@ant-design/icons"
import { Button,Input, Pagination } from "antd"
import BaseTable from "react-base-table"
import { useParams } from "react-router-dom"


import useDropshippingSupplierColumns from "./columns"
import { API_WS_URL } from "../../common/api/urls"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import MainLayout from "../../common/components/layouts/MainLayout"
import Loading from "../../common/components/loading/Loading"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import useTableWidth from "../../common/hooks/useTableWidth"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { DropshippingSuppliersPagesData } from "../../redux/slices/dropshippingSuppliers/types"
import { getAccessToken } from "../../utils/auth"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"

const Supplier: FC = () => {
  const { supplierId } = useParams()

  const [ isTransferToDeliveryLoading, setIsTransferToDeliveryLoading ] =
    useState(false)
  const [ selectedIds, setSelectedIds ] = useState<number[]>([])
  const [ selectedSupplierNames, setSelectedSupplierNames ] = useState<string>("")
  const isMobile = screen.width < 768

  const { isLoading, pagesData,isUpdate } = useTypedSelector((state) => state.dropshippingSupplier)
  const {
    companies,
  } =
      useTypedSelector((state) => state.stockFBS)
  const {
    dropshippingSupplierGetList,
    stockFBSGetCompanies,
    dropshippingSupplierDelete,
    addProductPurchased,
    setDropshippingSupplierUpdate,
    setSupplierDropshippingPagesData
  } =
    useActions()

  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const [ isSocket, setIsSocket ] = useState(false)


  const handleClickDelete = async (supplierId: any, productId: any) => {
    try {
      console.log("Попытка удаления: ", supplierId, productId)
      dropshippingSupplierDelete(supplierId, productId)
      console.log("Удаление прошло успешно")
    } catch (error) {
      console.error("Ошибка при удалении поставщика", error)
      showErrorMessage("Ошибка при удалении поставщика")
    }
  }


  const handleSave = async (record: any) => {
    try {
      const { product_id, quantity, real_price } = record
      if (!product_id || !quantity || !real_price) {
        throw new Error("Missing product data for saving.")
      }
      const dataToSave = {
        products: [
          {
            product_id,
            supplier_id: supplierId,
            quantity,
            real_price,
          },
        ],
      }


      await addProductPurchased(dataToSave)
      showSuccessMessage("Все изменения сохранены.")

    } catch (error:any) {
      console.log(error)
      const isMissingData = error?.message === "Missing product data for saving."
      isMissingData && showErrorNotification("Не заполнено поле реальная цена или кол-во!")
    }
  }

  const handleSaveAll = async () => {
    try {
      const productsToSave = selectedRowKeys.map((key) => {
        const row = pagesData?.rows?.find((el)=>el?.product_id === key)

        const { product_id, quantity, real_price } = row
        if (!product_id || !quantity || !real_price) {
          throw new Error("Missing product data for saving.")
        }

        return {
          product_id,
          supplier_id: supplierId,
          quantity,
          real_price,
        }
      })


      if (productsToSave.length > 0) {
        const dataToSave = {
          products: productsToSave,
        }

        await addProductPurchased(dataToSave)

        showSuccessMessage("Все изменения сохранены.")
        setSelectedRowKeys([])

      }
    } catch (error:any) {
      console.log(error)
      const isMissingData = error?.message === "Missing product data for saving."
      isMissingData && showErrorNotification("Не заполнено поле реальная цена или кол-во!")
    }
  }


  const handleRowEdit = (record: any, field: string, value: any) => {
    const updatedPagesData:DropshippingSuppliersPagesData = {
      ...pagesData,
      rows: pagesData
        ?.rows?.map((item) =>
          item.product_id === record.product_id
            ? { ...item, [field]: value }
            : item
        ) }
    setSupplierDropshippingPagesData(updatedPagesData)
  }

  const [ columns, setColumns,defaultColumns,sorting,selectedRowKeys,setSelectedRowKeys ] = useDropshippingSupplierColumns({ supplierId,handleSave,handleClickDelete,handleRowEdit })
  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-dropshipping"]?.status === true) {
            setDropshippingSupplierUpdate(true)
            setIsSocket(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }

  useEffect(()=>{
    connectWebSocket()
  },[])

  useEffect(() => {
    const selectedNames = companies
      .filter(company => selectedIds.includes(company.id))
      .map(company => company.name)
      .join(", ")
    setSelectedSupplierNames(selectedNames)
  }, [ selectedIds, companies ])

  useEffect(()=>{
    if(isUpdate){
      dropshippingSupplierGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting,
        filters: { supplier_id: supplierId, company_id: selectedIds.length > 0 ? selectedIds : undefined },
      },isSocket)
      setDropshippingSupplierUpdate(false)
      setIsSocket(false)
    }
  },[ isUpdate,isSocket ])

  useEffect(() => {
    stockFBSGetCompanies()
    dropshippingSupplierGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting,
      filters: { supplier_id: supplierId, company_id: selectedIds.length > 0 ? selectedIds : undefined },
    },isSocket)
  }, [ selectedIds, supplierId, pagesData.currentPage, pagesData.pageSize,sorting ])

  useEffect(() => {
    const selectedNames = companies
      .filter(company => selectedIds.includes(company.id))
      .map(company => company.name)
      .join(", ")
    setSelectedSupplierNames(selectedNames)
  }, [ selectedIds, companies ])

  const tableWidth = useTableWidth()

  const handleResetColumns = () => {
    localStorage.setItem("savedDropSupplierCols",JSON.stringify(defaultColumns.filter((el)=> el.key !== "selection")))
    setColumns(defaultColumns)
    showSuccessMessage("Порядок успешно возвращен.")
  }

  const handlePagination = (page:number,pageSize:number) => {
    dropshippingSupplierGetList({
      page: page,
      pageSize: pageSize,
      sorting,
      filters: { supplier_id: supplierId, company_id: selectedIds.length > 0 ? selectedIds : undefined },
    },isSocket)
  }


  return (
    <>
      <MainLayout pageTitle={"Новый заказ поставщику"}>
        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title={"Новый заказ поставщику "}
            extra={<div></div>}
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px",justifyContent:"space-between",marginRight: "24px" }}>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            </div>
          </div>
          <div style={{
            display: "grid",
            gridTemplateColumns: "150px 250px",
            gap: "16px",
            marginTop: "16px",
            marginLeft: "24px"
          }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>НАЗВАНИЕ</span>
            <Input style={{ width: "100%" }} defaultValue={pagesData.header.name} value={pagesData.header.name} />

            <span style={{ fontWeight: 600, fontSize: 16 }}>ТЕЛЕФОН</span>
            <Input style={{ width: "100%" }} defaultValue={pagesData.header.phone == null ? "Нет" : pagesData.header.phone} value={pagesData.header.phone == null ? "Нет" : pagesData.header.phone} />

            <span style={{ fontWeight: 600, fontSize: 16 }}>ОРГАНИЗАЦИЯ</span>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Input
                style={{ width: "100%" }}
                value={selectedSupplierNames}
                readOnly
                placeholder="Выбранные поставщики"
              />
              <AdvancedDropdown
                items={companies}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            </div>
          </div>
          <div className="page-content">
            <div
              style={{
                display: "flex",
                columnGap: "20px",
                alignItems: "center",
                rowGap: "10px",
                flexWrap: "wrap",
                position: "relative"
              }}
            >
            </div>
            <div className="universal-table-settings" style={{ width: tableWidth }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                <Button
                  style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => setIsManageColumnsVisible(true)}
                >
                  <MenuOutlined
                    style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
                  />
                  СТОЛБЦЫ
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={handleResetColumns}
                  >
                    ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
                  </Button>
                </div>
              </div>
              <Loading loading={isLoading}>
                <BaseTable
                  columns={columns as any}
                  data={pagesData?.rows}
                  loading={isLoading}
                  fixed
                  width={tableWidth}
                  height={600}
                  disabled={isLoading}
                  rowKey="product_id"
                  estimatedRowHeight={70}
                  onEndReachedThreshold={300}
                />
              </Loading>
            </div>
            <Pagination
              disabled={isLoading}
              style={{ width: isMobile ? "100vw" : "100%",marginBottom:"40px" }}
              onChange={handlePagination}
              size={"small"}
              current={pagesData.currentPage}
              showSizeChanger={true}
              pageSizeOptions={[ "10", "25", "50", "100" ]}
              defaultCurrent={1}
              defaultPageSize={10}
              total={pagesData.total}
              showTotal={(total) => `Всего строк: ${total}`}
            />
          </div>
          {isManageColumnsVisible && (
            <ColumnSettings
              setVisible={setIsManageColumnsVisible}
              defaultColumns={defaultColumns}
              columns={columns}
              setColumns={setColumns}
              localStorageKey={"savedDropSupplierCols"}
            />
          )}

          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 200,
              width: "100%",
              height: "50px",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
            }}
          >
            <Button
              type="primary"
              onClick={handleSaveAll}
              disabled={selectedRowKeys.length === 0}
              className="blueButton"
              style={{ marginLeft: "25px" }}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

export default Supplier
