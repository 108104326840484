import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {ExportData, ExportState} from "./types"

const initialState: ExportState = {
  tableData:[],
  isLoading: false,
}

export const exportSlice = createSlice({
  name: "export",
  initialState: initialState,
  reducers: {
    setTableData: (state, action: PayloadAction<ExportData[]>) => {
      state.tableData = action.payload
    },
    setExportLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  }
})

export const exportSliceReducer = exportSlice.reducer
export const exportSliceActions = exportSlice.actions
