import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IStaffRow, IStaffState } from "./types"

const initialState: IStaffState = {
  staffData: [],
  isLoading: false,
  isUpdate: false
}

export const staffSlice = createSlice({
  name: "staff",
  initialState: initialState,
  reducers: {
    setStaffData: (state, action: PayloadAction<IStaffRow[]>) => {
      state.staffData = action.payload
    },
    setStaffLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStaffUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const staffSliceReducer = staffSlice.reducer
export const staffSliceActions = staffSlice.actions
