import axios from "axios"

import { axiosConfigAuth } from "../middleware"
import {
  API_META_GET_MANAGERS,
  API_META_GET_PARTNERS,
  API_META_GET_ROLES,
  API_META_GET_WP_STATUSES,
  API_STAFF_ADD,
  API_STAFF_DELETE,
  API_STAFF_EDIT,
  API_STAFF_LIST,
  API_STAFF_ONE
} from "../urls"

export const staffApi = {
  fetchList: async () => {
    return axios.get(API_STAFF_LIST, axiosConfigAuth())
  },

  fetchOne: async (id: string | number) => {
    return axios.get(`${API_STAFF_ONE}/${id}`, axiosConfigAuth())
  },

  create: async (data: any) => {
    return axios.post(API_STAFF_ADD, data, axiosConfigAuth())
  },

  edit: async (data: any) => {
    return axios.post(API_STAFF_EDIT, data, axiosConfigAuth())
  },

  delete: async (id: string | number) => {
    return axios.get(`${API_STAFF_DELETE}/${id}`, axiosConfigAuth())
  },

  fetchManagers: async () => {
    return axios.get(API_META_GET_MANAGERS, axiosConfigAuth())
  },

  fetchPartners: async () => {
    return axios.get(API_META_GET_PARTNERS, axiosConfigAuth())
  },

  fetchRoles: async () => {
    return axios.get(API_META_GET_ROLES, axiosConfigAuth())
  },

  fetchWpStatuses: async () => {
    return axios.get(API_META_GET_WP_STATUSES, axiosConfigAuth())
  }
}
