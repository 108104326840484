import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PurchaseState, initialState } from './types';

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setItemLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPurchaseData(state, action: PayloadAction<any[]>) {
      state.itemData = action.payload;
    },
    setItemPagesData(state, action: PayloadAction<PurchaseState['pagesData']>) {
      state.pagesData = action.payload;
    },
    setPurchaseUpdate(state, action: PayloadAction<boolean>) {
      state.isUpdate = action.payload;
    },
    setPartnerFilters(state, action: PayloadAction<any>) {
      state.filtersData = action.payload;
    },

  }
});

export const purchaseSliceActions = purchaseSlice.actions;
export const purchaseSliceReducer = purchaseSlice.reducer;
