import React, { FC, useState } from "react";
import { Tabs } from "antd";
import MainLayout from "../../common/components/layouts/MainLayout";
import PageHead from "../../common/components/page-header/PageHeader";
import SuppliersTable from "./Pages/SuppliersTable"
import PurchaseTable from "./Pages/PurchaseTable"

const { TabPane } = Tabs;

const SuppliersPage: FC = () => {
  const [ currentTab, setCurrentTab ] = useState("1")

  const tabs = [
    {
      key: "1",
      label: "Закупки",
      children: (
        <PurchaseTable />
      ),
    },
    {
      key: "2",
      label: "База поставщиков",
      children: (
        <SuppliersTable />
      ),
    },
  ]

  return (
    <MainLayout pageTitle="Поставщики">
      <div className="page-wrapper">
        <PageHead title="Поставщики"/>
        <div className="page-content">
          <Tabs
            activeKey={currentTab}
            items={tabs}
            type="card"
            onChange={(value) => setCurrentTab(value)}
            destroyInactiveTabPane
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default SuppliersPage;
