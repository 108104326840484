import { useTypedSelector } from "./useTypedSelector"
import { ADMIN_ROLE } from "../constants/roles"

export const useAllowedRoles = (allowedRoles: number[]) => {
  const { role } = useTypedSelector((state) => state.currentUser)

  const containsRoles = (where: number[], what: number) => {
    return where.includes(what, 0)
  }

  return Boolean(role && containsRoles([ ...allowedRoles,ADMIN_ROLE ], role))
}
