import React, { FC, useEffect } from "react"

import { Alert, Form, InputNumber, Modal, Select, Tag } from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors, showSuccessNotification } from "../../../utils/ui"

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  id: number
  minutes: string
  comment: string
  orders_count?: number
  name:string
}

const EditModal: FC<Props> = ({ open, setOpen, id, minutes, comment, orders_count,name }) => {

  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.monitoringSettings)
  const { monitoringSettingsFetchData, monitoringSettingsUpdate } = useActions()

  useEffect(() => {
    if (open) {
      form.setFieldValue("minutes", minutes)
      if (id === 2 || id === 3 || id === 5 || id === 6) form.setFieldValue("orders_count", orders_count)
    }
  }, [ open ])

  const updateParam = (values: { minutes: number, orders_count?: number }) => {
    const parsedValues = {
      id: id,
      minutes: `${values.minutes}`,
      orders_count: values.orders_count || undefined,
    }

    monitoringSettingsUpdate(parsedValues, () => {
      setOpen(false)
      showSuccessNotification("Параметр обновлен")
      monitoringSettingsFetchData()
    }, (errors) => {
      showFormErrors(form, errors)
    })
  }

  return (
    <Modal
      title="Редактирование параметра"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={updateParam}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item>
          Параметр: <Tag>Интервал</Tag>
        </Form.Item>

        <Form.Item>
          <Alert
            message={comment}
            type="info"
            showIcon
          />
        </Form.Item>

        <Form.Item
          label="Интервал, мин:"
          name="minutes"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select style={{ width: "100%" }}>
            {Array.from({ length: 10 }, (_, i) => ({
              name: `${(i + 1) * 5}`,
              id: (i + 1) * 5
            })).map(
              (item: {id: number; name: string}, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>

        {name.includes("количества заказов") && (
          <Form.Item
            label="Кол-во заказов:"
            name="orders_count"
            rules={[ { required: true, message: "Поле обязательное" } ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default EditModal
