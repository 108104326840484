import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { DatePicker, Form, Input, Modal, Select, Spin } from "antd"
import dayjs from "dayjs"

import { staffApi } from "../../../common/api/endpoints/staffApi"
import { PhoneRegex } from "../../../common/constants/regex"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { IList } from "../../../types/lists"
import { parseValuesBeforeInsertInForms } from "../../../utils/data"
import {
  showErrorNotification,
  showFormErrors,
  showSuccessNotification
} from "../../../utils/ui"
import AdvancedSelect from "../../../common/components/selects/AdvancedSelect";

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  editId: string
}

const EditModal: FC<Props> = ({ isOpen, setIsOpen, editId }) => {
  const [ form ] = Form.useForm()

  const [ managers, setManagers ] = useState<IList[]>([])
  const [ partners, setPartners ] = useState<IList[]>([])
  const [ roles, setRoles ] = useState<IList[]>([])
  const [ wpStatus, setWpStatus ] = useState<IList[]>([])
  const [ isFormLoading, setIsFormLoading ] = useState(false)
  const [partnerIds, setPartnerIds] = useState<number[]>([]);
  const { isLoading } = useTypedSelector((state) => state.staff)
  const { staffEdit, authLogout, staffGetList } = useActions()

  const errorList = () => {
    setIsFormLoading(false)
    showErrorNotification(
      "Ошибка при получении данных, повторите попытку позже."
    )
    setIsOpen(false)
  }

  const getFormData = (userId: string | number) => {
    setIsFormLoading(true)

    staffApi
      .fetchManagers()
      .then((response) => {
        setManagers(response.data)
        staffApi
          .fetchPartners()
          .then((response) => {
            setPartners(response.data)
            staffApi
              .fetchRoles()
              .then((response) => {
                setRoles(response.data)
                staffApi
                  .fetchWpStatuses()
                  .then((response) => {
                    setWpStatus(response.data)
                    staffApi
                      .fetchOne(userId)
                      .then((response) => {
                        const data = {
                          ...response.data,
                          date_of_employment: response.data.date_of_employment
                            ? dayjs(response.data.date_of_employment)
                            : null
                        }

                        const partnerIdsFromServer = response.data.partners.map((partner: any) => partner.id);

                        setPartnerIds(partnerIdsFromServer);
                        const values = parseValuesBeforeInsertInForms(data)
                        console.log(5, values)
                        form.setFields(values)
                        setIsFormLoading(false)
                        form.setFieldsValue({
                          ...parseValuesBeforeInsertInForms(data),
                          partners: partnerIdsFromServer
                        });

                        setIsFormLoading(false);
                      })
                      .catch((err) => {
                        if (err.response?.status === 401) {
                          authLogout(() => {
                            showErrorNotification(
                              "Сессия истекла. Пожалуйста, войдите снова."
                            )
                            window.location.href = "/"
                          })
                          return
                        }
                        errorList()
                      })
                  })
                  .catch((err) => {
                    if (err.response?.status === 401) {
                      authLogout(() => {
                        showErrorNotification(
                          "Сессия истекла. Пожалуйста, войдите снова."
                        )
                        window.location.href = "/"
                      })
                      return
                    }
                    errorList()
                  })
              })
              .catch((err) => {
                if (err.response?.status === 401) {
                  authLogout(() => {
                    showErrorNotification(
                      "Сессия истекла. Пожалуйста, войдите снова."
                    )
                    window.location.href = "/"
                  })
                  return
                }
                errorList()
              })
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              authLogout(() => {
                showErrorNotification(
                  "Сессия истекла. Пожалуйста, войдите снова."
                )
                window.location.href = "/"
              })
              return
            }
            errorList()
          })
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          authLogout(() => {
            showErrorNotification("Сессия истекла. Пожалуйста, войдите снова.")
            window.location.href = "/"
          })
          return
        }
        errorList()
      })
  }

  const updateStaff = (values: any) => {
    const data = {
      ...values,
      id: editId,
      partner_ids: partnerIds,
    };

    staffEdit(
      data,
      () => {
        showSuccessNotification("Пользователь успешно обновлен");
        setIsOpen(false);
        staffGetList();
      },
      (errors) => {
        showFormErrors(form, errors);
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      getFormData(editId)
    }
  }, [ isOpen ])

  const setSelectedPartners = (ids: number[]) => {
    setPartnerIds(ids) ;
    form.setFieldsValue({ partners: ids }) ;
  };

  return (
    <Modal
      title="Редактирование сотрудника"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
      }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={updateStaff}
        layout="vertical"
        requiredMark={true}
      >
        {isFormLoading ? (
          <div className="global-loading-container" style={{ height: 400 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        ) : (
          <>
            <Form.Item
              label="Имя"
              name="name"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Логин пользователя"
              name="username"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Пароль" name="password">
              <Input.Password autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              label="Старший менеджер"
              name="manager_id"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <Select loading={isFormLoading}>
                {managers.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Партнеры"
              name="partners"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <AdvancedSelect
                items={partners.map(item => ({
                  id: Number(item.id),
                  name: item.name,
                }))}
                selectedIds={partnerIds}
                setSelectedIds={setSelectedPartners}
                placeholder="Выберите партнеров"
              />
            </Form.Item>

            <Form.Item
              label="Название группы WhatsApp"
              name="whatsapp_group_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="URL группы WhatsApp"
              name="whatsapp_group_url"
              rules={[ { type: "url", message: "Требуется URL" } ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Номер телефона WhatsApp"
              name="whatsapp_phone"
              rules={[
                { pattern: PhoneRegex, message: "Требуется номер телефона" }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Номер телефона WB"
              name="phone_binding_wb"
              rules={[
                { pattern: PhoneRegex, message: "Требуется номер телефона" }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Статус пользователя"
              name="status"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <Select loading={isFormLoading}>
                <Select.Option value={1}>Активный</Select.Option>
                <Select.Option value={2}>Не активный</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Роль пользователя"
              name="role"
              rules={[ { required: true, message: "Поле обязательное" } ]}
            >
              <Select loading={isFormLoading}>
                {roles.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Статус участника группы WhatsApp"
              name="whatsapp_group_status"
            >
              <Select loading={isFormLoading} allowClear>
                {wpStatus.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Реквизиты для перевода ЗП" name="requisites">
              <Input />
            </Form.Item>

            <Form.Item
              label="Дата принятия на работу"
              name="date_of_employment"
            >
              {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore*/}
              <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" />
            </Form.Item>

            <Form.Item label="Комментарий" name="comment">
              <Input.TextArea />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default EditModal
