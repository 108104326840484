import React, { FC, useEffect } from "react"
import { Button, Col, Form, Input, Modal, Row } from "antd"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors, showSuccessMessage } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  filters: any
}

const AddModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm()

  const { isLoading, isUpdate } = useTypedSelector((state) => state.suppliers)
  const { suppliersCreate, setSuppliersUpdate, setSuppliersLoading } = useActions()

  const addItem = (values: any) => {
    const data = {
      ...values,
      phone: values.phone === undefined ? null : values.phone
    }
    suppliersCreate(
      data,
      () => {
        showSuccessMessage("Поставщик успешно добавлен.")
        setIsOpen(false)
        setSuppliersLoading(true)
        setSuppliersUpdate(true)
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [isOpen])

  return (
    <Modal
      title="Добавление поставщика"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
      destroyOnClose
      width={"30%"}
      modalRender={(modal) => (
        <div style={{ position: "relative", paddingLeft: "25px" }}>
          {modal}
        </div>
      )}
    >
      <Form
        form={form}
        onFinish={addItem}
        layout="vertical"
        requiredMark={true}
      >
        <Row gutter={16} style={{paddingTop:"15px"}}>
          <Col span={12}>
            <Form.Item
              label="Поставщик"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Поле обязательное"
                },
                () => ({
                  validator(_, value) {
                    if (value && value.trim().length === 0) {
                      return Promise.reject(new Error("Поле не может содержать только пробелы"))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input maxLength={275} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Номер телефона"
              name="phone"
            >
              <Input maxLength={255} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: "20px" }}>
          <Button onClick={() => setIsOpen(false)} style={{ marginRight: "10px" }}>
            Отмена
          </Button>
          <Button type="primary" htmlType="submit" className="blueButton" loading={isLoading}>
            Добавить
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddModal
