import { FC, useEffect, useState } from "react"

import { FileExcelOutlined, LoadingOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Space, Select, Pagination } from "antd"
import BaseTable, { Column } from "react-base-table"

import "react-base-table/styles.css"
import AddModal from "./components/AddModal"
import DropshippingColumns from "./components/DropshippingColumns"
import { dropshippingApi } from "../../common/api/endpoints/DropshippingApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import MainLayout from "../../common/components/layouts/MainLayout"
import Loading from "../../common/components/loading/Loading"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import HeaderCellSorting from "../../common/components/table/HeaderCellSorting"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import useTableWidth from "../../common/hooks/useTableWidth"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { columnsWithSortingHeader } from "../../utils/function"
import { showErrorMessage, showErrorNotification, showSuccessMessage } from "../../utils/ui"
const sortedColumns = [ "quantity","arrival_date","name","sku","purchase_price","barcode" ]
const { Option } = Select

const Dropshipping: FC = () => {
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ productId, setProductId ] = useState<number | null>(null)
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })
  const isMobile = screen.width < 768

  const tableWidth = useTableWidth()
  const [ isManageColumnsVisible, setIsManageColumnsVisible ] = useState(false)
  const handleSort = (columnKey: string) => {
    setSorting((prevSorting) => {
      if (prevSorting[columnKey]) {
        return {
          [columnKey]:prevSorting[columnKey] === "ASC" ? "DESC" : "ASC"
        }
      } else {
        return {
          [columnKey]: "ASC",
        }
      }
    })
  }

  const setColumnsState = (sorting: any) => {
    const savedColumns = localStorage.getItem("savedDropshippingColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el: { key: string }) => el.key)
      const sortedDefaultColumns = [ ...DropshippingColumns({ setIsAddOpen, setProductId }) ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return columnsWithSortingHeader(sortedDefaultColumns.filter(el => [ ...columnsKeys ].includes(el.key)),sorting,sortedColumns,handleSort)
    }
    return columnsWithSortingHeader(DropshippingColumns({ setIsAddOpen, setProductId }),sorting,sortedColumns,handleSort)
  }
  const [ columns, setColumns ] = useState<Column[]>(()=>setColumnsState(sorting))

  const [ activeMarketplace, setActiveMarketplace ] = useState<number | null>(null)

  const { isLoading, itemData, pagesData, isUpdate } = useTypedSelector((state) => state.dropshipping)
  const { dropshippingGetList, setDropshippingUpdate } = useActions()
  const [ isSocket, setIsSocket ] = useState(false)
  const [ suppliersOptions, setSuppliersOptions ] = useState<{ id: string; name: string }[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ selectedValue, setSelectedValue ] = useState<string | null>(null)

  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-dropshipping"]?.status === true) {
            setIsSocket(true)
            setDropshippingUpdate(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }

  useEffect(() => {
    connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      dropshippingGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters,
      }, isSocket)
      setIsSocket(false)
      setDropshippingUpdate(false)
    }
  }, [ isUpdate, isSocket ])

  useEffect(() => {
    dropshippingGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting,
      filters,
    }, isSocket)
  }, [ filters,sorting ])
  useEffect(()=>{
    setColumns(setColumnsState(sorting))
  },[ sorting ])

  const handlePagination = (page:number,pageSize:number) => {
    dropshippingGetList({
      page: page,
      pageSize: pageSize,
      sorting,
      filters,
    })
  }

  const handleMarketplaceFilter = (marketplaceId: number) => {
    if (activeMarketplace === marketplaceId) {
      setFilters(defaultItemsFilters)
      setActiveMarketplace(null)
    } else {
      setFilters({
        ...defaultItemsFilters,
        marketplace_id: marketplaceId,
      })
      setActiveMarketplace(marketplaceId)
    }
  }

  const refreshTable = () => {
    dropshippingGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters,
    })
  }

  const handleImportExcel = async () => {
    try {
      await dropshippingApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters,
      })
    } catch (err) {
      console.log(err)
      const responseErrorMessage = (err as unknown as { response: { data: { message: string } } }).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  const handleUpdateDropshiping = async () => {
    setLoading(true)
    try {
      const response = await dropshippingApi.syncDropshipping()
      if (response.status === 200) {
        showSuccessMessage("Дропшиппинг успешно синхронизирован.")
      } else {
        const errorMessage = response.data?.message || "Произошла ошибка при синхронизации дропшиппинга"
        showErrorNotification(errorMessage)
      }
    } catch (error) {
      const err = error as { response?: { data?: { message?: string } } }
      const errorMessage = err.response?.data?.message || "Произошла ошибка при синхронизации дропшиппинга"
      showErrorNotification(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchSuppliers = async () => {
      setLoading(true)
      try {
        const response = await dropshippingApi.suppliersList()
        if (response.status === 200) {
          const suppliersData: Record<string, string> = response.data
          const options = Object.entries(suppliersData).map(([ id, name ]) => ({
            id,
            name: name as string,
          }))
          setSuppliersOptions(options)
        } else {
          const errorMessage = response.data?.message || "Произошла ошибка при загрузке поставщиков"
          showErrorNotification(errorMessage)
        }
      } catch (error) {
        const err = error as { response?: { data?: { message?: string } } }
        const errorMessage = err.response?.data?.message || "Произошла ошибка при загрузке поставщиков"
        showErrorNotification(errorMessage)
      } finally {
        setLoading(false)
      }
    }

    fetchSuppliers()
  }, [])

  const handleSelectChange = (supplierId: string | null) => {
    if (supplierId === null) {
      setFilters(defaultItemsFilters)
      setSelectedValue(null)
    } else if (selectedValue === supplierId) {
      setFilters(defaultItemsFilters)
      setSelectedValue(null)
    } else {
      setFilters({
        ...defaultItemsFilters,
        purveyor_id: supplierId,
      })
      setSelectedValue(supplierId)
    }
  }

  return (
    <MainLayout pageTitle="Дропшиппинг">
      <div className="page-wrapper">
        <PageHead titleStyles={{ fontWeight: "600" }} title="Дропшиппинг, закупки из заказов" extra={<div></div>} />
        <div className="page-content">
          <Space
            style={{ justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: activeMarketplace === 1 ? "#4096ff" : "",
                  color: activeMarketplace === 1 ? "#fff" : "",
                }}
                onClick={() => handleMarketplaceFilter(1)}
              >
                <img
                  alt={"Маркетплейс"}
                  style={{ borderRadius: "50%", width: "20px", marginRight: "6px" }}
                  src={marketPlacePictureMap[1]}
                />
                  WILDBERRIES
              </Button>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: activeMarketplace === 2 ? "#4096ff" : "",
                  color: activeMarketplace === 2 ? "#fff" : "",
                }}
                onClick={() => handleMarketplaceFilter(2)}
              >
                <img
                  alt={"Маркетплейс"}
                  style={{ borderRadius: "50%", width: "20px", marginRight: "6px" }}
                  src={marketPlacePictureMap[2]}
                />
                  OZON
              </Button>
              <Select
                style={{ width: "300px" }}
                placeholder="Поставщик"
                value={selectedValue}
                onSelect={handleSelectChange}
                onChange={handleSelectChange}
                options={suppliersOptions.map((supplier) => ({
                  label: supplier.name,
                  value: supplier.id,
                }))}
                loading={loading}
                allowClear
              />
            </div>
            <div style={{ display:"flex" }}>
              <Button
                disabled={loading}
                type="primary"
                onClick={handleUpdateDropshiping}
                className={"blueButton"}
                style={{
                  marginRight: "2%",
                  borderRadius: "4px",
                  height: "40px",
                }}
              >
                АКТУАЛИЗИРОВАТЬ ДРОПШИППИНГ
              </Button>
              <Button
                type="primary"
                icon={<FileExcelOutlined/>}
                onClick={handleImportExcel}
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  borderRadius: "4px",
                  color: "#fff",
                  height: "40px",
                }}
              >
                СКАЧАТЬ В EXCEL
              </Button>
            </div>
          </Space>
          <div className="universal-table-settings" style={{ width: tableWidth }}>
            <div style={{ display: "flex", justifyContent: "space-between",width:"100%",marginBottom:"10px" }}>
              <Button
                style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                onClick={() => setIsManageColumnsVisible(true)}
              >
                <MenuOutlined
                  style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
                />
                  СТОЛБЦЫ
              </Button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => {
                    localStorage.setItem(
                      "savedDropshippingColumns",
                      JSON.stringify(DropshippingColumns({ setIsAddOpen, setProductId })))
                    setColumns(setColumnsState(sorting))
                    showSuccessMessage("Порядок успешно возвращен.")
                  }}
                >
                    ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
                </Button>
              </div>
            </div>
            <Loading loading={isLoading}>
              <BaseTable
                columns={columns as any}
                data={itemData}
                loading={isLoading}
                fixed
                width={tableWidth}
                height={600}
                disabled={isLoading}
                rowKey="product_id"
                estimatedRowHeight={70}
                onEndReachedThreshold={300}
              />
            </Loading>
          </div>
          <Pagination
            disabled={isLoading}
            style={{ width: isMobile ? "100vw" : "100%" }}
            onChange={handlePagination}
            size={"small"}
            current={pagesData.currentPage}
            showSizeChanger={true}
            pageSizeOptions={[ "10", "25", "50", "100" ]}
            defaultCurrent={1}
            defaultPageSize={10}
            total={pagesData.total}
            showTotal={(total, range) => `${range[0]}-${range[1]} из ${total} карточек`}
          />
          {
            isAddOpen &&
                <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} productId={productId} onSuccess={refreshTable}/>
          }
        </div>

        {isManageColumnsVisible && (
          <ColumnSettings
            setVisible={setIsManageColumnsVisible}
            defaultColumns={columnsWithSortingHeader(DropshippingColumns({ setIsAddOpen, setProductId }),sorting,sortedColumns,handleSort)}
            columns={columns}
            setColumns={setColumns}
            localStorageKey={"savedDropshippingColumns"}
          />
        )}
      </div>
    </MainLayout>
  )
}

export default Dropshipping
