import axios from "axios"

import { axiosConfigAuth } from "../middleware"
import { API_PRICES_GET_LIST, API_PRICES_GET_LISTS
  ,API_PRICES_SEND } from "../urls"


export const priceApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_PRICES_GET_LIST, filters, axiosConfigAuth())
  },

  fetchListsData: async () => {
    return axios.get(API_PRICES_GET_LISTS, axiosConfigAuth())
  },

  sendData: async (data: any) => {
    return axios.post(API_PRICES_SEND, data, axiosConfigAuth())
  }
}
