import { authSliceActions } from "./authSlice"
import { authApi } from "../../../common/api/endpoints/authApi"
import {
  clearStorage,
  saveAccessToken
} from "../../../utils/auth"
import { showErrorNotification } from "../../../utils/ui"
import { TAppDispatch } from "../../store"
import { appActions } from "../app/actions"
import { currentUserActions } from "../currentUser/actions"

export const authActions = {
  ...authSliceActions,

  authLogin:
    (
      username: string,
      password: string,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      async (dispatch: TAppDispatch) => {
        dispatch(authActions.setAuthLoading(true))

        authApi
          .login(username, password)
          .then((response) => {
            if (response.status === 200) {
              dispatch(authActions.setIsAuth(true))

              saveAccessToken(response.data.access_token)
              onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            } else {
              showErrorNotification("Произошла ошибка, повторите попытку позже.")
            }
          })
          .finally(() => {
            dispatch(authActions.setAuthLoading(false))
          })
      },

  authLogout: (onFinish: () => void) => (dispatch: TAppDispatch) => {
    dispatch(appActions.setAppLoading(true))

    authApi
      .logout()
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        dispatch(authActions.setAuthClear())
        dispatch(currentUserActions.currentUserClearData())
        dispatch(authActions.setIsAuth(false))

        clearStorage()
        onFinish()
      })
  }

  // authSignup: (name: string, email: string, password: string, phone: string, agreed: boolean, url: string, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
  //     dispatch(authActions.setAuthLoading(true))
  //
  //     const fingerprint = await getFingerPrint()
  //
  //     authApi.signup(name, email, password, phone, agreed, url, fingerprint)
  //         .then((response) => {
  //             if (response.status === 200) {
  //                 dispatch(authActions.setIsAuth(true))
  //
  //                 saveAccessToken(response.data.access_token)
  //                 onFinish()
  //             }
  //         })
  //         .catch((err) => {
  //             if (err.response?.status === 400) {
  //                 onFormError(err.response.data)
  //             } else {
  //                 showErrorNotification("Произошла ошибка, повторите попытку позже.")
  //             }
  //         })
  //         .finally(() => {
  //             dispatch(authActions.setAuthLoading(false))
  //         })
  // },
}
