import React, { useState } from "react"

import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import { marketPlacePictureMap } from "../../../utils/data"

interface AdvancedDropdownProps {
    items: { id: number; name: string, marketplace_id?: number }[];
    selectedIds: number[];
    setSelectedIds: (ids: number[]) => void;
    minWidth?: string;
    multiple?:boolean;
    handleMenuItemClick?:(key:string)=>void
    additionalItems?:{ key: number; label: string}[];
}

const AdvancedDropdown: React.FC<AdvancedDropdownProps> = ({
  items,
  selectedIds,
  setSelectedIds,
  multiple=true,
  handleMenuItemClick=()=>{},
  additionalItems=[],
}) => {
  const [ dropdownVisible, setDropdownVisible ] = useState(false)

  const handleMenuClick = ({ key }: { key: string }) => {

    if(key == "-1"){
      handleMenuItemClick(key)
      setDropdownVisible(false)
      return
    }
    const id = parseInt(key, 10)
    const newSelectedIds = multiple ? selectedIds.includes(id)
      ? selectedIds.filter(selectedId => selectedId !== id)
      : [ ...selectedIds, id ] :[ id ]
    setSelectedIds(newSelectedIds)
  }

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    setSelectedIds(checked ? items.map((item) => item.id) : [])
  }

  const menuItems = [
    ...additionalItems,
    ...(multiple ?
      [
        {
          key: "selectAll",
          label: (
            <Checkbox
              onChange={handleSelectAllChange}
              checked={selectedIds.length === items.length}
              indeterminate={selectedIds.length > 0 && selectedIds.length < items.length}
            >
            Выбрать все
            </Checkbox>
          ),
        },
        {
          type: "divider",
        },
      ] : []),
    ...items.map((item) => ({
      key: item.id.toString(),
      label: (
        <div style={{ ...(selectedIds.includes(item.id) && { background:"#e6f4ff" }) }}>
          {item?.marketplace_id && (
            <img
              alt={"Маркетплейс"}
              style={{ borderRadius: "50%", width: "25px", marginRight: 4 }}
              src={marketPlacePictureMap[item.marketplace_id]}
            />
          )}
          {item.name}
        </div>
      ),
    })),
  ]

  return (
    <Dropdown
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      overlay={<Menu items={menuItems} onClick={handleMenuClick}/>}
      trigger={[ "click" ]}
      open={dropdownVisible}
      onOpenChange={setDropdownVisible}
    >
      <div style={{
        position: "relative",
        width: "25px",
        display: "inline-block",
        paddingLeft: "11px",
        paddingRight: "24px"
      }}>
        <div style={{ position: "absolute", top: "-7px", height: "100%", width: "100%", left: "1px" }}>
        </div>
        {dropdownVisible ? <UpOutlined/> : <DownOutlined/>}
        <div style={{ position: "absolute", height: "100%", width: "100%", left: "1px",bottom:"-4px" }}>
        </div>
      </div>
    </Dropdown>
  )
}

export default AdvancedDropdown
