import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { TelegramNotificationsData, TelegramNotificationsState } from "./types"


const initialState: TelegramNotificationsState = {
  tableData: [],
  isLoading: false,
}

export const telegramNotificationsSlice = createSlice({
  name: "telegramNotifications",
  initialState: initialState,
  reducers: {
    setTableData: (state, action: PayloadAction<TelegramNotificationsData[]>) => {
      state.tableData = action.payload
    },
    setTelegramNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  }
})

export const telegramNotificationsSliceReducer = telegramNotificationsSlice.reducer
export const telegramNotificationsSliceActions = telegramNotificationsSlice.actions
