export interface DropshippingState {
  isLoading: boolean;
  itemData: any[];
  pagesData: {
    total: number;
    totalPages: number;
    start: number;
    end: number;
    currentPage: number;
    pageSize: number;
  };
  isUpdate: boolean;
}

export const initialState: DropshippingState = {
  isLoading: false,
  itemData: [],
  pagesData: {
    total: 0,
    totalPages: 0,
    start: 0,
    end: 0,
    currentPage: 1,
    pageSize: 10,
  },
  isUpdate: false,
}