import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IMonitoringRow, IMonitoringState } from "./types"

const initialState: IMonitoringState = {
  dataPrice: [],
  dataOrder: [],
  isLoading: false,
  isUpdate: false
}

export const monitoringSlice = createSlice({
  name: "monitoring",
  initialState: initialState,
  reducers: {
    setMonitoringPriceData: (state, action: PayloadAction<IMonitoringRow[]>) => {
      state.dataPrice = action.payload
    },
    setMonitoringOrderData: (state, action: PayloadAction<IMonitoringRow[]>) => {
      state.dataOrder = action.payload
    },
    setMonitoringLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setMonitoringUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const monitoringSliceReducer = monitoringSlice.reducer
export const monitoringSliceActions = monitoringSlice.actions
