import qz, {ClearQueueOptions, CreatePrinterInput, PrintData} from "qz-tray"

import { showErrorMessage } from "./ui"
const certificate = `-----BEGIN CERTIFICATE-----
MIIGCTCCA/GgAwIBAgIUdTi9/DZOZS0IDpYBN4yvvgEOiwkwDQYJKoZIhvcNAQEL
BQAwgZMxCzAJBgNVBAYTAnJ1MQ8wDQYDVQQIDAZtb3Njb3cxDzANBgNVBAcMBm1v
c2NvdzESMBAGA1UECgwJc2VsbGVyY3JtMRkwFwYDVQQLDBBzZWxsZXJjcm1zZWN0
aW9uMQ8wDQYDVQQDDAZzZWxsZXIxIjAgBgkqhkiG9w0BCQEWE3NlbGxlcmNybUBn
bWFpbC5jb20wHhcNMjQwOTE5MTM0OTMzWhcNMjUwOTE5MTM0OTMzWjCBkzELMAkG
A1UEBhMCcnUxDzANBgNVBAgMBm1vc2NvdzEPMA0GA1UEBwwGbW9zY293MRIwEAYD
VQQKDAlzZWxsZXJjcm0xGTAXBgNVBAsMEHNlbGxlcmNybXNlY3Rpb24xDzANBgNV
BAMMBnNlbGxlcjEiMCAGCSqGSIb3DQEJARYTc2VsbGVyY3JtQGdtYWlsLmNvbTCC
AiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBALNSayEWfWydDB+HkQW7qhSy
cVIFoyMPlbAdvadSnaPXMQhyu3h2/kG/mbCGE7q5K9BbbmzqicEArWIkk8RVa4rG
HtRPRQBYfuWMwjWtm79nOY4rGl2Tvv+iv7u3H0P7t7TgFFjAdGkci4RnwTV5lQ8g
GwAlgBnXYYsxF17GnmiKfKPVox0X7g+3lmVHGfBfyGw75kW6rn2N3oYnzurBxncM
Yav55bst3vQ32xH4+wa/oqgJ1qf8lY4Sv5jZWQIvG9ev0fFiBHNmWmiWFV41l7NO
pBjXCt0ougDTz59nmtsXNbuFoSwG3hoIOvsRfshiCuIVf4YKx04GIVhlo08Atlis
DUqw2zTbS2e+lBX9HbEXu6vNtNFQnqq/p22GmAshy58rE+BliQ5SCdLXFyimBIu8
wtiq+Zh40S6qZRxqwuw+EZVL63b4y9osw0aIJnCZRMD8X/hEb1lQmNXi7VfKGAuV
ovZeNRopHtegKsF8+DtGpsX1TxeAcDfGX/duWWZmsEEmKE5UiiME+zr+cbg0M7eG
H7CuebR93pwW0vCamp9YK8Tifj5fUeBUXvxl5G1rCVg+h5hWldGxHGreeXWOnQIU
TQ9uRC5myDG+mP69x0wr59na9FW8jgl3UDK6DOZdx8ULuITW9Wq21EPzaixZqRPe
qLQ+APBn8vJn5Dsjwk4hAgMBAAGjUzBRMB0GA1UdDgQWBBSwtAfX2r/ZvE6nylFK
2MYEsiWyhTAfBgNVHSMEGDAWgBSwtAfX2r/ZvE6nylFK2MYEsiWyhTAPBgNVHRMB
Af8EBTADAQH/MA0GCSqGSIb3DQEBCwUAA4ICAQCNAy8BdpJrhaXLDXfZxfZdV/Jc
kw9z6qvKep6x6RXvORq3ANbJDClF9mrGtoeqCdwZ7ENL61Xymu09psnXvVqYATCp
2tM9utggRdDm6WAgPac2tb8ORiTLrCJvTEwVAtghQb+Fz7tqHocpSS0BuRjmEUZj
AINFcn0a8RaULRWl/PWCc7EFbA5xwZChPJli4Zg3vmOlnP5KYnj2wxET02P1aQoV
lWsZNm77w2ieQqte34bremELB0dGxdsbqEQcJr8ofEs0pp0WQTrvMCaS8KqcLRSR
/xTAbyGMAEKM1S93OSzCHSSMAFAv68i6twIvXMAEM909RJQFDG8oEEYToPMYJJRL
UzJZE0YkQQ1r7bqB/xhFNmNoMZw60eTx15iex4Usr4qRHgVhxk+NSiTAYPfN510K
4jAJVYnSbnWmZaN+u4d6nIj1WGlfvGnwYGAsXpSN5VXiQu6jkikV2Z9HdxR0wWIM
ORCz6Awkc+BPmDnZjIHywlYoEqI0wImJudIBmqYaMzk1EqCtP7wUru78vcn1hET4
n1e4tQDtUIxTdXGJRVU25Bes8aFaHYp5JN2myzl+u6jaEOM9sfOq66Ca0UVkMhV8
5m1sb91woVKjLz/yc9j3t8GyuQZYypm9CbV6J00RNss6w/wIfG9KPLK1WEkBHGp5
4LHvRA0bVabWT0eDhQ==
-----END CERTIFICATE-----
`
const privateKeyPem = `-----BEGIN PRIVATE KEY-----
MIIJQwIBADANBgkqhkiG9w0BAQEFAASCCS0wggkpAgEAAoICAQCzUmshFn1snQwf
h5EFu6oUsnFSBaMjD5WwHb2nUp2j1zEIcrt4dv5Bv5mwhhO6uSvQW25s6onBAK1i
JJPEVWuKxh7UT0UAWH7ljMI1rZu/ZzmOKxpdk77/or+7tx9D+7e04BRYwHRpHIuE
Z8E1eZUPIBsAJYAZ12GLMRdexp5oinyj1aMdF+4Pt5ZlRxnwX8hsO+ZFuq59jd6G
J87qwcZ3DGGr+eW7Ld70N9sR+PsGv6KoCdan/JWOEr+Y2VkCLxvXr9HxYgRzZlpo
lhVeNZezTqQY1wrdKLoA08+fZ5rbFzW7haEsBt4aCDr7EX7IYgriFX+GCsdOBiFY
ZaNPALZYrA1KsNs020tnvpQV/R2xF7urzbTRUJ6qv6dthpgLIcufKxPgZYkOUgnS
1xcopgSLvMLYqvmYeNEuqmUcasLsPhGVS+t2+MvaLMNGiCZwmUTA/F/4RG9ZUJjV
4u1XyhgLlaL2XjUaKR7XoCrBfPg7RqbF9U8XgHA3xl/3bllmZrBBJihOVIojBPs6
/nG4NDO3hh+wrnm0fd6cFtLwmpqfWCvE4n4+X1HgVF78ZeRtawlYPoeYVpXRsRxq
3nl1jp0CFE0PbkQuZsgxvpj+vcdMK+fZ2vRVvI4Jd1AyugzmXcfFC7iE1vVqttRD
82osWakT3qi0PgDwZ/LyZ+Q7I8JOIQIDAQABAoICAAaOfajGzfy17E7N0eVkBHa/
xIlzd9DNHdPD7p6K1IU8Vchhi42+yhKk2w17hYanBR1X7nxwgetq5ywGutlrpyQ6
lL9+C3+U/MTPZf149NAlidLzLAJeZ9YogPqv2iaN8lxcchoBpW//cXF8qrGWGbdl
HN+fP2USfThN6fUW7Y1UfPSvl8h1SsjL1WWsGRxzIBym/wcvKXihQpDkrTnjrmpF
a1/Sog37QiLrVOj+Ec2gHaYnv+/B+kTo1hUUY18DUnELNnGtPAztIeKflDXu3zZY
6oKXAEhwWAa2N+nz65NVsKsD2o4FzUKl6OQrsldI0G2OTCyeQcXOTxNQmg1GMSSS
jsoInT522tqb7ooq8KDumpARcgyCS6kqvx5DSo3XRyvmOWRE1oz7TJBwaOK00aZR
q7uU+zJrzt/UV97D5EUw/5rh22VMhntVI9EMKJ9XyVy0FCN8VOTwgY18KB83PHJP
C7lTJTAi0UiPgvZRf9SPSUYTbHLCPe34gNs92evd6HVqKO28tNjOYkBsWNiibnjE
bVIy5E9nBqrmIVS93LA0GJkycTZsuN+ihTySjkmAJnapXR+Gt5V9XV47F7JkJ1d0
y6AOOgP7INCjU/J2JQChgvWFTqMxepR38eEpZNuCib8NI9j0Dkx/lkN+U81FuAgx
pX4CYGkSOvah+KaebEMxAoIBAQD0DW9rFsEP0xJnG9d2paRkBZeh+Zc0Zbo80Jlm
tdw8RtIZOB2PmZ1en50X7Lc4FOgRGDMCERql87nTCR05eK+hqsrjxAawX+cLttaK
1DEFYPwr0llhgzFZ6w/bPvzzIgISvKuN+373nN9vsDeJyxGjy7nqUmHZDBvcwURS
La0gg1Rzq4pQi0Gd7/s8uqes9ixQdzI6G2aSBpq9wI9VfJlgK9mnqTdge5GqCHcG
9/IxqSbYaw/ai+KW4zm3reoyW06Muo0HtNVdqHZGVpZiq4H7BrSE1hBOjQfbfnWZ
I4L3fhLddOfYCEs53aKT5NBvSPTmDWDpE5/9mJ8zQgKFn4a5AoIBAQC8GcEAdMXV
GsBAZ4p1nGnWCDcobwhlVPXqhHV6yrFAYSBbkbnj+fvMEYoRZJoBHWBHGpvBPIbM
E4a2buvoZiNel+S3+4NqH9iRZRbdwrDHEVxxzwXMC9R72QPxxM2zcsK1S3o8GU/7
YN01hizZOgefuMt+jUM90cBBq1VQjiXIKD4sACfBzaD74jgHBXSntNsNRADm//cw
nEQ/U7ju20x5qdVFaxbeAeJLRRIKFg9vi1IHPVDr4b4SQLaryLA1DPDB3jeGrrwU
7JWAefvNnH6vTrNDWh/rLwfNaAP7whxJSCoBua5d2QF659NIChbRlZLkqZI3ou/S
pTrhl/tylk6pAoIBAAGLoDv+XeSawpASlg+zRtI3Utl8uh9j0YUXERGcbzOWgzgT
qeRB5cQ2oIkJP7PENh/9lj0aHKAWZgPXF+9IO1x7t2Mv15cAVHc3hi5E37aYAaDJ
aTV73jEikuwhfvqPKZIyzadNg2VQAf40EHX36jmuTZQVR3dXY0nHk4Ytsd311vAQ
kwSljRnR7jZZPDyVJnyv9Ip3p/OexRYUhRPWgSYhwPQV1zruLWL1QMOziD4WQx+1
tdj9b8bi03e9SzFccKfleoUk+QDp0y3o2HTxfxY22afJrzrAG9+g8l+w4OBuVPJA
DkPI8akBJUlodoVt4dcfGJEBRc8WS1PIxDlSZEkCggEBAKO4ut+9xBAXx6yHMuHp
sckkv3XHJwSkLaPhhSvKzvsdTv/E2IsRJFRcE9PNv6omuo1JILbSCvYILOIPq/BN
Lo4AIYjuN1UgwOzUk1GUSzAJ8W/x5LbUuS7PFnrSdMSzp7wLJSq4UYCYsBINZm3w
73/bZewR4qwlLHU8Pvbtsv8D4BGGVRR/+ZYkMeYSnpX2eg+S2EI4GxzJdzGzmqz0
Xh0rcMGKU44LD7+6L9htmSPanRxGB3A4vaq/fwAIny6dw9TKqYPmBTklL39zLXOq
ui22j5uZD10yQ1VLKuRv8RTr5fw4dQekgYBL1f/HIFNuIDw9SFJ61MmBHb4L/kWf
hHkCggEBAN3ZNmdJsP0ccz8NIRZCtIQZt/KGaVTX4YJFn4E1bhcOyZtSpyEG8LDP
jY1wukgvmADnhWoPrthigZzs/yMSYJSQ5kTPQfXu7kjmIBFnP6P3wM/UAaKTiQ2c
fxrBBcLJKgw9wvGAeXW6YRdu9DztiAszsei+r0wVU8wlJmka/MmehPZtEY715vNj
6Ai/JVpLoIvSssTZHMSrFCo0fk/kuJTAk96YDi0SALdzwoGOMBxhlaxh8ltq79Hl
aUIiAWI0+WcT7/bhXv9QpYm1AxLxRw7lN0A88IbDxdApqIlyxuKeqGyM8dmXwclk
9eD8MnXzNXZqJiCCS0bghIx4RKwV2Tg=
-----END PRIVATE KEY-----
`
function pemToBinary(pem: string): Uint8Array {
  const pemContent = pem.replace(/-----BEGIN [\w\s]+-----|-----END [\w\s]+-----|\s+/g, "")
  if (!/^[A-Za-z0-9+/=]+$/.test(pemContent)) {
    throw new Error("Invalid PEM format")
  }
  return Uint8Array.from(atob(pemContent), c => c.charCodeAt(0))
}

async function importPrivateKey(pemKey: string) {
  const binaryDer = pemToBinary(pemKey)
  return window.crypto.subtle.importKey(
    "pkcs8",
    binaryDer,
    {
      name: "RSASSA-PKCS1-v1_5",
      hash: { name: "SHA-512" },
    },
    true,
    [ "sign" ]
  )
}

async function signData(privateKey: CryptoKey, toSign: string) {
  const encoded = new TextEncoder().encode(toSign)
  const signature = await window.crypto.subtle.sign(
    { name: "RSASSA-PKCS1-v1_5" },
    privateKey,
    encoded
  )
  return signature
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const byteArray = new Uint8Array(buffer)
  let binaryString = ""
  for (let i = 0; i < byteArray.length; i++) {
    binaryString += String.fromCharCode(byteArray[i])
  }
  return btoa(binaryString)
}

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = (reader.result as string).split(",")[1]
      resolve(base64String)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const printWithQZTray = async (pdfBlob: any) => {
  const blob = new Blob([ pdfBlob ], { type: "application/pdf" })
  const base64String = await blobToBase64(blob)

  try {
    const isActive = qz.websocket.isActive()
    if (!isActive) {
      qz.security.setCertificatePromise((resolve) => {
        resolve(certificate)
      })

      const privateKey = await importPrivateKey(privateKeyPem)
      qz.security.setSignatureAlgorithm("SHA512")
      qz.security.setSignaturePromise((toSign) => {
        return async (resolve, reject) => {
          try {
            const signature = await signData(privateKey, toSign)
            const base64Signature = arrayBufferToBase64(signature)
            resolve(base64Signature)
          } catch (err: any) {
            console.error("Error signing data:", err)
            reject(err)
          }
        }
      })

      await qz.websocket.connect()
    }

    const printers = await qz.printers.find()
    if (Array.isArray(printers) && printers.length === 0) {
      showErrorMessage("Проверьте подключение к принтеру.")
      await qz.websocket.disconnect()
      return "refresh"
    }

    let printerFound = false
    for (const printer of printers) {
      await qz.printers.clearQueue(printer as ClearQueueOptions)
      if (printer.includes("Xprinter XP-420B")) {
        printerFound = true
        const config = qz.configs.create(printer as CreatePrinterInput)
        const data = [ { type: "pixel", format: "pdf", data: base64String, flavor: "base64" } ] as PrintData[]

        await qz.print(config, data).catch(console.error)
      }
    }

    if (!printerFound) {
      showErrorMessage("Не удалось найти принтер Xprinter XP-420B.")
    }
  } catch (err) {
    showErrorMessage("Проверьте подключение к принтеру.")
    console.error("QZ Tray connection failed:", err)
    return "refresh"
  }
}
