import React, { FC, useState } from "react"

import { Tabs } from "antd"

import OrderTab from "./tabs/OrderTab"
import PriceTab from "./tabs/PriceTab"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"

const MonitoringPage: FC = () => {
  const [ currentTab, setCurrentTab ] = useState("1")

  const tabs = [
    {
      key: "1",
      label: "Проверка цен",
      children: (
        <PriceTab />
      ),
    },
    {
      key: "2",
      label: "Проверка количества заказов",
      children: (
        <OrderTab />
      ),
    },
  ]

  return (
    <MainLayout pageTitle="Мониторинг">
      <div className="page-wrapper">
        <PageHead
          title="Мониторинг"
        />

        <div className="page-content">
          <Tabs
            activeKey={currentTab}
            items={tabs}
            type="card"
            onChange={(value) => setCurrentTab(value)}
            destroyInactiveTabPane
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default MonitoringPage
