import { InputNumber } from "antd"
type Props = {
    value:number
    onChange:(value:number | null)=>void
    currency?:string
}
const NumericInput = ({ value, onChange,currency="", ...props }:Props) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <InputNumber
      value={value}
      onChange={onChange}
      style={{ width: "100%" }}
      {...props}
    />
    {currency.length > 0  && <span style={{ marginLeft: 8 }}>{currency}</span>}
  </div>
)
export default NumericInput
