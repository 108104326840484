export interface PurchaseFilters {
  page?: number;
  pageSize?: number;
  sorting?: {
    [key: string]: 'ASC' | 'DESC';
  };
  filters?: {
    data?: {
      start?: string;
      end?: string;
    };
    nm_id?: string;
    nm_name?: string;
    barcode?: string;
    user_id?: number[];
    purveyor_id?: number[];
    partner_id?: number[];
    marketplace_id?: number[];
  };
}


export interface PurchaseState {
  isLoading: boolean;
  itemData: any[];
  pagesData: {
    total_purchase_amount: number | string;
    total_real_purchase_amount: number | string;
    total: number;
    totalPages: number;
    start: number;
    end: number;
    currentPage: number;
    pageSize: number;
    header: {
      id: number;
      name: string;
      phone: string | null;
    };
    rows: any[];
  };
  isUpdate: boolean;
  filtersData?: {
    user_list?: { id: number; name: string }[];
    partner_list?: { id: number; name: string }[];
    marketplace_list?: { [key: string]: string };
  };
}

export const initialState: PurchaseState = {
  isLoading: false,
  itemData: [],
  pagesData: {
    total_purchase_amount: 0,
    total_real_purchase_amount: 0,
    total: 0,
    totalPages: 0,
    start: 0,
    end: 0,
    currentPage: 1,
    pageSize: 10,
    header: {
      id: 0,
      name: '',
      phone: null,
    },
    rows: [],
  },
  isUpdate: false,
};
