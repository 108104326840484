import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Input, Modal, Space, Switch, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { showSuccessMessage } from "../../utils/ui"
const TelegramNotificationsPage: FC = () => {
  const { tableData, isLoading } = useTypedSelector((state) => state.telegramNotifications)
  const { telegramNotificationsGetData,telegramNotificationsRemoveUser,telegramNotificationsUpdateStatus } = useActions()

  const [ tempData, setTempData ] = useState(tableData)

  useEffect(() => {
    setTempData(tableData)
  }, [ tableData ])

  useEffect(() => {
    telegramNotificationsGetData()
  }, [])
  const partnersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width:2,
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Пользователь",
      dataIndex: "telegram",
      key: "telegram",
      width:10,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <a href={val.url} target={"_blank"}>
          <Tooltip placement="topLeft" title={val.name}>
            {val.name}
          </Tooltip>
        </a>
      )
    },
    {
      title: "Проверка цен",
      dataIndex: "param_1",
      key: "param_1",
      width:10,
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={() => telegramNotificationsUpdateStatus({ id:record.id,param:"param_1", status_id:value !== 1 ? 1 : 0 })}
        />
      )
    },
    {
      title: "Проверка кол-ва заказов",
      dataIndex: "param_2",
      key: "param_2",
      width:10,
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={() => telegramNotificationsUpdateStatus({ id:record.id,param:"param_2", status_id:value !== 1 ? 1 : 0 })}
        />
      )
    },
    {
      title: "Формирование файлов",
      dataIndex: "param_3",
      key: "param_3",
      width:10,
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={() => telegramNotificationsUpdateStatus({ id:record.id,param:"param_3", status_id:value !== 1 ? 1 : 0 })}
        />
      )
    },
    {
      title: "Импорт товаров/заказов",
      dataIndex: "param_4",
      key: "param_4",
      width:10,
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={() => telegramNotificationsUpdateStatus({ id:record.id,param:"param_4", status_id:value !== 1 ? 1 : 0 })}
        />
      )
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width:4,
      render: (value, record) => (
        <Space style={{ display:"flex",justifyContent:"center" }} direction="horizontal" align={"center"}>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить пользователя?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  telegramNotificationsRemoveUser(record.id, () => {
                    showSuccessMessage("Пользователь успешно удален.")
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]

  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    if (value) {
      const _data = tempData.filter((val: any) => {
        return (
          `${val.telegram.name}`.toLowerCase().includes(searchString) ||
              `${val.id}`.toLowerCase().includes(searchString)
        )
      })

      setTempData(_data)
    } else {
      setTempData(tableData)
    }
  }

  return (
    <MainLayout pageTitle="Уведомления в Telegram">
      <div className="page-wrapper">
        <PageHead
          title="Уведомления в Telegram"
          extra={
            <div>
            </div>
          }
        />

        <div className="page-content">
          <Space>
            <Input
              placeholder="Поиск по таблице..."
              onChange={(e) => searchTable(e.target.value)}
              style={{ width: 300, marginBottom: 20 }}
              allowClear
            />
          </Space>
          <Table
              virtual={true}
            columns={partnersColumns}
            dataSource={tempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            size="small"
            bordered
          />


        </div>
      </div>
    </MainLayout>
  )
}

export default TelegramNotificationsPage
