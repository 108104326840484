import { exportSliceActions } from "./exportSlice"
import { exportApi } from "../../../common/api/endpoints/exportApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const exportActions = {
  ...exportSliceActions,

  getExportData: () => async (dispatch: TAppDispatch) => {
    dispatch(exportActions.setExportLoading(true))

    exportApi.getExportData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(exportActions.setTableData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(exportActions.setExportLoading(false))
      })
  },
  exportDownloadFile: (exportId:number, filename: string) => async () => {
    exportApi.downloadFile(exportId, filename)
  },
  exportDeleteFile: (export_id: number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
    exportApi.removeExportFile(export_id)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
}
