import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IMonitoringRow, IMonitoringState } from "./types"

const initialState: IMonitoringState = {
  data: [],
  isLoading: false,
  isUpdate: false
}

export const monitoringSettingsSlice = createSlice({
  name: "monitoringSettings",
  initialState: initialState,
  reducers: {
    setMonitoringSettingsData: (state, action: PayloadAction<IMonitoringRow[]>) => {
      state.data = action.payload
    },
    setMonitoringSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setMonitoringSettingsUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const monitoringSettingsSliceReducer = monitoringSettingsSlice.reducer
export const monitoringSettingsSliceActions = monitoringSettingsSlice.actions
