export interface DropshippingSuppliersPagesData {
  total: number;
  totalPages: number;
  start: number;
  end: number;
  currentPage: number;
  pageSize: number;
  header: {
    id: number;
    name: string;
    phone: string | null;
  };
  rows: any[];
}
export interface DropshippingState {
  isLoading: boolean;
  itemData: any[];
  pagesData: DropshippingSuppliersPagesData
  isUpdate: boolean;
}

export const initialState: DropshippingState = {
  isLoading: false,
  itemData: [],
  pagesData: {
    total: 0,
    totalPages: 0,
    start: 0,
    end: 0,
    currentPage: 1,
    pageSize: 10,
    header: {
      id: 0,
      name: '',
      phone: null,
    },
    rows: [],
  },
  isUpdate: false,
};
