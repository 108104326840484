import React, { FC, useState } from "react"

import { Tabs } from "antd"

import TableTab from "./tabs/TableTab"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import {marketPlacePictureMap} from "../../utils/data";

const ProductsImportPage: FC = () => {
  const [ currentTab, setCurrentTab ] = useState("1")

  const tabs = [
    {
      key: "1",
      label:<div style={{ display:"flex",columnGap:"5px",alignItems:"center" }}><img style={{ width:"25px",borderRadius:"50%" }} src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs3LbIGDAcXecrb_rMzHReSjKXYEKuJFLgqRvZqcSAhHBbS5UJoTZoBQFcBtGPRezTJbc&usqp=CAU"} /><span>OZON</span></div>,

      children: (
        <TableTab marketplace_id={2} />
      ),
    },
    {
      key: "2",
      label:<div style={{ display:"flex",columnGap:"5px",alignItems:"center" }}><img style={{ width:"25px",borderRadius:"50%" }} src={marketPlacePictureMap[1]} /><span style={{ color: "#b415b4" }}>WILDBERRIES</span></div>,
      children: (
        <TableTab marketplace_id={1} />
      ),
    },
  ]

  return (
    <MainLayout pageTitle="Импорт товаров">
      <div className="page-wrapper">
        <PageHead
          title="Импорт товаров"
        />

        <div className="page-content">
          <Tabs
            activeKey={currentTab}
            items={tabs}
            type="card"
            onChange={(value) => setCurrentTab(value)}
            destroyInactiveTabPane
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default ProductsImportPage
