import React, { FC, useEffect, useState } from "react"

import { DatePicker, Form, Input, Modal, Select } from "antd"
import AdvancedSelect from "../../../common/components/selects/AdvancedSelect"

import { staffApi } from "../../../common/api/endpoints/staffApi"
import { PhoneRegex } from "../../../common/constants/regex"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { IList } from "../../../types/lists"
import { showErrorNotification, showFormErrors } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
}

const AddModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [ form ] = Form.useForm()

  const [ managers, setManagers ] = useState<IList[]>([])
  const [ partners, setPartners ] = useState<IList[]>([])
  const [ roles, setRoles ] = useState<IList[]>([])
  const [ wpStatus, setWpStatus ] = useState<IList[]>([])
  const [ isFormLoading, setIsFormLoading ] = useState(false)
  const [partnerIds, setPartnerIds] = useState<number[]>([]);

  const { isLoading } = useTypedSelector((state) => state.staff)
  const { staffCreate, staffGetList, authLogout } = useActions()

  const errorList = () => {
    setIsFormLoading(false)
    showErrorNotification(
      "Ошибка при получении данных, повторите попытку позже."
    )
    setIsOpen(false)
  }

  const getFormData = () => {
    setIsFormLoading(true)

    staffApi
      .fetchManagers()
      .then((response) => {
        setManagers(response.data)
        staffApi
          .fetchPartners()
          .then((response) => {
            setPartners(response.data)
            staffApi
              .fetchRoles()
              .then((response) => {
                setRoles(response.data)
                staffApi
                  .fetchWpStatuses()
                  .then((response) => {
                    setWpStatus(response.data)
                    setIsFormLoading(false)
                  })
                  .catch((err) => {
                    if (err.response?.status === 401) {
                      authLogout(() => {
                        showErrorNotification(
                          "Сессия истекла. Пожалуйста, войдите снова."
                        )
                        window.location.href = "/"
                      })
                      return
                    }
                    errorList()
                  })
              })
              .catch((err) => {
                if (err.response?.status === 401) {
                  authLogout(() => {
                    showErrorNotification(
                      "Сессия истекла. Пожалуйста, войдите снова."
                    )
                    window.location.href = "/"
                  })
                  return
                }
                errorList()
              })
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              authLogout(() => {
                showErrorNotification(
                  "Сессия истекла. Пожалуйста, войдите снова."
                )
                window.location.href = "/"
              })
              return
            }
            errorList()
          })
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          authLogout(() => {
            showErrorNotification("Сессия истекла. Пожалуйста, войдите снова.")
            window.location.href = "/"
          })
          return
        }
        errorList()
      })
  }

  const addStaff = (values: any) => {
    staffCreate(
        { ...values, partner_ids: partnerIds },
        () => {
          setIsOpen(false);
          staffGetList();
        },
        (errors) => {
          showFormErrors(form, errors);
        }
    );
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
      getFormData()
      setPartnerIds([]);
    }
  }, [ isOpen ])

  return (
    <Modal
      title="Добавление сотрудника"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addStaff}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Логин пользователя"
          name="username"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item
          label="Старший менеджер"
          name="manager_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select loading={isFormLoading}>
            {managers.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
            label="Партнеры"
            name="partners"
            rules={[{ required: true, message: "Поле обязательное" }]}
        >
          <AdvancedSelect
              items={partners.map(item => ({
                id: Number(item.id),
                name: item.name,
              }))}
              selectedIds={partnerIds}
              setSelectedIds={(ids) => {
                setPartnerIds(ids)
                form.setFieldsValue({ partners: ids })
              }}
              placeholder="Выберите партнеров"
          />
        </Form.Item>

        <Form.Item label="Название группы WhatsApp" name="whatsapp_group_name">
          <Input />
        </Form.Item>

        <Form.Item
          label="URL группы WhatsApp"
          name="whatsapp_group_url"
          rules={[ { type: "url", message: "Требуется URL" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Номер телефона WhatsApp"
          name="whatsapp_phone"
          rules={[ { pattern: PhoneRegex, message: "Требуется номер телефона" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Номер телефона WB"
          name="phone_binding_wb"
          rules={[ { pattern: PhoneRegex, message: "Требуется номер телефона" } ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Роль пользователя"
          name="role"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select loading={isFormLoading}>
            {roles.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Статус участника группы WhatsApp"
          name="whatsapp_group_status"
        >
          <Select loading={isFormLoading} allowClear>
            {wpStatus.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Реквизиты для перевода ЗП" name="requisites">
          <Input />
        </Form.Item>

        <Form.Item label="Дата принятия на работу" name="date_of_employment">
          {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*/}
          <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" />
        </Form.Item>

        <Form.Item label="Комментарий" name="comment">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddModal
