import axios from "axios"

import { AddExportTask } from "../../../redux/slices/export/types"
import { getAccessToken } from "../../../utils/auth"
import { axiosConfigAuth } from "../middleware"
import {
  API_EXPORT_ADD_TASK,
  API_EXPORT_DOWNLOAD_FILE,
  API_EXPORT_GET_DATA,
  API_EXPORT_REMOVE_FILE,
} from "../urls"
import {showErrorNotification} from "../../../utils/ui";

export const exportApi = {
  addExportTask:async (data:AddExportTask) => {
    return axios.post(API_EXPORT_ADD_TASK,data,axiosConfigAuth())
  },
  getExportData: async () => {
    return axios.get(API_EXPORT_GET_DATA,axiosConfigAuth())
  },
  removeExportFile: async (exportId:number) => {
    return axios.get(`${API_EXPORT_REMOVE_FILE}${exportId}`,axiosConfigAuth())
  },
  downloadFile: (exportId: number, filename: string): void => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", `${API_EXPORT_DOWNLOAD_FILE}${exportId}/${getAccessToken()}`, true)
    xhr.responseType = "blob"

    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([ this.response ], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `${filename}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        return
      }
      if (this.status === 400) {
        const reader = new FileReader()
        reader.onload = function() {
          try {
            const response = JSON.parse(reader.result as string)
            showErrorNotification(response.message)
          } catch (e) {
            console.log("Error parsing JSON response: ", e)
          }
        }
        reader.readAsText(this.response)
      }
    }

    xhr.send()


  },
}
