import { priceSliceActions } from "./priceSlice"
import { priceApi } from "../../../common/api/endpoints/priceApi"
import { handleResponseError } from "../../../common/api/middleware"
import { showErrorNotification } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const priceActions = {
  ...priceSliceActions,

  priceGetListsData: () => (dispatch: TAppDispatch) => {
    dispatch(priceActions.setItemListsLoading(true))

    priceApi
      .fetchListsData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(priceActions.setItemLists({ ...response.data }))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(priceActions.setItemListsLoading(false))
      })
  },

  priceGetList: (filters: any) => (dispatch: TAppDispatch) => {
    dispatch(priceActions.setItemLoading(true))

    priceApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(priceActions.setPriceData(data.rows.map((el:{id:number})=>({ ...el,key:el.id }))))
          dispatch(
            priceActions.setItemPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
          dispatch(priceActions.setItemBannerData(data.banner))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(priceActions.setItemLoading(false))
      })
  },
  priceSend: (
    data: any,
    onFinish: () => void,
  ) =>
    (dispatch: TAppDispatch) => {
      dispatch(priceActions.setItemLoading(true))

      priceApi
        .sendData(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err)
        })
        .finally(() => {
          dispatch(priceActions.setItemLoading(false))
        })
    },
}
