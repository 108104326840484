import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IItemState, IItemRow, ImportResults } from "./types"

const initialState: IItemState = {
  itemData: [],
  supplierData: [],
  pagesData: {
    total: 2,
    totalPages: 1,
    start: 1,
    end: 20,
    currentPage: 1,
    pageSize: 20
  },
  filters: {},
  isLoading: false,
  isUpdate: false,
  isSocket: false,
  isLoadingLists: false,
  companies:[],
  importResults:{} as ImportResults
}

export const stockSuppliers = createSlice({
  name: "stockSuppliers",
  initialState: initialState,
  reducers: {
    setStockSuppliersImportResults: (state, action: PayloadAction<ImportResults>) => {
      state.importResults = action.payload
    },
    setStockSuppliersTableData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload.map((el)=>({ ...el,key:el.id }))
    },
    setStockSupplierSocket: (state, action: PayloadAction<boolean>) => {
      state.isSocket = action.payload
    },
    setSupplierItem: (state, action: PayloadAction<any>) => {
      state.supplierData = { ...action.payload,rows:action.payload.rows.map((el:any)=>({ ...el,key:el.id })) }
    },
    setStockSuppliersPagesData: (state, action: PayloadAction<any>) => {
      state.pagesData = action.payload
    },
    setStockSuppliersFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    setStockSuppliersCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload
    },
    setItemListsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLists = action.payload
    },
    setItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStockSuppliersUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const stockSuppliersSliceReducer = stockSuppliers.reducer
export const stockSuppliersSliceActions = stockSuppliers.actions
