import axios from "axios"

import { TAppDispatch } from "../../../redux/store"
import {AddCookies, PrintLabel, SyncSupplier, TransferToDelivery, UpdateOrderStatus} from "../../../types/api"
import { getAccessToken } from "../../../utils/auth"
import { showErrorNotification } from "../../../utils/ui"
import { axiosConfigAuth, handleResponseError } from "../middleware"
import {
  API_MONITORING_DOWNLOAD_FILE,
  API_SBERMARKET_COOKIES,
  API_STOCK_SUPPLIER_DOWNLOAD_EXCEL,
  API_STOCK_SUPPLIERS_ADD_IMPORT_TASK,
  API_STOCK_SUPPLIERS_DELETE_SUPPLIER,
  API_STOCK_SUPPLIERS_DOWNLOAD_EXCEL,
  API_STOCK_SUPPLIERS_GET_IMPORT_RESULT,
  API_STOCK_SUPPLIERS_GET_PARTNERS,
  API_STOCK_SUPPLIERS_GET_SUPPLIER,
  API_STOCK_SUPPLIERS_GET_TABLE_DATA,
  API_STOCK_SUPPLIERS_LABEL_DOWNLOAD,
  API_STOCK_SUPPLIERS_PRINT_LABEL,
  API_STOCK_SUPPLIERS_PRINT_STICKER,
  API_STOCK_SUPPLIERS_STICKER_DOWNLOAD,
  API_STOCK_SUPPLIERS_STICKER_REMOVE,
  API_STOCK_SUPPLIERS_STICKER_UPDATE,
  API_STOCK_SUPPLIERS_SUPPLY_ADD,
  API_STOCK_SUPPLIERS_SUPPLY_UPDATE,
  API_STOCK_SUPPLIERS_SYNC_SUPPLY,
  API_STOCK_SUPPLIERS_TRANSFER_TO_DELIVERY,
  API_STOCK_SUPPLIERS_UPDATE_ORDERS,
  API_STOCK_SUPPLIERS_UPDATE_STATUS,
  API_SUPPLIER_CLOSE
} from "../urls"

export const stockSuppliersApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_STOCK_SUPPLIERS_GET_TABLE_DATA, filters, axiosConfigAuth())
  },
  fetchImportResults: async () => {
    return axios.get(API_STOCK_SUPPLIERS_GET_IMPORT_RESULT, axiosConfigAuth())
  },
  fetchSupplierById: async (filters:any) => {
    return axios.post(API_STOCK_SUPPLIERS_GET_SUPPLIER, filters, axiosConfigAuth())
  },
  fetchCompanies: async () => {
    return axios.get(API_STOCK_SUPPLIERS_GET_PARTNERS, axiosConfigAuth())
  },
  removeSupplierById: async (supplier_id:number) => {
    return axios.get(`${API_STOCK_SUPPLIERS_DELETE_SUPPLIER}/${supplier_id}`, axiosConfigAuth())
  },
  removeSticker: async (id:number) => {
    return axios.get(`${API_STOCK_SUPPLIERS_STICKER_REMOVE}/${id}`, axiosConfigAuth())
  },
  downloadSticker: async (id:number) => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", `${API_STOCK_SUPPLIERS_STICKER_DOWNLOAD}/${id}/${getAccessToken()}`, true)
    xhr.responseType = "blob"

    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([ this.response ], { type: "application/pdf" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `sticker_${id}.pdf`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        return
      }
      if (this.status === 400) {
        const reader = new FileReader()
        reader.onload = function() {
          try {
            const response = JSON.parse(reader.result as string)
            showErrorNotification(response.message)
          } catch (e) {
            console.log("Error parsing JSON response: ", e)
          }
        }
        reader.readAsText(this.response)
      }
    }
    xhr.send()
  },
  downloadLabel: async (id:number) => {
    try{
      const xhr = new XMLHttpRequest()
      xhr.open("GET", `${API_STOCK_SUPPLIERS_LABEL_DOWNLOAD}/${id}/${getAccessToken()}`, true)
      xhr.responseType = "blob"

      xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([ this.response ], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = url
          a.download = `sticker_${id}.pdf`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
          return
        }

        if (this.status === 400) {
          const reader = new FileReader()
          reader.onload = function() {
            try {
              const response = JSON.parse(reader.result as string)
              showErrorNotification(response.message)
            } catch (e) {
              console.log("Error parsing JSON response: ", e)
            }
          }
          reader.readAsText(this.response)
        }

      }
      xhr.send()
    } catch (error:any) {
      console.log(error,"here")
      const dispatch = (() => {
      }) as TAppDispatch
      handleResponseError(dispatch, error)
    }
  },
  downloadExcel: async (filters:any) => {
    try {
      const response = await axios.post(
        API_STOCK_SUPPLIERS_DOWNLOAD_EXCEL,
        filters,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, "0")
      const day = String(now.getDate()).padStart(2, "0")

      let fileName = `export_${year}_${month}_${day}.csv`

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.error("Error downloading Excel file:", error)
    }
  },
  downloadSupplierExcel: async (filters:any) => {
    try {
      const response = await axios.post(
        API_STOCK_SUPPLIER_DOWNLOAD_EXCEL,
        filters,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, "0")
      const day = String(now.getDate()).padStart(2, "0")

      let fileName = `export_${year}_${month}_${day}.csv`

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.error("Error downloading Excel file:", error)
    }
  },
  updateStatus: async (data:UpdateOrderStatus) => {
    return axios.post(API_STOCK_SUPPLIERS_UPDATE_STATUS,data,axiosConfigAuth())
  },
  syncSupplier: async (data:SyncSupplier) => {
    return axios.post(API_STOCK_SUPPLIERS_SYNC_SUPPLY,data,axiosConfigAuth())
  },
  transferToDelivery: async (data:TransferToDelivery) => {
    return axios.post(API_STOCK_SUPPLIERS_TRANSFER_TO_DELIVERY,data,axiosConfigAuth())
  },
  printLabel: async (data:PrintLabel) => {
    try {
      const response = await axios.post(
        API_STOCK_SUPPLIERS_PRINT_LABEL,
        data,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]

      let fileName = "labels.pdf"

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.error("Error downloading file:", error)
    }
  },
  printSticker: async (data:PrintLabel) => {
    try {
      const response = await axios.post(
        API_STOCK_SUPPLIERS_PRINT_STICKER,
        data,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]

      let fileName = "stickers.pdf"

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.error("Error downloading file:", error)
    }
  },
  updateOrders: async (data:PrintLabel) => {
    return axios.post(API_STOCK_SUPPLIERS_UPDATE_ORDERS,data,axiosConfigAuth())
  },
  updateSticker: async (data: any) => {
    return axios.post(API_STOCK_SUPPLIERS_STICKER_UPDATE, data, axiosConfigAuth())
  },
  addCookies: async (data: AddCookies) => {
    return axios.post(API_SBERMARKET_COOKIES, data, axiosConfigAuth())
  },
  addSupply: async (data: any) => {
    return axios.post(API_STOCK_SUPPLIERS_SUPPLY_ADD, data, axiosConfigAuth())
  },
  updateSupply: async (data: any) => {
    return axios.post(API_STOCK_SUPPLIERS_SUPPLY_UPDATE, data, axiosConfigAuth())
  },

  addImportTask: async (data: {partner_id:number[]}) => {
    return axios.post(API_STOCK_SUPPLIERS_ADD_IMPORT_TASK, data, axiosConfigAuth())
  },
  checkCookies: async () => {
    return axios.get(`${API_SBERMARKET_COOKIES}`, axiosConfigAuth())
  },
  closeSupplier: async (supplier_id:number) => {
    return axios.get(`${API_SUPPLIER_CLOSE}/${supplier_id}`,axiosConfigAuth())
  },
}
