import React, { FC, useEffect, useRef, useState } from "react"

import { Input, Alert, Card, Image, Spin } from "antd"

import { stockFBSMarketApi } from "../../../common/api/endpoints/stockFBSMarketApi"
import MainLayout from "../../../common/components/layouts/MainLayout"
import PageHead from "../../../common/components/page-header/PageHeader"
import { fallbackImg } from "../../../utils/data"

type AlertState = {
  message: string;
  type: "info" | "error" | "success" | "warning" | undefined;
  product: null | {
    primary_photo: null | string;
    order_id: null | string;
    product_name: null | string;
  };
};

const CollectOrders: FC = () => {
  const [ alertState, setAlertState ] = useState<AlertState>({
    message: "Отсканируйте <strong>QR</strong> или ШК заказа",
    type: "info",
    product: null
  })
  const [ isLoading, setIsLoading ] = useState(false)
  const [ inputValue, setInputValue ] = useState("") // State for input value

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const inputRef = useRef<Input>(null)

  const handleBarcodeScan = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(event.target as HTMLInputElement).value.startsWith("*")) {
      setInputValue("")
      return
    }
    if (event.key === "Enter") {
      const scannedValue = (event.target as HTMLInputElement).value
      fetchInfoByBarcode(scannedValue)
      console.log("Баркод:", scannedValue)
      setInputValue("")
    }
  }

  const fetchInfoByBarcode = async (barcode: string) => {
    try {
      setIsLoading(true)
      const { data } = await stockFBSMarketApi.fetchInfoByBarcode(barcode)
      setAlertState({
        type: data?.code === 200 ? "success" : "error",
        message: data?.msg,
        product: {
          order_id: data?.order_id,
          primary_photo: data?.primary_photo,
          product_name: data?.product_name
        }
      })
    } catch (error: any) {
      if (error?.message === "Network Error") {
        setAlertState({
          message: "<strong>Проверьте интернет соеденение.</strong>",
          type: "error",
          product: null
        })
      }
      if (error.response?.status === 400 && error.response?.data?.message) {
        setAlertState({
          message: error.response?.data?.message,
          type: "error",
          product: null
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.input.contains(event.target as Node)) {
        setTimeout(() => {
          inputRef.current.focus()
        }, 500)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const alertMessage = (
    <span dangerouslySetInnerHTML={{ __html: alertState.message }}></span>
  )

  return (
    <MainLayout pageTitle="Сборка заказов">
      <div className="page-wrapper">
        <PageHead title="Сборка заказов" />
        <Spin spinning={isLoading}>
          <div className="page-content">
            <Input
              value={inputValue}
              ref={inputRef}
              onKeyPress={handleBarcodeScan}
              onChange={(e) => setInputValue(e.target.value.startsWith("*") ? e.target.value : "")}
              placeholder="Отсканируйте штрих код"
              style={{ width: 184 }}
            />
            {alertState.product !== null && (
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<Image alt="example" src={alertState.product.primary_photo as string} fallback={fallbackImg} />}
              >
                <Card.Meta description={alertState.product?.order_id}
                  title={<span style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}>{alertState.product?.product_name}</span>}
                />
              </Card>
            )}
            <Alert showIcon message={alertMessage} type={alertState.type} />
          </div>
        </Spin>
      </div>
    </MainLayout>
  )
}

export default CollectOrders
