import { useState, useEffect } from "react"


const useTableWidth = () => {
  const [ tableWidth, setTableWidth ] = useState(1000)

  const tableDiv = document.querySelector(".page-content")

  useEffect(() => {
    if (tableDiv) {
      const calcTableWidth = () => {
        const width = (tableDiv as HTMLDivElement)?.offsetWidth
        setTableWidth(width <= 1200 ? 1200 : width)
      }

      new ResizeObserver(calcTableWidth).observe(tableDiv)
      calcTableWidth()
    }
  }, [ tableDiv ])


  return tableWidth
}

export default useTableWidth
