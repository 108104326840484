import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropshippingState, initialState } from './types';

const dropshippingSlice = createSlice({
  name: 'dropshipping',
  initialState,
  reducers: {
    setItemLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setDropshippingData(state, action: PayloadAction<any[]>) {
      state.itemData = action.payload;
    },
    setItemPagesData(state, action: PayloadAction<DropshippingState['pagesData']>) {
      state.pagesData = action.payload;
    },
    setDropshippingUpdate(state, action: PayloadAction<boolean>) {
      state.isUpdate = action.payload;
    },
    addSupplierSuccess(state, action: PayloadAction<string>) {
    },
    exportExcelSuccess(state, action: PayloadAction<Blob>) {
    },
    exportExcelError(state, action: PayloadAction<string>) {
    },
  },
});

export const dropshippingSliceActions = dropshippingSlice.actions;
export const dropshippingSliceReducer = dropshippingSlice.reducer;
