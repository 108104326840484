import HeaderCellSorting from "../common/components/table/HeaderCellSorting"

export const columnsWithSortingHeader = (array: any[], sorting: any,sortedColumns:string[],handleSort:(columnKey: string)=>void) => {
  return array.map((col) => {
    return {
      ...col,
      sorting,
      ...(sortedColumns.includes(col.key) ? {
        headerRenderer: ({ column }: any) => {
          return (
            <div onClick={() => handleSort(column.key)} style={{ cursor: "pointer",display:"flex",columnGap:"2px",alignItems:"center",justifyContent:"space-between",width:"100%" }}>
              {column.title}
              <HeaderCellSorting sort={sorting[column.key] as string} />
            </div>
          )
        },
      } : {})
    }
  })
}
export const normalizeColumns = (columns:any[],columnsToRemove=[ "selection" ]) => {
  return columns.filter((item:any) =>!columnsToRemove.includes(item.key))
}
export const rowClassName = (record: any) => {
  return record?.rowData?.line_color ? `row-color-${record?.rowData?.line_color}` : ""
}
