import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IPartnerRow, IPartnersState } from "./types"

const initialState: IPartnersState = {
  data: [],
  isLoading: false,
  isUpdate: false
}

export const parntersSlice = createSlice({
  name: "partners",
  initialState: initialState,
  reducers: {
    setPartnersData: (state, action: PayloadAction<IPartnerRow[]>) => {
      state.data = action.payload
    },
    setPartnersLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPartnersUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const partnersSliceReducer = parntersSlice.reducer
export const partnersSliceActions = parntersSlice.actions
