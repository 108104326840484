import axios from "axios"

import { axiosConfigAuth } from "../middleware"
import {
  API_META_GET_PARTNERS,
  API_PARTNERS_ADD,
  API_PARTNERS_DELETE,
  API_PARTNERS_EDIT, API_PARTNERS_GET_BY_ID,
  API_PARTNERS_LIST,
  API_PARTNERS_VALID_API
} from "../urls"

export const partnersApi = {
  fetchList: async () => {
    return axios.get(API_PARTNERS_LIST, axiosConfigAuth())
  },

  create: async (data: any) => {
    return axios.post(API_PARTNERS_ADD, data, axiosConfigAuth())
  },

  edit: async (data: any) => {
    return axios.post(API_PARTNERS_EDIT, data, axiosConfigAuth())
  },
  fetchById:async (id:number) => {
    return axios.get(`${API_PARTNERS_GET_BY_ID}/${id}`,axiosConfigAuth())
  },

  delete: async (id: string | number) => {
    return axios.get(`${API_PARTNERS_DELETE}/${id}`, axiosConfigAuth())
  },

  validApiKey: async() =>{
    return axios.get(`${API_PARTNERS_VALID_API}`,axiosConfigAuth())
  }
}
