import { suppliersSliceActions } from "./suppliersSlice"
import { suppliersApi } from "../../../common/api/endpoints/suppliersApi"
import { handleResponseError } from "../../../common/api/middleware"
import { showErrorNotification } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const suppliersActions = {
  ...suppliersSliceActions,
  suppliersGetData: (filters: any) => (dispatch: TAppDispatch) => {
    dispatch(suppliersActions.setSuppliersLoading(true))
    suppliersApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(suppliersActions.setItemData(data.rows))
          dispatch(
            suppliersActions.setItemPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(suppliersActions.setSuppliersLoading(false))
      })
  },
  suppliersGetFilter: () => (dispatch: TAppDispatch) => {
    dispatch(suppliersActions.setSuppliersLoading(true))
    suppliersApi
      .getFilter()
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(suppliersActions.setItemLists(data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(suppliersActions.setSuppliersLoading(false))
      })
  },
  suppliersCreate: (
    data: any,
    onFinish: () => void,
    onFormError: (errors: {[key: string]: string}) => void
  ) =>
    (dispatch: TAppDispatch) => {
      dispatch(suppliersActions.setSuppliersLoading(true))

      suppliersApi
        .create(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.code === 253) {
              showErrorNotification("Такой поставщик уже существует, проверьте данные и повторите попытку.")
              return
            } else {
              onFormError(err.response.data)
            }
          }

          handleResponseError(dispatch, err)
        })
        .finally(() => {
          dispatch(suppliersActions.setSuppliersLoading(false))
        })
    },

  suppliersUpdate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(suppliersActions.setSuppliersLoading(true))

        suppliersApi
          .update(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }else {
              handleResponseError(dispatch, err)
            }
          })
          .finally(() => {
            dispatch(suppliersActions.setSuppliersLoading(false))
          })
      },

  suppliersDelete:
    (id: string | number, onFinish: () => void) => (dispatch: TAppDispatch) => {
      dispatch(suppliersActions.setSuppliersLoading(true))

      suppliersApi
        .delete(id)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err)
        })
        .finally(() => {
          dispatch(suppliersActions.setSuppliersLoading(false))
        })
    },
}
