import axios from "axios";
import { axiosConfigAuth } from "../middleware";
import {
    API_ANALYTICS_FILTER_LIST,
    API_ANALYTICS_ORDERS_BY_PERIOD,
    API_ANALYTICS_DOWNLOAD_EXCEL
} from "../urls";

export const analyticsApi = {
    fetchFilterList: async () => {
        return axios.get(API_ANALYTICS_FILTER_LIST, axiosConfigAuth());
    },
    fetchOrdersByPeriod: async (filters: any) => {
        return axios.post(API_ANALYTICS_ORDERS_BY_PERIOD, filters, axiosConfigAuth());
    },
    downloadOrdersReportExcel: async (filters: any) => {
        try {
            const response = await axios.post(
                API_ANALYTICS_DOWNLOAD_EXCEL,
                filters,
                {
                    ...axiosConfigAuth(),
                    responseType: "blob",
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            const contentDisposition = response.headers["content-disposition"];
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");

            let fileName = `export_${year}_${month}_${day}.csv`;

            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch?.[1]) {
                    fileName = fileNameMatch[1];
                }
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error("Error downloading Excel file:", error);
        }
    }
};
