import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ImportProductsState, PartnerItem, StatusItem, TableItemData } from "./types"

const initialState: ImportProductsState = {
  tableData:[],
  isLoading:false,
  partnersList:[],
  statusData:[]
}

export const importProductsSlice = createSlice({
  name: "importProducts",
  initialState: initialState,
  reducers: {
    setTableData: (state, action: PayloadAction<TableItemData[]>) => {
      state.tableData = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPartnersList: (state, action: PayloadAction<PartnerItem[]>) => {
      state.partnersList = action.payload
    },
    setStatusData: (state, action: PayloadAction<StatusItem[]>) => {
      state.statusData = action.payload
    },
  }
})

export const importSliceReducer = importProductsSlice.reducer
export const importSliceActions = importProductsSlice.actions
