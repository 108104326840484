import React, { FC } from "react"

import "antd/dist/reset.css"
import "./styles/root.scss"
import GlobalErrorWrapper from "./common/components/wrappers/global-error-wrapper/GlobalErrorWrapper"
import AppRouter from "./router"

const App: FC = () => {
  return (
    <div id="app" className="App">
      <GlobalErrorWrapper>
        <AppRouter />
      </GlobalErrorWrapper>
    </div>
  )
}

export default App
