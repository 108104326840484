import { telegramNotificationsSliceActions } from "./telegramNotificationsSlice"
import { UpdateStatus } from "./types"
import { telegramNotificationsApi } from "../../../common/api/endpoints/telegramNotificationsApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const telegramNotificationsActions = {
  ...telegramNotificationsSliceActions,

  telegramNotificationsGetData: () => (dispatch: TAppDispatch) => {
    dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(true))

    telegramNotificationsApi
      .fetchList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(telegramNotificationsActions.setTableData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(false))
      })
  },
  telegramNotificationsUpdateStatus:
        (
          data: UpdateStatus,
          onFinish?: () => void,
          onFormError?: (errors: {[key: string]: string}) => void
        ) =>
          (dispatch: TAppDispatch) => {
            dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(true))

            telegramNotificationsApi
              .updateStatus(data)
              .then((response) => {
                if (response.status === 200) {
                  onFinish && onFinish()
                }
              })
              .catch((err) => {
                if (err.response?.status === 400) {
                  onFormError && onFormError(err.response.data)
                }
                handleResponseError(dispatch, err)
              })
              .finally(() => {
                dispatch(telegramNotificationsActions.telegramNotificationsGetData())
                dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(false))
              })
          },
  telegramNotificationsRemoveUser:
    (id: string | number, onFinish?: () => void) =>
      (dispatch: TAppDispatch) => {
        dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(true))
        telegramNotificationsApi
          .delete(id)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(telegramNotificationsActions.telegramNotificationsGetData())
            dispatch(telegramNotificationsActions.setTelegramNotificationsLoading(false))
          })
      }
}
