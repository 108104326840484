import { stockSuppliersSliceActions } from "./stockSuppliers"
import { stockSuppliersApi } from "../../../common/api/endpoints/stockSuppliersApi"
import { handleResponseError } from "../../../common/api/middleware"
import { AddCookies } from "../../../types/api"
import { showSuccessMessage } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const stockSuppliersActions = {
  ...stockSuppliersSliceActions,
  stockSuppliersGetCompanies: () => (dispatch: TAppDispatch) => {
    dispatch(stockSuppliersActions.setItemListsLoading(true))

    stockSuppliersApi
      .fetchCompanies()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersCompanies(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockSuppliersActions.setItemListsLoading(false))
      })
  },
  stockSuppliersGetImportResults: () => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .fetchImportResults()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersImportResults(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSuppliersRemoveSticker: (orderId:number) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .removeSticker(orderId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
          showSuccessMessage("Стикер успешно удален.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSuppliersUpdateSticker: (data:any) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .updateSticker(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
          showSuccessMessage("Стикер успешно обновлен.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSupplierRemoveSupplier: (orderId:number) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .removeSupplierById(orderId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
          showSuccessMessage("Поставка успешно удалена.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSuppliersAddSupply: (data:any) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .addSupply(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
          showSuccessMessage("Поставка успешно добавлена.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSuppliersUpdateSupply: (data:any) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .updateSupply(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
          showSuccessMessage("Поставка успешно сменена.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
  stockSuppliersGetList: (filters: any,isSocket=false) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(stockSuppliersActions.setItemLoading(true))

    stockSuppliersApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(stockSuppliersActions.setStockSuppliersTableData(data.rows))
          dispatch(stockSuppliersActions.setStockSuppliersFilters({
            ...(data?.partners && { partners:data.partners }),
            ...(data?.supplies && { supplies:data.supplies }),
            ...(data?.supplies_for_transfer && { supplies_for_transfer:data.supplies_for_transfer }),
            ...(data?.statuses && { statuses:Object.values(data.statuses) }),
            ...(data?.delivery_date && { delivery_date:data.delivery_date }),
            ...(data?.marketplace_count && { marketplace_count:data.marketplace_count }),

          }))
          dispatch(
            stockSuppliersActions.setStockSuppliersPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(stockSuppliersActions.setItemLoading(false))
      })
  },
  stockSuppliersGetSupplier: (filters: any,isSocket=false) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(stockSuppliersActions.setItemLoading(true))

    stockSuppliersApi
      .fetchSupplierById(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(stockSuppliersActions.setSupplierItem(data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(stockSuppliersActions.setItemLoading(false))
      })
  },
  stockSuppliersAddCookies: (
    data: AddCookies,
    onFinish: () => void,
    onFormError: (errors: {[key: string]: string}) => void
  ) =>
    (dispatch: TAppDispatch) => {
      stockSuppliersApi
        .addCookies(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data)
          }
          handleResponseError(dispatch, err)
        })

    },
  stockSupplierClose: (supplier_id:number) => (dispatch: TAppDispatch) => {
    stockSuppliersApi
      .closeSupplier(supplier_id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockSuppliersActions.setStockSuppliersUpdate(true))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
}
