import { AxiosError } from "axios";
import { purchaseApi } from "../../../common/api/endpoints/purchaseApi";
import { handleResponseError } from "../../../common/api/middleware";
import { purchaseSliceActions } from "./purchase";
import { TAppDispatch } from "../../store";
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui";
import { PurchaseFilters } from "./types";

export const purchaseActions = {
  ...purchaseSliceActions,

  getPurchaseList: (filters: PurchaseFilters = {}) => async (dispatch: TAppDispatch) => {
    dispatch(purchaseActions.setItemLoading(true));

    try {
      const response = await purchaseApi.fetchList(filters);
      if (response.status === 200) {
        const data = response.data;
        dispatch(purchaseActions.setPurchaseData(
          data.rows.map((el: { id: number }) => ({ ...el, key: el.id }))
        ));
        dispatch(purchaseActions.setItemPagesData({
          total_purchase_amount: data?.total_purchase_amount,
          total_real_purchase_amount: data?.total_real_purchase_amount,
          total: data.total || 0,
          totalPages: data.totalPages || 0,
          start: data.start || 1,
          end: data.end || 0,
          currentPage: data.currentPage || 1,
          pageSize: data.pageSize || 10,
          header: data.header || { id: 0, name: '', phone: null },
          rows: data.rows || [],
        }));
      }
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError);
    } finally {
      dispatch(purchaseActions.setItemLoading(false));
    }
  },

  getPartnerFilterList: () => async (dispatch: TAppDispatch) => {
    dispatch(purchaseActions.setItemLoading(true));

    try {
      const response = await purchaseApi.getPartnerFilterList();
      if (response.status === 200) {
        const data = response.data;
        dispatch(purchaseActions.setPartnerFilters(data));
      }
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError);
    } finally {
      dispatch(purchaseActions.setItemLoading(false));
    }
  },

  deletePurchaseItem: (product_id: number, supplier_id: number, user_id: number, purchase_date: string) => async (dispatch: TAppDispatch) => {
    dispatch(purchaseActions.setItemLoading(true));

    try {
      const response = await purchaseApi.delete({
        product_id,
        supplier_id,
        user_id,
        purchase_date
      });
      if (response.status === 200) {
        showSuccessMessage("Товар успешно удален.");
        dispatch(purchaseActions.setPurchaseUpdate(true));
      }
    } catch (err) {
      handleResponseError(dispatch, err as AxiosError);
    } finally {
      dispatch(purchaseActions.setItemLoading(false));
    }
  },
};
