import React, { FC } from "react";
import { Button, Card, Col, Form, Input, Row, Select, Spin, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../../common/hooks/useTypedSelector";
import { removeObjectEmptyKeys } from "../../../utils/data";
import { useActions } from "../../../common/hooks/useActions"
import { defaultAnalyticsFilters } from "../../../common/constants/filters";

interface Props {
  isLoading: boolean;
  sorting: { [x: string]: string }
  setFilters: (filters: any) => void;
}

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [form] = Form.useForm();
  const { filterList, pagesData } = useTypedSelector((state) => state.analytics);
  const { fetchOrdersByPeriod } = useActions()

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values);
    const data = {
      ...cleanedValues,
      order_statuses: cleanedValues.order_statuses?.length ? cleanedValues.order_statuses : undefined,
      marketplace_id: cleanedValues.marketplace_id?.length ? cleanedValues.marketplace_id : undefined,
      partner_id: cleanedValues.partner_id?.length ? cleanedValues.partner_id : undefined,
      data: cleanedValues.data && {
        start: cleanedValues.data[0].format("YYYY.MM.DD"),
        end: cleanedValues.data[1].format("YYYY.MM.DD"),
      },
    };

    setFilters(data);
    fetchOrdersByPeriod({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: data
    })
  };

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <Card style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
        <Form form={form} onFinish={onSubmitFilter} name="analyticsFilter">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="data">
                <DatePicker.RangePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nm_id">
                <Input placeholder="Артикул цифровой ВБ" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="marketplace_id">
                <Select
                  placeholder="Маркетплейс"
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                >
                  {Object.entries(filterList?.marketplace_list || {}).map(([key, value]) => (
                    <Select.Option key={key} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nm_name">
                <Input placeholder="Артикул буквенный ВБ" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="barcode">
                <Input placeholder="Баркод" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="order_statuses">
                <Select mode="multiple" placeholder="Статус заказа"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {Object.entries(filterList?.order_status_list || {}).map(([key, value]) => (
                    <Select.Option key={key} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="partner_id">
                <Select
                  mode={"multiple"}
                  placeholder="Партнеры"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {filterList?.partner_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="purveyor_id">
                <Select
                  placeholder="Поставщик"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {Object.entries(filterList?.suppliers_list || {}).map(([key, value]) => (
                    <Select.Option key={key} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "left" }}>
              <Button type="primary" style={{ background: "#00a2e8" }} onClick={() => form.submit()}>
                Применить
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => {
                  form.resetFields();
                  fetchOrdersByPeriod({
                    page: 1,
                    pageSize: 10,
                    sorting: sorting,
                    filters: defaultAnalyticsFilters,
                  });
                }}
              >
                Сбросить
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default FilterTable;