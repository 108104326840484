import React, { FC, useEffect, useState } from "react"

import { CopyOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  Button, Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal, Row,
  Select,
  Spin
} from "antd"
import dayjs from "dayjs"
import { useDispatch } from "react-redux"

import { itemsApi } from "../../../common/api/endpoints/itemsApi"
import { handleResponseError } from "../../../common/api/middleware"
import NumberInput from "../../../common/components/inputs/NumberInput"
// import { PhoneRegex } from "../../../common/constants/regex"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { parseValuesBeforeInsertInForms } from "../../../utils/data"
import { showFormErrors } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  editId: string
  filters: any
}

const EditModal: FC<Props> = ({ isOpen, setIsOpen, editId }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage()

  const { isLoading, lists } = useTypedSelector((state) => state.item)
  const { itemUpdate, setItemUpdate, itemGetSuppliersList, setItemLoading } =
    useActions()
  const [priceWithoutDiscount, setPriceWithoutDiscount] = useState<number | null>(null)
  const [suppliersList, setSuppliersList] = useState<
    Array<{ id: string | number; name: string }>
  >([])

  const editItem = (values: any) => {
    const data = {
      ...values,
      date_card: values.date_card
        ? values.date_card.format("DD.MM.YYYY")
        : null,
      id: editId
    }
    const handleConfirm = () => {
      itemUpdate(
        data,
        () => {
          setIsOpen(false)
          setItemUpdate(true)
        },
        (errors) => {
          showFormErrors(form, errors)
        }
      )
    }
    if (Number(data.price_without_discount) <= 0.8 * Number(priceWithoutDiscount) && Number(data.price_without_discount) !== Number(priceWithoutDiscount)) {
      Modal.confirm({
        title: "Цена изменилась в меньшую сторону на 20% и более. Хотите продолжить?",
        onOk: handleConfirm,
        onCancel: () => {
        },
      })
    } else {
      handleConfirm()
    }
  }

  const getEditData = (id: string | number) => {
    setItemLoading(true)

    itemsApi
      .fetchItemData(id)
      .then((response) => {
        const data = {
          ...response.data,
          purveyor_id: [...response.data.purveyor_id] || [],
          date_card: response.data.date_card
            ? dayjs(response.data.date_card)
            : null
        }
        setPriceWithoutDiscount(Number(data.price_without_discount))
        const values = parseValuesBeforeInsertInForms(data)
        form.setFields(values)

        itemGetSuppliersList("", setSuppliersList, () => setItemLoading(false))
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
        setItemLoading(false)
        setIsOpen(false)
      })
  }

  useEffect(() => {
    if (isOpen && editId) {
      getEditData(editId)
    }
  }, [isOpen])

  return (
    <Modal
      title="Редактирование товара"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{
        loading: isLoading,
        className: "blueButton"
      }}
      destroyOnClose
      width={"50%"}
    >
      {contextHolder}
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={editItem}
        layout="vertical"
        requiredMark={true}
      >
        {isLoading ? (
          <div className="global-loading-container" style={{ height: 400 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        ) : (
          <div>
            <Row>
              <Form.Item
                label="Артикул цифровой"
                name="nm_id"
                style={{ marginRight: '16px', flex: '0.33' }}
                rules={[{ required: true, message: "Поле обязательное" }]}
              >
                <NumberInput />
              </Form.Item>
              <Form.Item
                label="Поставщик"
                name="purveyor_id"
                style={{ flex: '0.67' }}
              >
                <Select
                  showSearch
                  mode="multiple"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={(value) => itemGetSuppliersList(value, setSuppliersList)}
                >
                  {suppliersList.map(
                    (item: { id: string | number; name: string }, index: number) => (
                      <Select.Option key={index} value={`${item.id}`}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                          <span>{item.name}</span>
                          <Button
                            icon={<CopyOutlined />}
                            size="small"
                            style={{
                              marginLeft: 5
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()

                              navigator.clipboard.writeText(item.name).then(() => {
                                messageApi.success("Название скопировано!")
                              })
                            }}
                          />
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Артикул буквенный"
                  name="nm_name"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Общее название товара"
                  name="product_name"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Баркод"
                  name="barcode"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <NumberInput />
                </Form.Item>

                <Form.Item
                  label="Ссылка на карточку товара"
                  name="product_wb_url"
                  rules={[
                    { type: "url", message: "Требуется URL" },
                    { required: true, message: "Поле обязательное" }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Ссылка на VK"
                  name="vk_url"
                  rules={[{ type: "url", message: "Требуется URL" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={"Партнер"} name="partner_id" rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <Select
                    placeholder="Партнеры"
                    showSearch
                    filterOption={(input, option) =>
                      option && option.children
                        ? (option.children as unknown as string)
                          .toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    defaultActiveFirstOption={false}
                  >
                    {lists?.partner_list?.map((item: any, index: number) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Цена закупки на Садоводе"
                  name="purchase_price_gardener"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <NumberInput />
                </Form.Item>
                <Form.Item
                  label="Скидка на МП"
                  name="discount_percentage"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <NumberInput />
                </Form.Item>
                <Form.Item
                  label="Маркетплейс"
                  name="marketplace_id"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <Select>
                    <Select.Option value={1}>
                      WildBerries
                    </Select.Option>
                    <Select.Option value={2}>
                      Ozon
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Остаток на складе"
                  name="stock_quantity"
                >
                  <NumberInput />
                </Form.Item>
                <Form.Item
                  label="Логистика на ВБ"
                  name="logistics_wb"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <NumberInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Цена до скидки"
                  name="price_without_discount"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <NumberInput />
                </Form.Item>

                <Form.Item label="Цена после скидки" name="discount_price_spp">
                  <NumberInput />
                </Form.Item>

                <Form.Item label="Цена у топа" name="top_price_wb">
                  <NumberInput />
                </Form.Item>

                <Form.Item
                  label="Ссылка на ТОПа"
                  name="product_top_url"
                  rules={[{ type: "url", message: "Требуется ссылка" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Комментарий старшего менеджера"
                  name="manager_comment"
                >
                  <Input.TextArea />
                </Form.Item>

                <Form.Item
                  label="Статус карточки товара"
                  name="card_status_id"
                  rules={[{ required: true, message: "Поле обязательное" }]}
                >
                  <Select>
                    {lists?.cards_status_list?.map(
                      (item: { id: string | number; name: string }, index: number) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>

                <Form.Item label="Дата заведения карточки" name="date_card">
                  {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*/}
                  <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </Modal>
  )
}

export default EditModal
