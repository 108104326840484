import React, { ReactNode, useEffect, useState } from "react"

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined, ShoppingOutlined
} from "@ant-design/icons"
import { Button, Modal, Select, Space, Switch, Table, TableColumnsType, TableProps } from "antd"
import { ColumnsType } from "antd/es/table"

import ColorTag from "../../../common/components/color-tag/ColorTag"
import AdvancedSelect from "../../../common/components/selects/AdvancedSelect"
import { allTagColors, errorTagColor, successTagColor } from "../../../common/constants/colors"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"

type Props = {
  marketplace_id:number
}
type ImportRow = {
  id:number
  created_at:string
  status_id:number
  msg:string
}

const TableTab = ({ marketplace_id }:Props) => {
  const { importGetTableData,importGetPartnersList,importRemoveTask,importAddTask,importUpdateStatus,importGetStatuses } = useActions()
  const { tableData,isLoading,partnersList,statusData } = useTypedSelector((state)=>state.importProducts)
  const [ statusChecked,setStatusChecked ] = useState(false)
  const [ partner_ids ,setPartnerIds ] = useState<number[]>([])
  useEffect(() => {
    importGetTableData(marketplace_id)
    importGetPartnersList(marketplace_id)
    importGetStatuses()
  }, [])
  useEffect(()=>{
    partnersList.length > 0 && setPartnerIds([ partnersList[0].id ])
  },[ partnersList ])
  useEffect(()=>{
    if(statusData.length > 0) {
      setStatusChecked(statusData.filter((el)=>el.id === marketplace_id)[0]?.status === 1)
    }
  },[ statusData ])
  const importColumns: TableColumnsType<ImportRow>= [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Дата импорта",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Информация",
      dataIndex: "msg",
      key: "msg",
      align:"center",
      render:(val) => {
        return val === null ? "Нет" : val
      }
    },
    {
      title: "Статус импорта",
      dataIndex: "status_id",
      key: "status_id",
      sorter: true,
      width: 220,
      align:"center",
      render: (val) => {
        const values = {
          1:"В очереди",
          2:"Идет импорт",
          3:"Успешно",
          4:"Ошибка"
        }
        let color = errorTagColor
        switch (val) {
          case 3:
            color = successTagColor
            break
          case 4:
            color = errorTagColor
            break
          case 1:
            color = allTagColors[2]
            break
          case 2:
            color = allTagColors[3]
            break
          default:
            color = errorTagColor
        }

        return <ColorTag color={color}>{values[val as keyof typeof values]}</ColorTag>
      }
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить задачу?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  importRemoveTask(record.id,marketplace_id)
                }
              })
            }}
            danger
          />
        </Space>
      )
    },
  ]
  const handleCreateTask = () => {
    importAddTask({ partner_ids,marketplace_id })
  }

  return (
    <>
      <div style={{ display:"flex",alignItems:"center",columnGap:"8px",marginBottom:"10px" }}>
        <Switch checked={statusChecked} onChange={(checked)=>{setStatusChecked(checked),importUpdateStatus({ id:marketplace_id ,status:checked ? 1 : 0 })}} className={"orange-switcher"} />
        Автоматически импортировать товары ночью
      </div>
      <div style={{ display:"flex",columnGap:"20px" }}>
        <h2>Выберите партнёра:</h2>
        <AdvancedSelect
          items={partnersList}
          selectedIds={partner_ids}
          setSelectedIds={setPartnerIds}
          placeholder="Маркетплейс"
        />
        <Button
          type="primary"
          onClick={() => handleCreateTask()}
          loading={isLoading}
          className="blueButton"
        >
          <ShoppingOutlined style={{ marginLeft:"-5px" }} />
          Добавить задачу</Button>
      </div>
      <Table
        virtual={true}
        dataSource={tableData}
        columns={importColumns}
        scroll={{ x: 870 }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        pagination={false}
        bordered
      />
    </>
  )
}

export default TableTab
