import React from "react"

import { ConfigProvider } from "antd"
import ruRU from "antd/es/locale/ru_RU"
import dayjs from "dayjs"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import App from "./App"
import { initAuthInterceptor } from "./common/api/middleware"
import { store } from "./redux/store"
import reportWebVitals from "./reportWebVitals"
import "dayjs/locale/ru"

dayjs.locale("ru")

initAuthInterceptor().finally(() => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
