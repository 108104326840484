import React, {CSSProperties, FC} from "react"

import { QuestionCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"

interface IPageHeaderProps {
  title: string
  description?: string
  extra?: React.ReactElement
    titleStyles?:CSSProperties
}

const PageHead: FC<IPageHeaderProps> = ({ title,titleStyles={}, description, extra }) => {
  return (
    <div className="page-header">
      <div>
        <h3 style={titleStyles}>{title}</h3>
        {description ? (
          <Tooltip
            placement="bottom"
            title={
              <div
                className="page-title-tooltip-text"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            }
            color="#fff"
            zIndex={9999}
            trigger="click"
            overlayClassName="page-title-tooltip"
          >
            <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
          </Tooltip>
        ) : null}
      </div>
      <div>{extra || null}</div>
    </div>
  )
}

export default PageHead
