import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IItemState, IItemRow } from "./types"

const initialState: IItemState = {
  itemData: [],
  pagesData: {
    total: 2,
    totalPages: 1,
    start: 1,
    end: 20,
    currentPage: 1,
    pageSize: 20
  },
  lists: {},
  isLoading: false,
  isUpdate: false,
  isLoadingLists: false,
  bannerData: null
}

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState: initialState,
  reducers: {
    setItemData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload
    },
    setItemPagesData: (state, action: PayloadAction<any>) => {
      state.pagesData = action.payload
    },
    setItemLists: (state, action: PayloadAction<any>) => {
      state.lists = action.payload
    },
    setItemListsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLists = action.payload
    },
    setSuppliersLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSuppliersUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const suppliersSliceReducer = suppliersSlice.reducer
export const suppliersSliceActions = suppliersSlice.actions
