import React from "react"

import { Alert } from "antd"

import { marketPlacePictureMap } from "../../../utils/data"

type Props = {
    marketplace_id:number
    message:string
    color:string
}
const ImportResultAlert = ({ marketplace_id,message,color }:Props) => {
  const alertMessage = (
    <span dangerouslySetInnerHTML={{ __html: message }}></span>
  )
  return (
    <Alert message={alertMessage} style={{ backgroundColor:`#${color}` }} showIcon icon={<img alt={"Маркетплейс"}
      style={{ borderRadius: "50%", width: "25px" }}
      src={marketPlacePictureMap[marketplace_id]}/>}/>
  )
}
export default ImportResultAlert
