import axios from "axios"

import { axiosConfigAuth } from "../middleware"
import {
  API_ITEMS_CREATE,
  API_ITEMS_DELETE,
  API_ITEMS_GET_ITEM,
  API_ITEMS_GET_LIST,
  API_ITEMS_GET_LISTS,
  API_ITEMS_GET_SUPPLIERS_LIST,
  API_ITEMS_UPDATE,
  API_ITEMS_UPDATE_PRODUCTS
} from "../urls"

export const itemsApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_ITEMS_GET_LIST, filters, axiosConfigAuth())
  },

  fetchListsData: async () => {
    return axios.get(API_ITEMS_GET_LISTS, axiosConfigAuth())
  },

  fetchItemData: async (id: string | number) => {
    const data = {
      id: id
    }

    return axios.post(API_ITEMS_GET_ITEM, data, axiosConfigAuth())
  },

  create: async (data: any) => {
    return axios.post(API_ITEMS_CREATE, data, axiosConfigAuth())
  },

  update: async (data: any) => {
    return axios.post(API_ITEMS_UPDATE, data, axiosConfigAuth())
  },

  delete: async (id: string | number) => {
    return axios.get(`${API_ITEMS_DELETE}/${id}`, axiosConfigAuth())
  },

  fetchSuppliers: async (search: string) => {
    const data = {
      str: search
    }

    return axios.post(API_ITEMS_GET_SUPPLIERS_LIST, data, axiosConfigAuth())
  },
  
  fetchUpdateProducts: async (data: any) => {
    return axios.post(API_ITEMS_UPDATE_PRODUCTS, data, axiosConfigAuth())
  }
}
