import React, { FC, useEffect, useState } from "react"

import { CopyOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select } from "antd"

import NumberInput from "../../../common/components/inputs/NumberInput"
// import { PhoneRegex } from "../../../common/constants/regex"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  filters: any
}

const AddModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const { isLoading, lists } = useTypedSelector((state) => state.item)
  const { itemCreate, setItemUpdate, itemGetSuppliersList } = useActions()

  const [suppliersList, setSuppliersList] = useState<
    Array<{ id: string | number; name: string }>
  >([])

  const addItem = (values: any) => {
    const data = {
      ...values,
      date_card: values.date_card ? values.date_card.format("DD.MM.YYYY") : null
    }

    itemCreate(
      data,
      () => {
        setIsOpen(false)
        setItemUpdate(true)
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [isOpen])

  return (
    <Modal
      title="Добавление товара"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{
        loading: isLoading,
        className: "blueButton"
      }}
      destroyOnClose
      width={"50%"}
    >
      {contextHolder}
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addItem}
        layout="vertical"
        requiredMark={true}
      >
        <Row style={{display: "flex"}}>
          <Form.Item
            label="Артикул цифровой ВБ"
            name="nm_id"
            style={{marginRight: '16px', flex: '0.33'}}
            rules={[{ required: true, message: "Поле обязательное" }]}
          >
            <NumberInput />
          </Form.Item>

          <Form.Item
              label="Поставщик"
              name="purveyor_id"
              style={{flex: '0.67'}}
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Select
                showSearch
                mode="multiple"
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={(value) => itemGetSuppliersList(value, setSuppliersList)}
              >
                {suppliersList.map(
                  (item: { id: string | number; name: string }, index: number) => (
                    <Select.Option key={index} value={`${item.id}`}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                        <span>{item.name}</span>
                        <Button
                          icon={<CopyOutlined />}
                          size="small"
                          style={{
                            marginLeft: 5
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            navigator.clipboard.writeText(item.name).then(() => {
                              messageApi.success("Название скопировано!")
                            })
                          }}
                        />
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col span={8}>


            <Form.Item
              label="Артикул буквенный ВБ"
              name="nm_name"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Общее название товара"
              name="product_name"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Баркод"
              name="barcode"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <NumberInput />
            </Form.Item>

            <Form.Item
              label="Ссылка на ВБ на карточку товара"
              name="product_wb_url"
              rules={[
                { type: "url", message: "Требуется URL" },
                { required: true, message: "Поле обязательное" }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Ссылка на VK"
              name="vk_url"
              rules={[{ type: "url", message: "Требуется URL" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={"Партнер"} name="partner_id"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Select
                placeholder="Партнеры"
                showSearch
                filterOption={(input, option) =>
                  option && option.children
                    ? (option.children as unknown as string)
                      .toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : false
                }
                defaultActiveFirstOption={false}
              >
                {lists?.partner_list?.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>


            <Form.Item
              label="Цена закупки на Садоводе"
              name="purchase_price_gardener"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <NumberInput />
            </Form.Item>
            <Form.Item
              label="Скидка на МП"
              name="discount_percentage"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <NumberInput />
            </Form.Item>
            <Form.Item
              label="Маркетплейс"
              name="marketplace_id"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Select>
                <Select.Option value={1}>
                  WildBerries
                </Select.Option>
                <Select.Option value={2}>
                  Ozon
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Остаток на складе"
              name="stock_quantity"
            >
              <NumberInput />
            </Form.Item>
            <Form.Item
              label="Логистика на ВБ"
              name="logistics_wb"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <NumberInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Цена на ВБ до скидки"
              name="price_without_discount"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <NumberInput />
            </Form.Item>
            <Form.Item label="Цена у топа на ВБ" name="top_price_wb">
              <NumberInput />
            </Form.Item>

            <Form.Item
              label="Ссылка на ТОПа"
              name="product_top_url"
              rules={[{ type: "url", message: "Требуется ссылка" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Комментарий старшего менеджера"
              name="manager_comment"
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Статус карточки товара"
              name="card_status_id"
              rules={[{ required: true, message: "Поле обязательное" }]}
            >
              <Select>
                {lists?.cards_status_list?.map(
                  (item: { id: string | number; name: string }, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Дата заведения карточки" name="date_card">
              {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*/}
              <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddModal
