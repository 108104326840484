import axios from "axios"

import { AddCookies } from "../../../types/api"
import { axiosConfigAuth } from "../middleware"
import {
  API_SBERMARKET_ADD_IMPORT_TASK,
  API_SBERMARKET_COOKIES, API_SBERMARKET_GET_COMPANIES,
  API_SBERMARKET_GET_FILTERS,
  API_SBERMARKET_GET_IMPORT_RESULT,
  API_SBERMARKET_GET_LIST
} from "../urls"

export const sberMarketApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_SBERMARKET_GET_LIST, filters, axiosConfigAuth())
  },
  fetchImportResults: async () => {
    return axios.get(API_SBERMARKET_GET_IMPORT_RESULT, axiosConfigAuth())
  },
  fetchFilters: async () => {
    return axios.get(API_SBERMARKET_GET_FILTERS, axiosConfigAuth())
  },
  fetchCompanies: async () => {
    return axios.get(API_SBERMARKET_GET_COMPANIES, axiosConfigAuth())
  },
  addCookies: async (data: AddCookies) => {
    return axios.post(API_SBERMARKET_COOKIES, data, axiosConfigAuth())
  },

  addImportTask: async (data: number[]) => {
    return axios.post(API_SBERMARKET_ADD_IMPORT_TASK, { company_ids:data }, axiosConfigAuth())
  },

  checkCookies: async () => {
    return axios.get(`${API_SBERMARKET_COOKIES}`, axiosConfigAuth())
  },
}
