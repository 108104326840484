import React, { useState } from "react";
import { Modal, Form, Select } from "antd";
import { useParams } from "react-router-dom";
import { stockSuppliersApi } from "../../../common/api/endpoints/stockSuppliersApi";
import { TAppDispatch } from "../../../redux/store";
import { handleResponseError } from "../../../common/api/middleware";
import { AxiosError } from "axios";
import { showSuccessMessage } from "../../../utils/ui";

type Props = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const SyncSupplier = ({ visible, setVisible }: Props) => {
    const { supplierId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const handleModalOk = () => {
        form.validateFields().then(async (values) => {
            try {
                setIsLoading(true);
                await stockSuppliersApi.syncSupplier({
                    supply_id: supplierId!,
                    day: values?.day,
                });
                showSuccessMessage("Поставки успешно синхронизированы.");
            } catch (err) {
                const dispatch = (() => {}) as TAppDispatch;
                handleResponseError(dispatch, err as AxiosError);
            } finally {
                setIsLoading(false);
                setVisible(false);
            }
            form.resetFields();
        });
    };

    const handleModalCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const daysOptions = Array.from({ length: 30 }, (_, index) => ({
        value: index + 1,
        label: `${index + 1}`,
    }));

    return (
        <>
            <Modal
                loading={isLoading}
                title="Синхронизация поставок"
                open={visible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okButtonProps={{
                    className: "blueButton",
                }}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="day"
                        label="День"
                        rules={[
                            { required: true, message: "Выберите день" },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Выберите день"
                            optionFilterProp="children"
                            options={daysOptions}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default SyncSupplier;
