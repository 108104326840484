import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {IItemState, IItemRow, ImportResults} from "./types"

const initialState: IItemState = {
  itemData: [],
  pagesData: {
    total: 2,
    totalPages: 1,
    start: 1,
    end: 20,
    currentPage: 1,
    pageSize: 20
  },
  filters: {},
  isLoading: false,
  isUpdate: false,
  isLoadingLists: false,
  companies:[],
  importResults:{} as ImportResults
}

export const sberSlice = createSlice({
  name: "sber",
  initialState: initialState,
  reducers: {
    setSberImportResults: (state, action: PayloadAction<ImportResults>) => {
      state.importResults = action.payload
    },
    setSberTableData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload.map((el)=>({...el,key:el.id}))
    },
    setSberPagesData: (state, action: PayloadAction<any>) => {
      state.pagesData = action.payload
    },
    setSberFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    setSberCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload
    },
    setItemListsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLists = action.payload
    },
    setItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSberUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    }
  }
})

export const sberSliceReducer = sberSlice.reducer
export const sberSliceActions = sberSlice.actions
