import { sberSliceActions } from "./sberSlice"
import { sberMarketApi } from "../../../common/api/endpoints/sberMarketApi"
import { handleResponseError } from "../../../common/api/middleware"
import { AddCookies } from "../../../types/api"
import { showSuccessMessage } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const sberActions = {
  ...sberSliceActions,
  sberGetFilters: () => (dispatch: TAppDispatch) => {
    dispatch(sberActions.setItemListsLoading(true))

    sberMarketApi
      .fetchFilters()
      .then((response) => {
        if (response.status === 200) {
          dispatch(sberActions.setSberFilters({ ...response.data }))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(sberActions.setItemListsLoading(false))
      })
  },
  sberGetCompanies: () => (dispatch: TAppDispatch) => {
    dispatch(sberActions.setItemListsLoading(true))

    sberMarketApi
      .fetchCompanies()
      .then((response) => {
        if (response.status === 200) {
          dispatch(sberActions.setSberCompanies(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(sberActions.setItemListsLoading(false))
      })
  },
  sberGetImportResults: () => (dispatch: TAppDispatch) => {
    sberMarketApi
      .fetchImportResults()
      .then((response) => {
        if (response.status === 200) {
          dispatch(sberActions.setSberImportResults(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },

  sberGetList: (filters: any) => (dispatch: TAppDispatch) => {
    dispatch(sberActions.setItemLoading(true))

    sberMarketApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(sberActions.setSberTableData(data.rows))
          dispatch(
            sberActions.setSberPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(sberActions.setItemLoading(false))
      })
  },
  sberAddCookies: (
    data: AddCookies,
    onFinish: () => void,
    onFormError: (errors: {[key: string]: string}) => void
  ) =>
    (dispatch: TAppDispatch) => {
      sberMarketApi
        .addCookies(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data)
          }
          handleResponseError(dispatch, err)
        })

    },
  sberCheckCookies: () => (dispatch: TAppDispatch) => {
    sberMarketApi
      .checkCookies()
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage("Куки рабочие")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
  },
}
