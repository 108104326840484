import { monitoringSettingsSliceActions } from "./monitoringSlice"
import { monitoringApi } from "../../../../common/api/endpoints/monitoringApi"
import { handleResponseError } from "../../../../common/api/middleware"
import { TAppDispatch } from "../../../store"

export const monitoringSettingsActions = {
  ...monitoringSettingsSliceActions,

  monitoringSettingsFetchData: () => async (dispatch: TAppDispatch) => {
    dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(true))

    monitoringApi.fetchData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(monitoringSettingsActions.setMonitoringSettingsData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(false))
      })
  },

  monitoringSettingsUpdateStatus: (
    id: string,
    value: boolean,
    onSuccess?: any
  ) => async (dispatch: TAppDispatch) => {
    dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(true))

    monitoringApi.updateStatus(id, value)
      .then((response) => {
        if (response.status === 200) {
          onSuccess && onSuccess()
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(false))
      })
  },

  monitoringSettingsUpdate: (
    data: { id: number, minutes: string },
    onFinish: () => void,
    onFormError: (errors: {[key: string]: string}) => void
  ) => async (dispatch: TAppDispatch) => {
    dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(true))

    monitoringApi.updateData(data)
      .then((response) => {
        if (response.status === 200) {
          onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }

        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(monitoringSettingsActions.setMonitoringSettingsLoading(false))
      })
  },
}
