import axios from "axios"

import {AddTask, UpdateStatus} from "../../../redux/slices/importProducts/types"
import { axiosConfigAuth } from "../middleware"
import {
  API_IMPORT_ADD_TASK, API_IMPORT_GET_STATUSES,
  API_IMPORT_LIST_OF_PARTNERS,
  API_IMPORT_PRODUCTS, API_IMPORT_REMOVE_TASK, API_IMPORT_UPDATE_STATUS
} from "../urls"

export const importApi = {
  fetchProducts: async (marketplace_id:number) => {
    return axios.get(`${API_IMPORT_PRODUCTS}${marketplace_id}`, axiosConfigAuth())
  },
  fetchPartnersList: async (marketplace_id:number) => {
    return axios.get(`${API_IMPORT_LIST_OF_PARTNERS}${marketplace_id}`, axiosConfigAuth())
  },
  removeTask: async (taskId:number) => {
    return axios.get(`${API_IMPORT_REMOVE_TASK}${taskId}`, axiosConfigAuth())
  },
  addTask: async (data:AddTask) => {
    return axios.post(API_IMPORT_ADD_TASK,data,axiosConfigAuth())
  },
  updStatus: async (data:UpdateStatus) => {
    return axios.post(API_IMPORT_UPDATE_STATUS,data,axiosConfigAuth())
  },
  getStatuses:async () => {
    return axios.get(API_IMPORT_GET_STATUSES,axiosConfigAuth())
  }
}
