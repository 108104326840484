import axios from "axios";
import { axiosConfigAuth, handleResponseError } from "../middleware";
import { API_DROPSHIPPING_ADD_PRODUCT_PUCHASED, API_DROPSHIPPING_SUPPLIERS_DELETE, API_DROPSHIPPING_SUPPLIERS_POST_TABLE, API_STOCK_SUPPLIERS_GET_PARTNERS } from "../urls";


export const dropshippingSuppliersApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_DROPSHIPPING_SUPPLIERS_POST_TABLE, filters, axiosConfigAuth());
  },
  fetchCompanies: async () => {
    return axios.get(API_STOCK_SUPPLIERS_GET_PARTNERS, axiosConfigAuth())
  },
  
  delete: async(productId: any, supplierId: any) => {
    return axios.get(`${API_DROPSHIPPING_SUPPLIERS_DELETE}${productId}/${supplierId}`, axiosConfigAuth())
  },

  addProductPurchased: async(data:any) => {
    return axios.post(`${API_DROPSHIPPING_ADD_PRODUCT_PUCHASED}`,data, axiosConfigAuth())
  }
}
