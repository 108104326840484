import React, { FC, useEffect, useState } from "react"
import axios from "axios";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
  LoadingOutlined, UsergroupAddOutlined
} from "@ant-design/icons"
import { Button, Input, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { showErrorNotification, showSuccessMessage } from "../../utils/ui"
import {marketPlacePictureMap} from "../../utils/data";
import { API_PARTNERS_VALID_API } from "../../common/api/urls";

const Partners: FC = () => {
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ editData, setEditData ] = useState({
    id: "",
    name: "",
    api_key_standard: "",
    api_key_stats: ""
  })

  const { data, isLoading } = useTypedSelector((state) => state.partners)
  const { parentsGetList, parentsDelete, parentsValidApiKey } = useActions()

  const [isChecking, setIsChecking] = useState(false);
  const [ tempData, setTempData ] = useState(data)

  useEffect(() => {
    setTempData(data)
  }, [ data ])

  useEffect(() => {
    parentsGetList()
  }, [])



  const partnersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 20,
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Название партнера",
      dataIndex: "name",
      key: "name",
      width: 300,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      width: 130,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Клиент ID",
      dataIndex: "client_id",
      key: "client_id",
      width: 130,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 110,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <img alt={"Маркетплейс"} style={{ borderRadius:"50%",width:"36px" }} src={marketPlacePictureMap[val as number]} />
        </div>
      )
    },
    {
      title: "Ключ API",
      dataIndex: "api_key_standard",
      key: "api_key_standard",
      width: 110,
      render: () => <div>**********************</div>
    },
    {
      title: "Доступность ключа API",
      dataIndex: "status_key",
      key: "status_key",
      width: 110,
      render: (value) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {value == 1 ? (
            <CheckCircleOutlined style={{ fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ fontSize: "24px" }} />
          )}
        </div>
      )
    },
    {
      title: "Дата добавления",
      dataIndex: "created_at",
      key: "created_at",
      width:110,
      render: (value) => <div style={{ width: 150 }}>{value}</div>
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 50,
      render: (value, record) => (
        <Space style={{ display:"flex",justifyContent:"center" }} direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditData({
                id: record.id,
                name: record.name,
                api_key_standard: record.api_key_standard,
                api_key_stats: record.api_key_stats
              })
              setIsEditOpen(true)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить партнера?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  parentsDelete(record.id, () => {
                    showSuccessMessage("Партнер успешно удален.")
                    parentsGetList()
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]



  const handleApiKeyCheck = async () => {
    setIsChecking(true);
    try {
      await parentsValidApiKey();
    } catch (error) {
      console.error('Error checking API keys:', error);
    } finally {
      setIsChecking(false);
    }
  };


  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    if (value) {
      const _data = tempData.filter((val: any) => {
        return (
          `${val.api_key_standard}`.toLowerCase().includes(searchString) ||
          `${val.name}`.toLowerCase().includes(searchString) ||
          `${val.api_key_stats}`.toLowerCase().includes(searchString) ||
          `${val.phone_binding_wb}`.toLowerCase().includes(searchString) ||
          `${val.created_at}`.toLowerCase().includes(searchString) ||
          `${val.updated_at}`.toLowerCase().includes(searchString)
        )
      })

      setTempData(_data)
    } else {
      setTempData(data)
    }
  }

  return (
    <MainLayout pageTitle="Партнеры">
      <div className="page-wrapper">
        <PageHead
          title="Партнеры"
          extra={
            <div>
            </div>
          }
        />

        <div className="page-content">
          <Space>
            <Input
              placeholder="Поиск по таблице..."
              onChange={(e) => searchTable(e.target.value)}
              style={{ width: 300, marginBottom: 20 }}
              allowClear
            />
          </Space>
          <Space style={{ marginTop:"-20px" }}>
            <Button type="primary" onClick={() => setIsAddOpen(true)} style={{background:"#00a2e8"}}>
              <UsergroupAddOutlined />
              Добавить партнера
            </Button>
            <Button
              style={{ marginLeft: "6px", display: "flex", columnGap: "6px", alignItems: "center", background:"#00a2e8" }}
              type="primary"
              onClick={handleApiKeyCheck}
              disabled={isChecking}
            >
              {isChecking ? <LoadingOutlined /> : <KeyOutlined />}
              {isChecking ? "Проверяем API ключи" : "Проверить API ключи"}
            </Button>
          </Space>
          <Table
              virtual={true}
            columns={partnersColumns}
            dataSource={tempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            size="small"
            bordered
          />

          <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
          {
            isEditOpen && <EditModal
              isOpen={isEditOpen}
              setIsOpen={setIsEditOpen}
              editData={editData}
            />
          }

        </div>
      </div>
    </MainLayout>
  )
}

export default Partners
