import axios from "axios"

import { UpdateStatus } from "../../../redux/slices/telegramNotifications/types"
import { axiosConfigAuth } from "../middleware"
import {
  API_TELEGRAM_GET_ITEMS, API_TELEGRAM_REMOVE_USER, API_TELEGRAM_UPDATE_STATUS
} from "../urls"

export const telegramNotificationsApi = {
  fetchList: async () => {
    return axios.get(API_TELEGRAM_GET_ITEMS, axiosConfigAuth())
  },
  updateStatus: async (data: UpdateStatus) => {
    return axios.post(API_TELEGRAM_UPDATE_STATUS, data, axiosConfigAuth())
  },
  delete: async (id: string | number) => {
    return axios.get(`${API_TELEGRAM_REMOVE_USER}${id}`, axiosConfigAuth())
  }
}
