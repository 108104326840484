import React from "react";

type Props = {
    count:number
    additionalStyles?:React.CSSProperties
}
const Counter = ({ count,additionalStyles }:Props) => {
  return (
    <span className={"counter"} style={additionalStyles}>{count}</span>
  )
}
export default Counter
