import { useState, useCallback } from "react"
import {TAppDispatch} from "../../redux/store";

type UseLoadingButtonReturn = [boolean, (fn: () => (dispatch: TAppDispatch) => void) => Promise<void>];

const useLoadingButton = ():UseLoadingButtonReturn => {
  const [ isLoading,setIsLoading ] = useState(false)

  const handleLoading = useCallback(async(fn:any) => {
    setIsLoading(true)
    await fn()
    setIsLoading(false)
  },[])

  return [ isLoading,handleLoading ]
}

export default useLoadingButton
