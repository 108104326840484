import { partnersSliceActions } from "./parntersSlice"
import { partnersApi } from "../../../common/api/endpoints/partnersApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"
import { showSuccessMessage } from "../../../utils/ui"


export const partnersActions = {
  ...partnersSliceActions,

  parentsGetList: () => (dispatch: TAppDispatch) => {
    dispatch(partnersActions.setPartnersLoading(true))

    partnersApi
      .fetchList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(partnersActions.setPartnersData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(partnersActions.setPartnersLoading(false))
      })
  },

  parentsCreate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(partnersActions.setPartnersLoading(true))

        partnersApi
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(partnersActions.setPartnersLoading(false))
          })
      },

  parentsEdit:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(partnersActions.setPartnersLoading(true))

        partnersApi
          .edit(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(partnersActions.setPartnersLoading(false))
          })
      },

  parentsDelete:
    (id: string | number, onFinish?: () => void) =>
      (dispatch: TAppDispatch) => {
        dispatch(partnersActions.setPartnersLoading(true))

        partnersApi
          .delete(id)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(partnersActions.setPartnersLoading(false))
          })
      },


       parentsValidApiKey: () => async (dispatch: TAppDispatch) => {
        dispatch(partnersActions.setPartnersLoading(true));
    
        await partnersApi
          .validApiKey()
          .then((response) => {
            if (response.status === 200) {
              showSuccessMessage("API ключи успешно проверены.")
              dispatch(partnersActions.parentsGetList());
          }}).catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(partnersActions.setPartnersLoading(false));
          });
      },
    
}
