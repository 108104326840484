import React, { FC, useState } from "react"

import { CopyOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  DatePicker
} from "antd"

import FilterPopover from "../../../common/components/FilterPopover/FilterPopover"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { removeObjectEmptyKeys } from "../../../utils/data"

interface Props {
  isLoading: boolean
  sorting: {[x: string]: string}
  setFilters: (v: any) => any
}

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [ form ] = Form.useForm()

  const { pagesData, lists } = useTypedSelector((state) => state.item)
  const { itemsGetList, itemGetSuppliersList } = useActions()

  const [ suppliersList, setSuppliersList ] = useState<
    Array<{id: string | number; name: string}>
  >([])

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values)
    const data = {
      ...cleanedValues,
      data: cleanedValues.data && {
        start: cleanedValues.data[0]?.format("DD.MM.YYYY"),
        end: cleanedValues.data[1]?.format("DD.MM.YYYY")
      }
      
    }
    
    setFilters(data)
    itemsGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: data
    })
  }

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Card style={{ width: "100%", backgroundColor:"rgba(0, 0, 0, 0.02)"  }}>
        <Form form={form} onFinish={onSubmitFilter} name="basic">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="data">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <DatePicker.RangePicker
                  format="DD.MM.YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="product_name">
                <Input placeholder="Общее название товара/Бренд/Актикул ЦВБ" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="nm_id">
                <Input placeholder="Артикул цифровой ВБ" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="nm_name">
                <Input placeholder="Артикул буквенный ВБ" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="barcode">
                <Input placeholder="Баркод" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="user_id">
                <Select
                  mode="multiple"
                  placeholder="Сотрудник"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {lists?.user_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="card_status_id">
                <Select mode="multiple" placeholder="Статус карточки"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {lists?.cards_status_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="purveyor_id">
                <Select
                  placeholder="Поставщик"
                  showSearch
                  mode="multiple"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={(value) =>
                    itemGetSuppliersList(value, setSuppliersList)
                  }
                >
                  {suppliersList.map(
                    (
                      item: {id: string | number; name: string},
                      index: number
                    ) => (
                      <Select.Option key={index} value={item.id}>
                        <span>{item.name}</span>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="partner_id">
                <Select
                  mode={"multiple"}
                  placeholder="Партнеры"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {lists?.partner_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="marketplace_id">
                <Select
                  mode={"multiple"}
                  placeholder="Маркетплейс"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {Object.entries(lists?.marketplace_list || {}).map(([ key, value ], index) => (
                    <Select.Option key={index} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="empty_id">
                <Select
                  mode={"multiple"}
                  placeholder="Не заполнены данные"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {Object.entries(lists?.empty_data_list || {}).map(([ key, value ], index) => (
                    <Select.Option key={index} value={Number(key)}>
                      {value as string}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Space style={{ marginLeft:"10px" }}>
              <FilterPopover
                title="Кол-во отзывов"
                paramNameFrom="review_count_from"
                paramNameTo="review_count_to"
                min={0}
              />
              <FilterPopover
                title="Рейтинг"
                paramNameFrom="rating_from"
                paramNameTo="rating_to"
                min={0}
              />
              <FilterPopover
                title="Заказали товаров"
                paramNameFrom="orders_count_from"
                paramNameTo="orders_count_to"
                min={0}
              />
              <FilterPopover
                title="Выкупили товаров"
                paramNameFrom="buyouts_count_from"
                paramNameTo="buyouts_count_to"
                min={0}
              />
            </Space>
          </Row>
        </Form>
        <Space style={{ marginTop:"16px" }}>
          <Button type="primary" style={{background:"#00a2e8"}} onClick={() => form.submit()}>
            Применить
          </Button>
          <Button
            type="default"
            onClick={() => {
              form.resetFields()
              itemsGetList({
                page: pagesData.currentPage || 1,
                pageSize: pagesData.pageSize || 10,
                sorting: sorting,
                filters: {}
              })
              setFilters({})
            }}
          >
            Сбросить
          </Button>
        </Space>
      </Card>
    </Spin>
  )
}

export default FilterTable
