import React from "react"

import { Modal, Form, Input } from "antd"

import { useActions } from "../../../common/hooks/useActions"

type Props = {
  addState: {
      visible: boolean,
      id: null | number | undefined ,
      partner_id: null | number | undefined,
      name: string | undefined,
      marketplace_id:number | null | undefined
  };
  setAddState: React.Dispatch<
      React.SetStateAction<{
          visible: boolean,
          id: null | number | undefined ,
          partner_id: null | number | undefined,
          name: string | undefined,
          marketplace_id:number | null | undefined
      }>
  >;
};

const AddSupplier = ({ setAddState, addState }: Props) => {
  const { stockFBSAddSupply } = useActions()
  const [ form ] = Form.useForm()

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      const data = {
        id: addState.id,
        marketplace_id: addState.marketplace_id,
        partner_id: addState.partner_id,
        name: values.partnerName,
      }
      stockFBSAddSupply(data)
      setAddState({
        visible: false,
        id: null,
        partner_id: null,
        name: "",
        marketplace_id: null,
      })
      form.resetFields()
    })
  }

  const handleModalCancel = () => {
    setAddState({
      visible: false,
      id: null,
      partner_id: null,
      name: "",
      marketplace_id: null,
    })
    form.resetFields()
  }

  return (
    <>
      <Modal
        title="Добавление новой поставки"
        open={addState.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okButtonProps={{
          className:"blueButton"
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="partnerName"
            label="Название партнера"
            rules={[
              { max: 128, message: "Название партнера не может быть длиннее 128 символов" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddSupplier
