import axios, { AxiosResponse } from "axios"

import { TAppDispatch } from "../../../redux/store"
import { getAccessToken } from "../../../utils/auth"
import { printWithQZTray } from "../../../utils/print"
import { showErrorMessage, showErrorNotification } from "../../../utils/ui"
import { axiosConfigAuth, handleResponseError } from "../middleware"
import {
  API_EXPORT_DOWNLOAD_FILE,
  API_INVENTORIZATION_DOWNLOAD,
  API_INVENTORIZATION_LIST, API_INVENTORIZATION_UPLOAD,
  API_META_GET_PARTNERS, API_MONITORING_IMPORT,
  API_PARTNERS_ADD,
  API_PARTNERS_DELETE,
  API_PARTNERS_EDIT, API_PARTNERS_GET_BY_ID,
  API_PARTNERS_LIST, API_STOCK_FBS_DOWNLOAD_EXCEL, API_STOCK_FBS_STICKER_DOWNLOAD, API_STOCK_LIST
} from "../urls"

export const stockApi = {
  create: async (data: any) => {
    return axios.post(API_PARTNERS_ADD, data, axiosConfigAuth())
  },
  delete: async (id: string | number) => {
    return axios.get(`${API_PARTNERS_DELETE}/${id}`, axiosConfigAuth())
  },
  downloadLastProducts: async () => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open("GET", `${API_INVENTORIZATION_DOWNLOAD}/${getAccessToken()}`, true)
      xhr.responseType = "blob"

      xhr.onload = async function () {
        if (this.status === 200) {
          const blob = new Blob([ this.response ], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = url
          a.download = `${"Текущие-остатки-товаров"}.xlsx`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
          resolve(null)
        }
        if (this.status === 400) {
          const reader = new FileReader()
          reader.onload = function() {
            try {
              const response = JSON.parse(reader.result as string)
              showErrorNotification(response.message)
              reject(null)
            } catch (e) {
              console.log("Error parsing JSON response: ", e)
            }
          }
          reader.readAsText(this.response)
        }
        reject(null)
      }
      xhr.send()
    })
  },
  edit: async (data: any) => {
    return axios.post(API_PARTNERS_EDIT, data, axiosConfigAuth())
  },
  fetchById: async (id: number) => {
    return axios.get(`${API_PARTNERS_GET_BY_ID}/${id}`, axiosConfigAuth())
  },

  fetchInventarization: async () => {
    return axios.get(API_INVENTORIZATION_LIST, axiosConfigAuth())
  },
  fetchList: async () => {
    return axios.get(API_STOCK_LIST, axiosConfigAuth())
  },
  importFile: async (file: File | Blob | string): Promise<AxiosResponse> => {
    const data = new FormData()
    data.append("file_v", file)

    return axios.post(API_INVENTORIZATION_UPLOAD, data, axiosConfigAuth())
  },
}
