import React, { FC, useEffect } from "react"

import {Form, Input, InputNumber, Modal, Select} from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors, showSuccessMessage } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
}

const AddModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.partners)
  const { parentsCreate, parentsGetList } = useActions()

  const addPartner = (values: any) => {
    if(values.client_id === undefined){
      values.client_id = null
    }
    parentsCreate(
      values,
      () => {
        showSuccessMessage("Партнер успешно создан")
        setIsOpen(false)
        parentsGetList()
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [ isOpen ])

  return (
    <Modal
      title="Добавление партнера"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addPartner}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="Название партнера"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Организация"
          name="org"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Маркетплейс"
          name="marketplace_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select>
            <Select.Option value={1}>
              WildBerries
            </Select.Option>
            <Select.Option value={2}>
              Ozon
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Клиент ID"
          name="client_id"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="API KEY"
          name="api_key"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddModal
