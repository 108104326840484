import React from "react"

import { Modal } from "antd"
import { useNavigate } from "react-router-dom"

import { routeNames } from "../../../common/constants/routes"

type Props = {
    state: {
        visible: boolean,
        id: null | number | undefined ,
        msg: string,
    };
    setState: React.Dispatch<
        React.SetStateAction<{
            visible: boolean,
            id: null | number | undefined ,
            msg: string,
        }>
    >;
};

const SuppliersModal = ({ setState, state }: Props) => {
  const handleModalOk = () => {
    window.open(`${routeNames.stockSuppliers}/${state.id}`,"_blank")
  }

  const handleModalCancel = () => {
    setState({
      visible: false,
      id: null,
      msg: "",
    })
  }

  return (
    <>
      <Modal
        title="Подтвердите действие на sellercrm.pro."
        open={state.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {state.msg}
      </Modal>
    </>
  )
}

export default SuppliersModal
