
import { useState } from "react"

import { Button, Input, Modal } from "antd"

import { stockFBSMarketApi } from "../../../common/api/endpoints/stockFBSMarketApi"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showErrorMessage } from "../../../utils/ui"

export const DownloadQuantityModal = () => {
    type Status = ""|"error"
    const { downloadQuantityModal } = useTypedSelector((state) => state.stockFBS)
    const { setVisibleDownloadQuantityModal } = useActions()
    const [ isCode, setIsCode ] = useState("")
    const [ isStatusInput, setIsStatusInput ]= useState<Status>("")
    const handleCancel = ()=>setVisibleDownloadQuantityModal({ ...downloadQuantityModal,isVisible:false, service:"" })

    return(
      <Modal
        style={{ padding:80 }}
        centered={true}
        onCancel={handleCancel}
        title={downloadQuantityModal.text}
        open={downloadQuantityModal.isVisible}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
                Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (!isCode) {setIsStatusInput("error"); return}
              setVisibleDownloadQuantityModal({ ...downloadQuantityModal,isVisible:false, service: "" })
              if(!downloadQuantityModal.service) {showErrorMessage("Внутренняя ошибка"); return}
              stockFBSMarketApi[downloadQuantityModal.service](downloadQuantityModal.data,false,setVisibleDownloadQuantityModal,isCode)
            }}
          >
                Отправить
          </Button>,
        ]}
      >
        <Input
          placeholder="Код менеджера"
          type="text"
          status={isStatusInput}
          onChange={(e)=>{setIsCode(e.currentTarget.value.replace(/ {2,}/g, " "));setIsStatusInput("")}}
        />
      </Modal>)
}
