import { staffSliceActions } from "./staffSlice"
import { staffApi } from "../../../common/api/endpoints/staffApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const staffActions = {
  ...staffSliceActions,

  staffGetList: () => (dispatch: TAppDispatch) => {
    dispatch(staffActions.setStaffLoading(true))

    staffApi
      .fetchList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(staffActions.setStaffData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(staffActions.setStaffLoading(false))
      })
  },

  staffCreate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(staffActions.setStaffLoading(true))

        staffApi
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }

            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(staffActions.setStaffLoading(false))
          })
      },

  staffEdit:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(staffActions.setStaffLoading(true))

        staffApi
          .edit(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }

            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(staffActions.setStaffLoading(false))
          })
      },

  staffDelete:
    (id: string | number, onFinish?: () => void) =>
      (dispatch: TAppDispatch) => {
        dispatch(staffActions.setStaffLoading(true))

        staffApi
          .delete(id)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(staffActions.setStaffLoading(false))
          })
      }
}
