import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {Inventorization, IPartnerRow, IPartnersState} from "./types"

const initialState: IPartnersState = {
  data: [],
  inventoriesData:null,
  isLoading: false,
  isUpdate: false
}

export const stockSlice = createSlice({
  name: "stock",
  initialState: initialState,
  reducers: {
    setStockData: (state, action: PayloadAction<IPartnerRow[]>) => {
      state.data = action.payload
    },
    setStockLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStockUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setInventoriesData:(state, action: PayloadAction<Inventorization>) => {
      state.inventoriesData = action.payload
    },
  }
})

export const StockSliceReducer = stockSlice.reducer
export const StockSliceActions = stockSlice.actions
