import React, { useEffect, useState } from "react"

import { DeleteOutlined, SaveOutlined } from "@ant-design/icons"
import { Button, Image, Space, Tooltip, InputNumber, Popconfirm, Select, Checkbox } from "antd"

import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { marketPlacePictureMap, vk_image } from "../../utils/data"
import { columnsWithSortingHeader } from "../../utils/function"

const LOCAL_STORAGE_KEY = "savedDropSupplierCols"

export type Column = any
export type Sorting = {
  [x: string]: string
}
const loadColumnsFromLocalStorage = (defaultColumns: Column[]) => {
  const savedColumns = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (savedColumns) {
    const columnsKeys = JSON.parse(savedColumns)?.map((col: { key: string }) => col.key)
    const sortedDefaultColumns = [ ...defaultColumns ].sort((a, b) => {
      return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
    })
    return sortedDefaultColumns.filter(el => [ ...columnsKeys,"selection" ].includes(el.key))
  }
  return defaultColumns
}
const sortedColumns = [ "product_id","quantity","arrival_date","name","sku","purchase_price","real_price","barcode" ]

const useDropshippingSupplierColumns = (props: { handleRowEdit: Function; handleSave: Function; handleClickDelete: Function; supplierId: number | string | undefined}):[
  columns:Column[],
  setColumns:React.Dispatch<React.SetStateAction<any[]>>,
  defaultColumns:Column[],
  sorting:Sorting,
  selectedIds: string[],
  setSelectedIds:React.Dispatch<React.SetStateAction<string[]>>
] => {
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })
  const [ selectedIds,setSelectedIds ] = useState<string[]>([])

  const handleSort = (columnKey: string) => {
    setSorting((prevSorting) => {
      if (prevSorting[columnKey]) {
        return {
          [columnKey]:prevSorting[columnKey] === "ASC" ? "DESC" : "ASC"
        }
      } else {
        return {
          [columnKey]: "ASC",
        }
      }
    })
  }
  const { handleRowEdit, handleSave, handleClickDelete, supplierId } = props
  const { pagesData } = useTypedSelector((state) => state.dropshippingSupplier)
  const handleClickAll = () => {
    if(pagesData?.rows?.length === selectedIds?.length){
      setSelectedIds([])
    }else{
      setSelectedIds(pagesData?.rows?.map((el)=>{
        return el.product_id
      }))
    }
  }
  const initialColumns = [
    {
      key: "selection",
      dataKey: "selection",
      width: 40,
      align:"center",
      selectedIds,
      title:<Checkbox onClick={handleClickAll} />,
      cellRenderer: ({ rowData }: any) => {
        return (
          <Checkbox
            checked={selectedIds.includes(rowData.product_id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds((prev) => [ ...prev, rowData.product_id ])
              } else {
                setSelectedIds((prev) => prev.filter(id => id !== rowData.product_id))
              }
            }}
          />
        )
      },
      frozen:true,
    },
    {
      key: "product_id",
      dataKey: "product_id",
      title: "ID",
      width: 80,
      cellRenderer: ({ rowData }: any) => <span>{rowData.product_id}</span>,
    },
    {
      key: "photo",
      dataKey: "photo",
      title: "Фото",
      width: 120,
      cellRenderer: ({ rowData }: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image alt="Фото" style={{ width: "60px", borderRadius: "8px" }} src={rowData.photo} />
        </div>
      ),
    },
    {
      key: "name",
      dataKey: "name",
      title: "Название",
      width: 200,
      cellRenderer: ({ rowData }: any) => <span>{rowData.name}</span>,
    },
    {
      key: "product_url",
      dataKey: "links",
      title: "Ссылки",
      width: 150,
      cellRenderer: ({ rowData }: any) => (
        <Space style={{ display: "flex", justifyContent: "center" }}>
          {rowData.links.product_url && (
            <a href={rowData.links.product_url} target="_blank" rel="noopener noreferrer">
              <img alt="Продукт" src={marketPlacePictureMap[rowData.links.marketplace_id]} style={{ borderRadius: "50%", width: "24px", marginRight: "8px", marginLeft: "8px" }} />
            </a>
          )}
          {rowData.links.vk_url && (
            <a href={rowData.links.vk_url} target="_blank" rel="noopener noreferrer">
              <img alt="VK" style={{ borderRadius: "50%", width: "24px" }} src={vk_image} />
            </a>
          )}
        </Space>
      ),
    },
    {
      key: "quantity",
      dataKey: "quantity",
      title: "Количество",
      width: 150,
      pagesData,
      cellRenderer: ({ rowData }:any) => {
        return (
          <InputNumber
            min={1}
            precision={0}
            value={Math.ceil(Number(rowData.quantity))}
            onChange={(val) => val !== null && handleRowEdit(rowData, "quantity", Math.ceil(Number(val)))}
          />
        )},
    },
    {
      key: "purchase_price",
      dataKey: "purchase_price",
      title: "Закупочная цена",
      width: 150,
      cellRenderer: ({ rowData }: any) => <span>{rowData.purchase_price}</span>,
    },
    {
      key: "real_price",
      dataKey: "real_price",
      title: "Реальная цена",
      width: 150,
      pagesData,
      cellRenderer: ({ rowData }: any) => (
        <InputNumber
          min={0}
          step={0.01}
          value={Number(rowData.real_price)}
          onChange={(val) => val !== null && handleRowEdit(rowData, "real_price", val)}
        />
      ),
    },
    {
      key: "arrival_date",
      dataKey: "arrival_date",
      title: "Время с момента заказа",
      width: 150,
      cellRenderer: ({ rowData }: any) => <strong style={{ color: `#${rowData.arrival_date.color}` }}>{rowData.arrival_date.value}</strong>,
    },
    {
      key: "sku",
      dataKey: "sku",
      title: "Артикул",
      width: 150,
      cellRenderer: ({ rowData }: any) => <span>{rowData.sku}</span>,
    },
    {
      key: "barcode",
      dataKey: "barcode",
      title: "Штрихкод",
      width: 150,
      cellRenderer: ({ rowData }: any) => <span>{rowData.barcode}</span>,
    },
    {
      key: "operation",
      dataKey: "operation",
      title: "Действия",
      width: 200,
      cellRenderer: ({ rowData }: any) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "10px", flexWrap: "wrap" }}>
          <Tooltip placement="topLeft" title="Сохранить">
            <Button
              className="blueButton"
              type="primary"
              shape="circle"
              onClick={() => handleSave(rowData)}
              icon={<SaveOutlined />}
              style={{ padding: 0, width: "20px", height: "30px" }}
            />
          </Tooltip>
          <Popconfirm
            title={"Подтверждаете удаление связи поставщика и товара?"}
            placement={"left"}
            onConfirm={() => handleClickDelete(supplierId, rowData.product_id)}
            okText="Удалить"
            trigger={"click"}
            cancelText="Отмена"
          >
            <Button
              className="blueButton"
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ padding: 0, width: "20px", height: "30px" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const [ defaultColumns,setDefaultColumns ] = useState(initialColumns)
  const [ columns, setColumns ] = useState(() => columnsWithSortingHeader(loadColumnsFromLocalStorage(defaultColumns),sorting,sortedColumns,handleSort))

  useEffect(() => {
    setColumns(columnsWithSortingHeader(loadColumnsFromLocalStorage(initialColumns),sorting,sortedColumns,handleSort))
    setDefaultColumns(columnsWithSortingHeader(initialColumns,sorting,sortedColumns,handleSort))
  }, [ pagesData,sorting,selectedIds ])

  return [ columns, setColumns, defaultColumns,sorting,selectedIds,setSelectedIds ]
}

export default useDropshippingSupplierColumns
