import { analyticsSliceActions } from "./analyticsSlice";
import { analyticsApi } from "../../../common/api/endpoints/analyticsApi";
import { handleResponseError } from "../../../common/api/middleware";
import { TAppDispatch } from "../../store";
import axios from "axios";

export const analyticsActions = {
    ...analyticsSliceActions,

    fetchFilterList: () => (dispatch: TAppDispatch) => {
        dispatch(analyticsSliceActions.setLoadingFilters(true));

        analyticsApi
            .fetchFilterList()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(analyticsSliceActions.setFilterList(response.data));
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err);
            })
            .finally(() => {
                dispatch(analyticsSliceActions.setLoadingFilters(false));
            });
    },

    fetchOrdersByPeriod: (filters: any) => (dispatch: TAppDispatch) => {
        dispatch(analyticsSliceActions.setLoadingOrders(true));

        analyticsApi
            .fetchOrdersByPeriod(filters)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(analyticsSliceActions.setOrderData(response.data.rows));
                    dispatch(
                        analyticsSliceActions.setPagesData({
                            total: response.data.total || 0,
                            totalPages: response.data.totalPages || 0,
                            currentPage: response.data.currentPage || 1,
                            pageSize: response.data.pageSize || 10
                        })
                    );
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err);
            })
            .finally(() => {
                dispatch(analyticsSliceActions.setLoadingOrders(false));
            });
    },
    
    downloadOrdersReportExcel: (filters: any) => async (dispatch: TAppDispatch) => {
        try {
            await analyticsApi.downloadOrdersReportExcel(filters);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                handleResponseError(dispatch, err);
            } else {
                console.error("Unexpected error:", err);
            }
        }
    }
};
