import React, { FC, useEffect } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"

import { partnersApi } from "../../../common/api/endpoints/partnersApi"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showErrorMessage, showFormErrors, showSuccessMessage } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
  editData: any
}

const EditModal: FC<Props> = ({ isOpen, setIsOpen, editData }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.partners)
  const { parentsEdit, parentsGetList } = useActions()
  useEffect(() => {
    const fetchEditData = async () => {
      try{
        const { data } = await partnersApi.fetchById(editData.id)
        form.setFieldsValue(data)
      }catch(err){
        showErrorMessage("Произошла ошибка во время получения данных.")
      }
    }
    fetchEditData()
  }, [ editData ])

  const updatePartner = (values: any) => {
    if(values.client_id === undefined){
      values.client_id = null
    }
    const data = {
      id: editData.id,
      ...values
    }

    parentsEdit(
      data,
      () => {
        showSuccessMessage("Партнер успешно обновлен.")
        setIsOpen(false)
        parentsGetList()
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  return (
    <Modal
      title="Редактирование партнера"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={updatePartner}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="Название партнера"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Организация"
          name="org"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Маркетплейс"
          name="marketplace_id"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Select>
            <Select.Option value={1}>
              WildBerries
            </Select.Option>
            <Select.Option value={2}>
              Ozon
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Клиент ID"
          name="client_id"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="API KEY"
          name="api_key"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditModal
