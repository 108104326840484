import axios from "axios";
import { axiosConfigAuth } from "../middleware"
import { API_PURCHASE_DELETE, API_PURCHASE_EXPORT_EXCEL, API_PURCHASE_GET_FILTER, API_PURCHASE_TABLE_GET_TABLE } from "../urls"
import { showErrorNotification } from "../../../utils/ui";


export const purchaseApi = {
    fetchList: async (filters: any) => {
        return axios.post(API_PURCHASE_TABLE_GET_TABLE, filters, axiosConfigAuth());
    },

    getPartnerFilterList: async () => {
        return axios.get(API_PURCHASE_GET_FILTER, axiosConfigAuth());
    },

    delete: async(data:any) => {
        return axios.post(API_PURCHASE_DELETE, data, axiosConfigAuth())
    },

    downloadExcel: async (filters:any) => {
        try {
          const response = await axios.post(
            API_PURCHASE_EXPORT_EXCEL,
            filters,
            {
              ...axiosConfigAuth(),
               responseType: "blob",
            }
          )
    
          const url = window.URL.createObjectURL(new Blob([ response.data ]))
          const link = document.createElement("a")
          link.href = url
          const contentDisposition = response.headers["content-disposition"]
          const now = new Date()
          const year = now.getFullYear()
          const month = String(now.getMonth() + 1).padStart(2, "0")
          const day = String(now.getDate()).padStart(2, "0")
    
          let fileName = `export_dropshipping_purchases_${year}_${month}_${day}.csv`
    
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
            if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1]
            }
          }
          link.setAttribute("download", fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } catch (error:any) {
          // const dispatch = (() => {}) as TAppDispatch
          showErrorNotification("Нет данных для скачивания.")
          // console.error("Error downloading Excel file:", error)
        }
      },
}