import React, { FC, useState } from "react"

import { Layout } from "antd"

import Footer from "../footer/Footer"
import Header from "../header/Header"
import Nav from "../nav/Nav"

type Props = {
  children: React.ReactElement
  withHead?: boolean
  withFooter?: boolean
  clean?: boolean
  pageTitle?: string
}

const MainLayout: FC<Props> = ({
  children,
  withHead = true,
  withFooter = false,
  clean = false,
  pageTitle
}) => {
  document.title = pageTitle ? `${pageTitle}` : "CRM"

  const [ collapsed, setCollapsed ] = useState(false)

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        {clean ? null : (
          <Layout.Sider
            theme="dark"
            // collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="layout__nav-sider"
          >
            <Nav />
          </Layout.Sider>
        )}

        <Layout>
          {withHead ? <Header /> : null}
          <Layout.Content className="layout__content">
            {children}
          </Layout.Content>

          {withFooter ? <Footer /> : null}
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MainLayout
