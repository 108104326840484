import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Modal, Space, Spin, Table, Upload } from "antd"
import { ColumnsType } from "antd/es/table"
import { useDispatch } from "react-redux"

import { monitoringApi } from "../../common/api/endpoints/monitoringApi"
import { handleResponseError } from "../../common/api/middleware"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"


const ImportsPage: FC = () => {
  const dispatch = useDispatch()
  const [ data, setData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isLoadingImport, setIsLoadingImport ] = useState(false)

  const { monitoringDownloadFile, monitoringDeleteFile } = useActions()

  const fetchData = () => {
    setIsLoading(true)

    monitoringApi.fetchImports()
      .then((res) => {
        setData(res.data || [])
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteImport = (id: string) => {
    monitoringDeleteFile(id, () => {
      fetchData()
    })
  }

  const downloadImport = (id: string) => {
    monitoringDownloadFile(id, "import.csv")
  }

  const importFile = (type: 1 | 2 | 3, file: File | Blob | string) => {
    if (!type || !file) return

    setIsLoadingImport(true)

    monitoringApi.importFile(type, file)
      .then(() => {
        fetchData()
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        setIsLoadingImport(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const importsColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Название файла",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value, record) => (
        <Space>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              downloadImport(record.id)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить импорт?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  deleteImport(record.id)
                }
              })
            }}
            danger
          />
        </Space>
      )
    },
  ]

  return (
    <MainLayout pageTitle="Импорт">
      <div className="page-wrapper">
        <PageHead
          title="Импорт"
        />

        <Spin
          spinning={isLoadingImport}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Space direction="horizontal" style={{ padding: "0 24px", position: "relative" }}>
            <Upload
              maxCount={1}
              fileList={[]}
              customRequest={(options) => importFile(1, options.file)}
            >
              <Button type="primary" className="blueButton">
                  Импорт поставщиков
              </Button>
            </Upload>

            <Upload
              maxCount={1}
              fileList={[]}
              customRequest={(options) => importFile(2, options.file)}
            >
              <Button type="primary" className="blueButton">
                  Импорт ссылок на ВК
              </Button>
            </Upload>

            <Upload
              maxCount={1}
              fileList={[]}
              customRequest={(options) => importFile(3, options.file)}
            >
              <Button type="primary" className="blueButton">
                  Импорт цен
              </Button>
            </Upload>
          </Space>
        </Spin>

        <div className="page-content">
          <Table
              virtual={true}
            dataSource={data}
            columns={importsColumns}
            scroll={{ x: 870 }}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            pagination={{ position: [ "bottomCenter" ] }}
            bordered
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default ImportsPage
