import React from "react"

import { Image, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import { marketPlacePictureMap } from "../../../utils/data"

const SbermarketColumns: ColumnsType<any> = [
  {
    title: "Фото",
    dataIndex: "product_photo",
    key: "product_photo",
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (val) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          alt={"Основное фото"}
          style={{ width: "60px" }}
          src={val || "https://brilliant24.ru/files/cat/template_01.png"}
        />
      </div>
    ),
  },
  {
    title: "Номер заказа",
    dataIndex: "number",
    key: "number",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Название товара",
    dataIndex: "product_name",
    key: "product_name",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Url",
    dataIndex: "product_url",
    key: "product_url",
    sorter: true,
    render: (value:string) => {
      return (
        <div style={{ display: "flex", justifyContent: "center", columnGap: "8px" }}>
          <Tooltip placement="topLeft" title={"Ссылка на маркетплейс"}>
            <a href={value} target="_blank">
              <img
                alt={"Маркетплейс"}
                style={{ borderRadius: "50%", width: "25px" }}
                src={marketPlacePictureMap[3]}
              />
            </a>
          </Tooltip>
        </div>
      )
    },
    ellipsis: {
      showTitle: false,
    },
    width: 150,
  },
  {
    title: "Баркод",
    dataIndex: "barcode",
    key: "barcode",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Кол-во шт",
    dataIndex: "confirmed_quantity",
    key: "confirmed_quantity",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={`${val} шт`}>
        {val} {val > 0 && "шт"}
      </Tooltip>
    ),
  },
  {
    title: "Название компании",
    dataIndex: "company_name",
    key: "company_name",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Адрес доставки",
    dataIndex: "delivery_address",
    key: "delivery_address",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
  {
    title: "Цена за шт",
    dataIndex: "unit_price",
    key: "unit_price",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={`${val} руб`}>
        {val} руб
      </Tooltip>
    ),
  },
  {
    title: "Общая сумма",
    dataIndex: "total_amount",
    key: "total_amount",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={`${val} руб`}>
        {val} руб
      </Tooltip>
    ),
  },
  {
    title: "Дата доставки",
    dataIndex: "delivery_date",
    key: "delivery_date",
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
    width: 150,
    render: (val) => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
]

export default SbermarketColumns
