import React, { FC, MouseEventHandler, useEffect, useMemo, useState } from "react"

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons"
import { tab } from "@testing-library/user-event/dist/tab"
import { Button, Table, Tooltip, Form, Switch, Input, Alert } from "antd"
import { ColumnsType } from "antd/es/table"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import AddSupplier from "./components/AddSupplier"
import { stockFBSMarketApi } from "../../common/api/endpoints/stockFBSMarketApi"
import { stockSuppliersApi } from "../../common/api/endpoints/stockSuppliersApi"
import { handleResponseError } from "../../common/api/middleware"
import ColorTag from "../../common/components/color-tag/ColorTag"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import MainLayout from "../../common/components/layouts/MainLayout"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import { defaultStockSuppliersFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { TAppDispatch } from "../../redux/store"
import { TransferToDelivery } from "../../types/api"
import { showErrorMessage, showSuccessMessage } from "../../utils/ui"
import error from "../error";
import {AxiosError} from "axios";
import SyncSupplier from "./components/SyncSupplier";

const Supplier: FC = () => {
  const { supplierId }  = useParams()

  const [ isTransferToDeliveryLoading,setIsTransferToDeliveryLoading ] = useState(false)
  const { isLoading,
    isUpdate,
    supplierData,
    isSocket
  } =
        useTypedSelector((state) => state.stockSuppliers)
  const {
    stockSuppliersGetSupplier,
    stockSupplierClose,
    setStockSuppliersUpdate,
    stockSuppliersGetCompanies,
    setStockSupplierSocket
  } =
        useActions()
  const [ activeCards, setActiveCards ] = useState<any>({
    done: 1,
  })
  const dispatch = useDispatch()
  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ addSupplierState,setAddSupplierState ] = useState<{visible: boolean,id: null | number | undefined , partner_id: null | number | undefined,name: string | undefined,marketplace_id:number | null | undefined}>({ visible:false,id:null,partner_id:null,name:"",marketplace_id:null })
  const [ isChecked,setIsChecked ] = useState(false)
  const [ isSyncSupplierVisible,setIsSyncSupplierVisible] = useState(false)

  const StockSuppliersColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Статус заказа",
      dataIndex: "status_info",
      key: "status_info",
      sorter: (a, b) => a.status_info.name.localeCompare(b.status_info.name),
      align:"center",
      width: 220,
      render: (val) => {
        const color = `#${val?.color}` || "red"
        return <ColorTag color={color}><span style={{ color:"white",fontWeight:"600",padding:"12px",fontSize:"14px" }}>{val.name}</span></ColorTag>
      }
    },
    {
      title: "Номер заказа",
      dataIndex: "order_id",
      key: "order_id",
      sorter: (a, b) => a.order_id - b.order_id,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Дата сборки",
      dataIndex: "assembly_date",
      key: "assembly_date",
      sorter: (a, b) => new Date(a.assembly_date).getTime() - new Date(b.assembly_date).getTime(),
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Успешно",
      dataIndex: "error_status",
      key: "error_status",
      sorter: (a, b) => a.error_status - b.error_status,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        val !== 3 && (<Tooltip placement="topLeft" title={val === 2 ? "Нету ошибки." : "Есть ошибка."}>
          {val === 2 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </Tooltip>)
      ),
    },
    {
      title: "Ошибка",
      dataIndex: "error_msg",
      key: "error_msg",
      sorter: (a, b) => a.error_msg.localeCompare(b.error_msg),
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Количество",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (val) => {
        return (
          <Tooltip placement="topLeft" title={`${val} шт`}>
            {val} {val > 0 && "шт"}
          </Tooltip>
        )
      },
    },
  ]


  const [ columns,setColumns ] = useState<ColumnsType<any>> (() => {
    const savedColumns = localStorage.getItem("savedSupplierColumns")
    if (savedColumns) {
      const columnsKeys = JSON.parse(savedColumns).map((el:{key:string})=>el.key)
      const sortedDefaultColumns = [ ...StockSuppliersColumns ].sort((a, b) => {
        return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
      })
      return sortedDefaultColumns.filter(el => columnsKeys.includes(el.key))
    }
    return StockSuppliersColumns
  })
  const [ selectedSupplier,setSelectedSupplier ] = useState<number[]>([])


  useEffect(() => {
    stockSuppliersGetCompanies()
    stockSuppliersGetSupplier({
      supply_id:supplierId,
      check_supply_orders:isChecked
    },false)
    // connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      console.log(isUpdate,isSocket)
      stockSuppliersGetSupplier({
        supply_id:supplierId,
        check_supply_orders:isChecked
      },isSocket)
      setStockSupplierSocket(false)
      setStockSuppliersUpdate(false)
    }
  }, [ isUpdate,isSocket,isChecked ])


  const generateRowClassName = (record: any) => {
    return `row-color-${record.line_color?.replace("#", "")}`
  }

  const dynamicStyles = useMemo(() => {
    const styles: any = {}
    supplierData?.rows?.forEach((item: any) => {
      if (item.line_color) {
        const className = `row-color-${item.line_color?.replace("#", "")}`
        styles[className] = { backgroundColor: `#${item.line_color}` }
      }
    })
    return styles
  }, [ supplierData?.rows ])

  const handleCardClick = (doneValue: number) => {
    setActiveCards((prevState: any) => ({
      ...prevState,
      done: doneValue,
    }))
    // setFilters((prev:any)=>({ ...prev,done:doneValue }))
    // setStockSuppliersUpdate(true)
  }

  const handleCheckSupplier = () => {
    setIsChecked(true)
    setStockSuppliersUpdate(true)
  }

  const handleImportExcel = async () => {
    try{
      await stockSuppliersApi.downloadSupplierExcel({
        supply_id:supplierId,
        check_supply_orders:isChecked
      })
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }
  const handleSyncSupplier = () => {
    setIsSyncSupplierVisible(true)
  }

  const handleDownloadLabel = async (order_id:number) => {
    try{
      await stockSuppliersApi.downloadLabel(order_id)
    }catch (error:any) {
      handleResponseError(dispatch, error)
    }
  }

  const tableData = useMemo(()=>{
    return supplierData?.rows?.filter((el:{error_status:number})=>activeCards.done === 0 ? el.error_status === 1 : true)
  },[ supplierData?.rows,activeCards.done ])

  const handleTransferToDelivery = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if((event as {target?:{className?:string}})?.target?.className !== "action"){
      return
    }
    if(selectedSupplier?.length === 0){
      showErrorMessage("Выберите поставку для переноса")
      return
    }
    try{
      setIsTransferToDeliveryLoading(true)
      const values = {
        supply_id :selectedSupplier[0],
        marketplace_id:supplierData?.marketplace_id,
        partner_id :supplierData?.partner_id,
        type:null,
        ids:tableData.filter((el:{error_msg:string,status_info:{id:number}})=>[ 1,2 ].includes(el?.status_info?.id) && el?.error_msg === "Заказ не собран").map((el:{order_id:number})=>el.order_id)
      } as TransferToDelivery

      await stockFBSMarketApi.transferToDelivery(values)
      showSuccessMessage("Заказы успешно переведены в поставку.")
      setSelectedSupplier([])
      setStockSuppliersUpdate(true)
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      setIsTransferToDeliveryLoading(false)
    }
  }
  const handleCreateNewSupplier = () => {
    setAddSupplierState({
      visible:true,
      id:null,
      partner_id:supplierData?.partner_id,
      marketplace_id:supplierData?.marketplace_id,
      name:""
    })
  }

  return (
    <>
      <style>
        {Object.keys(dynamicStyles).map(className => {
          const style = dynamicStyles[className]
          return `
            .${className} td {
              background-color: ${style.backgroundColor};
            }
            .${className} .ant-table-cell-fix-left, 
            .${className} .ant-table-cell-fix-right {
              background-color: ${style.backgroundColor};
            }
          `
        })}
      </style>
      <MainLayout pageTitle={supplierData?.title || `Поставка #${supplierId}`}>

        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title={supplierData?.title || `Поставка #${supplierId}`}
            extra={
              <div>
              </div>
            }
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px",justifyContent:"space-between",marginRight: "24px" }}>
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              <Button
                type="primary"
                className={"blueButton"}
                onClick={handleCheckSupplier}
                disabled={!supplierData?.button_check || isChecked}
                loading={isLoading}
                style={{

                }}
              >
                <span>
                ПРОВЕРИТЬ
                </span>
              </Button>
              {
                supplierData?.button_close_delivery &&    <Button
                  type="primary"
                  className={"blueButton"}
                  disabled={!supplierData?.button_close_delivery}
                  onClick={()=>stockSupplierClose(Number(supplierId))}
                  loading={isLoading}
                  style={{
                    borderLeftWidth:"1px",
                    borderLeftColor:"white",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                >
                  <span>
                ЗАКРЫТЬ ПОСТАВКУ
                  </span>
                </Button>
              }
              {
                supplierData?.button_move_delivery && supplierData?.supplies_for_transfer?.length > 0 &&  <Button
                  type="primary"
                  onClick={(e)=>handleTransferToDelivery(e)}
                  disabled={isTransferToDeliveryLoading}
                  loading={isTransferToDeliveryLoading}
                  style={{
                    paddingRight: 0,
                    borderLeftWidth:"1px",
                    borderLeftColor:"white",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}

                  className={"blueButton"}
                >
                  <span className={"action"}>
                    {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                  Перенести в поставку
                  </span>
                  <span style={{
                    position: "absolute",
                    marginTop: "-5px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    height: "100%"
                  }}>
                  </span>
                  <span style={{ width: "6px" }}></span>
                  <AdvancedDropdown
                    items={supplierData?.supplies_for_transfer || []}
                    selectedIds={selectedSupplier}
                    setSelectedIds={(values) => {
                      setSelectedSupplier(values)
                    }}
                    additionalItems={[
                      { key: -1, label: "Создать новую" }
                    ]}
                    handleMenuItemClick={handleCreateNewSupplier}
                    multiple={false}

                  />
                </Button>
              }


            </div>
            <div style={{ display:"flex" }}>
              <Button
                type="primary"
                loading={isLoading}
                className={`${tableData?.length === 0 ? "" : "blueButton"}`}
                onClick={handleSyncSupplier}
                style={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  background:"#00a2e8"
                }}
              >
                <span>
                Синхронизировать поставки
                </span>
              </Button>
              <Tooltip placement="topLeft" title={"Скачать этикетку поставки"}>

                <Button
                  type="primary"
                  className={`${tableData?.length === 0 ? "" : "blueButton"}`}
                  onClick={() => handleDownloadLabel(Number(supplierId))}
                  disabled={tableData?.length === 0}
                  loading={isLoading}
                  style={{
                    borderLeftWidth:"1px",
                    borderLeftColor:"white",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    background:"#00a2e8"
                  }}
                >
                  <PrinterOutlined />
                </Button>
              </Tooltip>
              <Button
                type="primary"
                className={`${tableData?.length === 0 ? "" : "blueButton"}`}
                onClick={handleImportExcel}
                disabled={tableData?.length === 0}
                loading={isLoading}
                style={{
                  borderLeftWidth:"1px",
                  borderLeftColor:"white",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  background:"#00a2e8"
                }}
              >
                <PrinterOutlined />
                <span>
                Распечатать
                </span>
              </Button>
            </div>
          </div>
          {
            supplierData?.msg && <Alert style={{ marginLeft:"24px",marginBottom:"16px" }} type={"error"} message={supplierData?.msg}  />
          }
          <div style={{ display: "flex", marginLeft: "24px", columnGap: "80px", flexWrap: "wrap", rowGap: "20px",position:"relative" }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>СТАТУС</span>
            <span style={{ position:"absolute",left:"167px",fontSize:16,fontWeight:500 }}>{supplierData?.supply_status}</span>
          </div>
          <div style={{
            display: "flex",
            marginTop: "16px",
            marginLeft: "24px",
            columnGap: "80px",
            flexWrap: "wrap",
            rowGap: "20px",
            position:"relative"
          }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>ДАТА ПОСТАВКИ</span>
            <Input style={{ maxWidth: "100px",position:"absolute",left:"167px" }} defaultValue={supplierData?.date} value={supplierData?.date}/>
          </div>
          <div style={{
            display: "flex",
            marginTop: "16px",
            marginLeft: "24px",
            columnGap: "80px",
            flexWrap: "wrap",
            rowGap: "20px",
            position:"relative"
          }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>ВНЕШНИЙ НОМЕР</span>
            <Input style={{ width:`${supplierData?.external_id?.length * 9.5}px`,position:"absolute",left:"167px"  }} defaultValue={supplierData?.external_id} value={supplierData?.external_id}/>
          </div>
          <div className="page-content">

            <div
              style={{
                display: "flex",
                columnGap: "20px",
                alignItems: "center",
                rowGap: "10px",
                flexWrap: "wrap",
                position:"relative"
              }}
            >
              <Form.Item style={{ width: "max-content",marginTop:"16px" }} name="not_collected" valuePropName="checked">
                <span style={{ marginRight: "10px",fontWeight: 600, fontSize: 16 }}>Проблемные заказы</span>
                <Switch
                  checked={activeCards.done === 0}
                  onChange={() => handleCardClick(activeCards.done === 0 ? 1 : 0)}
                />
              </Form.Item>
            </div>


            <Table
              virtual={true}
              title={() => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={() => setIsManageColumnsVisible(true)}>
                    <MenuOutlined style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}/>
                        СТОЛБЦЫ</Button>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button style={{ border: "none", fontWeight: "400", color: "#4096ff" }} onClick={() => {
                      setColumns(StockSuppliersColumns), showSuccessMessage("Порядок успешно возвращен."), localStorage.setItem("savedSupplierColumns", JSON.stringify(StockSuppliersColumns))
                    }}>ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ</Button>
                  </div>
                </div>

              )}
              columns={columns}
              dataSource={tableData}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
              }}
              scroll={{ x: 1300 }}
              pagination={{
                position: [ "bottomLeft" ],
                showSizeChanger: true,
                pageSizeOptions: [ "10", "25", "50", "100" ],
                defaultCurrent: 1,
                defaultPageSize: 10,
                showTotal: (total, range) =>
                  `Всего строк: ${total}`
              }}
              rowClassName={generateRowClassName}
              rowKey={"order_id"}
              size="small"
              bordered
            />
          </div>
          {
            isManageColumnsVisible &&
              <ColumnSettings setVisible={setIsManageColumnsVisible}
                defaultColumns={StockSuppliersColumns}
                columns={columns} localStorageKey={"savedSupplierColumns"}
                setColumns={setColumns}
              />
          }
          {
            addSupplierState.visible && <AddSupplier addState={addSupplierState} setAddState={setAddSupplierState}/>
          }
          {
              isSyncSupplierVisible && <SyncSupplier visible={isSyncSupplierVisible} setVisible={setIsSyncSupplierVisible} />
          }
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

const iconButtonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const sendToSuppliesText = "Выберете заказы, которые хотите массово добавить в поставку на маркетплейсе. Сменить поставку можно только для заказов со статусами НОВЫЙ, НА СБОРКЕ или СОБРАН."


export default Supplier
