import { appActions } from "./slices/app/actions"
import { appSliceReducer } from "./slices/app/appSlice"
import { authActions } from "./slices/auth/actions"
import { authSliceReducer } from "./slices/auth/authSlice"
import { currentUserActions } from "./slices/currentUser/actions"
import { currentUserSliceReducer } from "./slices/currentUser/currentUserSlice"
import { exportActions } from "./slices/export/actions"
import { exportSliceReducer } from "./slices/export/exportSlice"
import { importActions } from "./slices/importProducts/actions"
import { importSliceReducer } from "./slices/importProducts/importProductsSlice"
import { itemActions } from "./slices/items/actions"
import { itemSliceReducer } from "./slices/items/itemSlice"
import { monitoringActions } from "./slices/monitoring/actions"
import { monitoringSliceReducer } from "./slices/monitoring/monitoringSlice"
import { partnersActions } from "./slices/partners/actions"
import { partnersSliceReducer } from "./slices/partners/parntersSlice"
import { monitoringSettingsActions } from "./slices/settings/monitoring/actions"
import { monitoringSettingsSliceReducer } from "./slices/settings/monitoring/monitoringSlice"
import { staffActions } from "./slices/staff/actions"
import { staffSliceReducer } from "./slices/staff/staffSlice"
import { stockActions } from "./slices/stock/actions"
import { StockSliceReducer } from "./slices/stock/stockSlice"
import { suppliersActions } from "./slices/suppliers/actions"
import { suppliersSliceReducer } from "./slices/suppliers/suppliersSlice"
import { telegramNotificationsActions } from "./slices/telegramNotifications/actions"
import { telegramNotificationsSliceReducer } from "./slices/telegramNotifications/telegramNotificationsSlice"
import { priceActions } from "./slices/prices/actions";
import { priceSliceReducer } from "./slices/prices/priceSlice";
import { sberActions } from "./slices/cberMarket/actions";
import { sberSliceReducer } from "./slices/cberMarket/sberSlice";
import { stockFBSActions } from "./slices/stockfbs/actions";
import { stockFBSSliceReducer } from "./slices/stockfbs/stockFBSSlice";
import { stockSuppliersActions } from "./slices/stockSuppliers/actions";
import { stockSuppliersSliceReducer } from "./slices/stockSuppliers/stockSuppliers";
import { dropshippingSliceReducer } from "./slices/dropshipping/dropshippingSlice"
import { dropshippingActions } from "./slices/dropshipping/actions"
import { dropshippingSupplierActions } from "./slices/dropshippingSuppliers/actions"
import { dropshippingSupplierSliceReducer } from "./slices/dropshippingSuppliers/dropshippingSuppliers"
import { purchaseActions } from "./slices/purchase/actions"
import { purchaseSliceReducer } from "./slices/purchase/purchase"
import { analyticsActions } from "./slices/analytics/actions"
import { analyticsSliceReducer } from "./slices/analytics/analyticsSlice"

export const rootAction = {
  ...appActions,
  ...authActions,
  ...currentUserActions,
  ...telegramNotificationsActions,
  ...staffActions,
  ...itemActions,
  ...priceActions,
  ...importActions,
  ...exportActions,
  ...partnersActions,
  ...stockActions,
  ...monitoringSettingsActions,
  ...monitoringActions,
  ...suppliersActions,
  ...sberActions,
  ...stockSuppliersActions,
  ...stockFBSActions,
  ...dropshippingActions,
  ...dropshippingSupplierActions,
  ...purchaseActions,
  ...analyticsActions
}

export const rootReducer = {
  app: appSliceReducer,
  auth: authSliceReducer,
  price:priceSliceReducer,
  currentUser: currentUserSliceReducer,
  stock:StockSliceReducer,
  staff: staffSliceReducer,
  importProducts:importSliceReducer,
  item: itemSliceReducer,
  partners: partnersSliceReducer,
  monitoringSettings: monitoringSettingsSliceReducer,
  monitoring: monitoringSliceReducer,
  telegramNotifications:telegramNotificationsSliceReducer,
  export:exportSliceReducer,
  suppliers:suppliersSliceReducer,
  sber:sberSliceReducer,
  stockFBS:stockFBSSliceReducer,
  stockSuppliers:stockSuppliersSliceReducer,
  dropshipping: dropshippingSliceReducer,
  dropshippingSupplier:dropshippingSupplierSliceReducer,
  purchase: purchaseSliceReducer,
  analytics: analyticsSliceReducer
}
