import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined, HomeOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Input, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import ColorTag from "../../common/components/color-tag/ColorTag"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { allTagColors, errorTagColor, successTagColor } from "../../common/constants/colors"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { showSuccessMessage } from "../../utils/ui"
import {marketPlacePictureMap} from "../../utils/data";
const Stock: FC = () => {
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ editData, setEditData ] = useState({
    id: "",
    name: "",
    api_key_standard: "",
    api_key_stats: ""
  })

  const { data, isLoading } = useTypedSelector((state) => state.stock)
  const { stockGetList, stockDelete } = useActions()

  const [ tempData, setTempData ] = useState(data)

  useEffect(() => {
    setTempData(data)
  }, [ data ])

  useEffect(() => {
    stockGetList()
  }, [])

  const partnersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 8,

      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Название  склада",
      dataIndex: "name",
      key: "name",
      width: 60,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "ID склада продавца",
      dataIndex: "seller_warehouse_id",
      key: "seller_warehouse_id",
      width: 50,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 40,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display:"flex",justifyContent:"center" }}>
          <img alt={"Маркетплейс"} style={{ borderRadius:"50%",width:"36px" }} src={marketPlacePictureMap[val as number]} />
        </div>
      )
    },
    {
      title: "Партнер",
      dataIndex: "partner_name",
      key: "partner_name",
      width: 70,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Статус склада",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 25,
      render: (val) => {
        let color = errorTagColor

        switch (val) {
          case "Активный":
            color = successTagColor
            break
          case "Заблокирован":
            color = errorTagColor
            break
          case "Активируется":
            color = allTagColors[2]
            break
          case "На паузе":
            color = allTagColors[3]
            break
          case "В архиве":
            color = allTagColors[1]
            break

          default:
            color = errorTagColor
        }

        return <ColorTag color={color}>{val}</ColorTag>
      }
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (value, record) => (
        <Space style={{ display:"flex",justifyContent:"center" }} direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              /*
              setEditData({
                id: record.id,
                name: record.name,
                api_key_standard: record.api_key_standard,
                api_key_stats: record.api_key_stats
              })
              setIsEditOpen(true)
               */
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить склад?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  /*
                  stockDelete(record.id, () => {
                    showSuccessMessage("Склад успешно удален.")
                    stockGetList()
                  })
                   */
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]

  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    if (value) {
      const _data = tempData.filter((val: any) => {
        return (
          `${val.api_key_standard}`.toLowerCase().includes(searchString) ||
                    `${val.name}`.toLowerCase().includes(searchString) ||
                    `${val.api_key_stats}`.toLowerCase().includes(searchString) ||
                    `${val.phone_binding_wb}`.toLowerCase().includes(searchString) ||
                    `${val.created_at}`.toLowerCase().includes(searchString) ||
                    `${val.updated_at}`.toLowerCase().includes(searchString)
        )
      })

      setTempData(_data)
    } else {
      setTempData(data)
    }
  }

  return (
    <MainLayout pageTitle="Склады">
      <div className="page-wrapper">
        <PageHead
          title="Склады"
          extra={
            <div>
            </div>
          }
        />

        <div className="page-content">
          <Space>
            <Input
              placeholder="Поиск по таблице..."
              onChange={(e) => searchTable(e.target.value)}
              style={{ width: 300, marginBottom: 20 }}
              allowClear
            />
          </Space>
          <Space style={{ marginTop:"-20px" }}>
            <Button type="primary" style={{background:"#00a2e8"}} onClick={()=>{}}>
              <HomeOutlined />
                            Добавить склад
            </Button>
          </Space>
          <Table
              virtual={true}
            columns={partnersColumns}
            dataSource={tempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            size="small"
            bordered
          />

          <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
          {
            isEditOpen && <EditModal
              isOpen={isEditOpen}
              setIsOpen={setIsEditOpen}
              editData={editData}
            />
          }

        </div>
      </div>
    </MainLayout>
  )
}

export default Stock
