import { importSliceActions } from "./importProductsSlice"
import { AddTask, UpdateStatus } from "./types"
import { importApi } from "../../../common/api/endpoints/importApi"
import { monitoringApi } from "../../../common/api/endpoints/monitoringApi"
import { handleResponseError } from "../../../common/api/middleware"
import { showSuccessMessage, showSuccessNotification } from "../../../utils/ui"
import { TAppDispatch } from "../../store"

export const importActions = {
  ...importSliceActions,
  importGetTableData: (marketplace_id: number) => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .fetchProducts(marketplace_id)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(importActions.setTableData(data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.setIsLoading(false))
      })
  },
  importRemoveTask: (taskId: number,marketplace_id:number) => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .removeTask(taskId)
      .then((response) => {
        if (response.status === 200) {
          showSuccessNotification("Задача удалена.")
          dispatch(importActions.importGetTableData(marketplace_id))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.setIsLoading(false))
      })
  },
  importGetStatuses: () => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .getStatuses()
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(importActions.setStatusData(data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.setIsLoading(false))
      })
  },
  importAddTask: (data:AddTask) => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .addTask(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(importActions.importGetTableData(data.marketplace_id))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.setIsLoading(false))
      })
  },
  importUpdateStatus: (data: UpdateStatus) => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .updStatus(data)
      .then((response) => {
        if (response.status === 200) {
          showSuccessNotification(data.status === 1 ? "Автоматический импорт включен." : "Автоматический импорт выключен.")
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.importGetStatuses())
        dispatch(importActions.setIsLoading(false))
      })
  },
  importGetPartnersList: (marketplace_id: number) => (dispatch: TAppDispatch) => {
    dispatch(importActions.setIsLoading(true))
    importApi
      .fetchPartnersList(marketplace_id)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          dispatch(importActions.setPartnersList(data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      }).finally(()=>{
        dispatch(importActions.setIsLoading(false))
      })
  },
}
