import React, { FC, useState } from "react"

import { Form, Button, Popover, InputNumber, Row, Col } from "antd"
interface FilterPopoverProps {
    title: string;
    paramNameFrom: string;
    paramNameTo: string;
    min: number;
    max?: number;
    step?: number;
}
const FilterPopover: FC<FilterPopoverProps> = ({ title, paramNameFrom, paramNameTo, min, max, step }) => {
  const [ visible, setVisible ] = useState(false)
  const hide = () => setVisible(false)
  const handleVisibleChange = (visible:boolean) => setVisible(visible)

  const content = (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name={paramNameFrom}>
            <InputNumber
              min={min}
              max={max}
              step={step}
              placeholder="от"
              style={{ width: "100%" }}
              name={paramNameFrom}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={paramNameTo}>
            <InputNumber
              min={min}
              max={max}
              step={step}
              placeholder="до"
              style={{ width: "100%" }}
              name={paramNameTo}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" onClick={hide} style={{ marginTop: "10px", width: "100%", background:"#00a2e8" }}>
                Применить
      </Button>
    </div>
  )

  return (
    <Popover
      content={content}
      title={title}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      <Button type="default">{title}</Button>
    </Popover>
  )
}
export default FilterPopover
