
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from "react"

import { Button, Image, Space, Tooltip } from "antd"
import { Link } from "react-router-dom"

import { routeNames } from "../../../common/constants/routes"
import { marketPlacePictureMap, vk_image } from "../../../utils/data"

const InitialColumns = (props: { setIsAddOpen: (open: boolean) => void; setProductId: (id: number | null) => void }): any[] => [
  {
    key: "marketplace_id",
    title: "Маркетплейс",
    dataKey: "marketplace_id",
    width: 150,
    sortable: true,
    align: "center",
    cellRenderer: ({ cellData }: any) => (
      <img alt="Маркетплейс" style={{ borderRadius: "50%", width: "28px" }} src={marketPlacePictureMap[cellData]} />
    ),

  },
  {
    key: "nm_id",
    title: "Артикул цифровой ВБ",
    dataKey: "nm_id",
    width: 150,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <Tooltip placement="topLeft" title={cellData}>
        {cellData}
      </Tooltip>
    ),

  },
  {
    key: "product_info",
    title: "Товар",
    dataKey: "product_info",
    width: 470,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <div style={{ display: "flex" }}>
        <div style={{ height: "120px" }}>
          <Image alt="Основное фото" style={{ width: "60px" }} src={cellData?.photo_url || "https://brilliant24.ru/files/cat/template_01.png"} />
        </div>
        <div style={{ display: "flex", marginLeft: "6px", flexDirection: "column", height: "100%" }}>
          <span><strong>1</strong> шт</span>
          <span><strong>{cellData?.purchase_price}</strong> руб</span>
        </div>
        <div style={{ display: "flex", marginLeft: "6px", flexWrap: "wrap", flexDirection: "column" }}>
          <strong>{cellData?.product_name}</strong>
          <span>Цвет: {cellData?.color}</span>
          <span>Размер: {cellData?.product_size}</span>
          <Space style={{ marginTop: "4px" }}>
            {cellData?.marketplace_id && (
              <a href={cellData?.product_url} target="_blank" rel="noopener noreferrer">
                <img alt="Маркетплейс" style={{ borderRadius: "50%", width: "25px", height: "25px" }} src={marketPlacePictureMap[cellData?.marketplace_id]} />
              </a>
            )}
            {cellData?.vk_url && (
              <a href={cellData?.vk_url} target="_blank" rel="noopener noreferrer">
                <img alt="VK" style={{ borderRadius: "50%", width: "25px", height: "25px" }} src="https://upload.wikimedia.org/wikipedia/commons/f/f3/VK_Compact_Logo_%282021-present%29.svg" />
              </a>
            )}
          </Space>
        </div>
      </div>
    ),
  },
  {
    key: "price_without_discount",
    title: "Цена до скидки",
    dataKey: "price_without_discount",
    width: 150,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <Tooltip placement="topLeft" title={cellData}>
        {cellData}
      </Tooltip>
    ),
  },
  {
    key: "discount_price_spp",
    title: "Цена после скидки",
    dataKey: "discount_price_spp",
    width: 150,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <Tooltip placement="topLeft" title={cellData}>
        {cellData}
      </Tooltip>
    ),
  },
  {
    key: "purchase_price_gardener",
    title: "Цена закупки",
    dataKey: "purchase_price_gardener",
    width: 150,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <Tooltip placement="topLeft" title={cellData}>
        {cellData}
      </Tooltip>
    ),
  },
  {
    key: "product_count",
    title: "Количество",
    dataKey: "product_count",
    width: 150,
    sortable: true,
    cellRenderer: ({ cellData }: any) => (
      <Tooltip placement="topLeft" title={cellData}>
        {cellData}
      </Tooltip>
    ),
  },
  {
    key: "barcodes",
    dataKey: "barcodes",
    title: "Баркод",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.barcodes}>
        {rowData.barcodes}
      </Tooltip>
    ),
    resizable: false,
  },
]

export default InitialColumns

