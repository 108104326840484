import React, { FC } from "react"

import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"

const Home: FC = () => {
  return (
    <MainLayout pageTitle="Главная">
      <div className="page-wrapper">
        <PageHead title="Главная" />

        <div></div>
      </div>
    </MainLayout>
  )
}

export default Home
