import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth, axiosRefreshConfig } from "../middleware"
import {
  API_AUTH_CONNECT_TELEGRAM,
  API_AUTH_GET_USER,
  API_AUTH_LOGIN,
  API_AUTH_LOGOUT,
  API_AUTH_REFRESH, 
  API_AUTH_REMOVE_TELEGRAM,
  API_AUTH_SET_PRINT_SETTINGS
} from "../urls"

export const authApi = {
  login: async (
    username: string,
    password: string,
  ): Promise<AxiosResponse> => {
    const data = {
      username: username,
      password
    }

    return axios.post(API_AUTH_LOGIN, data, axiosRefreshConfig())
  },

  logout: async (): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_LOGOUT, axiosConfigAuth({ withCredentials: true }))
  },

  getUserData: async (): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_GET_USER, axiosConfigAuth())
  },
  
  setPrintSettings: async (id: number): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_SET_PRINT_SETTINGS(id), axiosConfigAuth())
  },

  refreshToken: async (fingerprint: string): Promise<AxiosResponse> => {
    const data = {
      fingerprint: fingerprint
    }

    return axios.post(API_AUTH_REFRESH, data, axiosRefreshConfig())
  },

  connectTelegram: async (): Promise<AxiosResponse> => {
    return axios.get(API_AUTH_CONNECT_TELEGRAM, axiosConfigAuth())
  },
  removeTelegram: async (id:number): Promise<AxiosResponse> => {
    return axios.get(`${API_AUTH_REMOVE_TELEGRAM}${id}`, axiosConfigAuth())
  },
}
