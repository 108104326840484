import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {IItemState, IItemRow, ImportResults, IDownloadQuantityModal} from "./types"

const initialState: IItemState = {
  itemData: [],
  pagesData: {
    total: 2,
    totalPages: 1,
    start: 1,
    end: 20,
    currentPage: 1,
    pageSize: 20
  },
  filterList: {
    users_list: [],
    suppliers_list: []
  },
  filters: {},
  isLoading: false,
  isUpdate: false,
  isLoadingLists: false,
  isLoadingFilters: false,
  downloadQuantityModal:{ isVisible:false,data:0,text:"Not found",service:"" },
  companies:[],
  importResults:{} as ImportResults,
  suppliersResults:[]
}

export const stockFBSSlice = createSlice({
  name: "stockFBS",
  initialState: initialState,
  reducers: {
    setFilterList: (state, action: PayloadAction<any>) => {
      state.filterList = action.payload;
    },
    setLoadingFilters: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFilters = action.payload;
    },
    setStockFBSImportResults: (state, action: PayloadAction<ImportResults>) => {
      state.importResults = action.payload
    },
    setStockFBSSuppliersResults: (state, action: PayloadAction<IItemRow[]>) => {
      state.suppliersResults = action.payload
    },
    setStockFBSTableData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload.map((el)=>({ ...el,key:el.id }))
    },
    setStockFBSPagesData: (state, action: PayloadAction<any>) => {
      state.pagesData = action.payload
    },
    setStockFBSFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    setStockFBSCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload
    },
    setItemListsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLists = action.payload
    },
    setItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStockFBSUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setVisibleDownloadQuantityModal: (state, { payload }:PayloadAction<IDownloadQuantityModal>) => {
      state.downloadQuantityModal = payload
    }
  }
})

export const stockFBSSliceReducer = stockFBSSlice.reducer
export const stockFBSSliceActions = stockFBSSlice.actions
