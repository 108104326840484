import React, { FC, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import AddModal from "../components/AddModal";
import EditModal from "../components/EditModal";
import { defaultItemsFilters } from "../../../common/constants/filters";
import { useActions } from "../../../common/hooks/useActions";
import { useTypedSelector } from "../../../common/hooks/useTypedSelector";
import { projectIcons } from "../../../common/icons/projectIcons";
import { showSuccessMessage } from "../../../utils/ui";

const SuppliersTable: FC = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editData, setEditData] = useState<{ id: number; contact_info: string; name: string }>({
    id: 0,
    contact_info: "",
    name: ""
  });
  const [filters, setFilters] = useState(defaultItemsFilters);
  const [sorting, setSorting] = useState<{ [x: string]: string }>({});
  const { isLoading, isLoadingLists, isUpdate, itemData, pagesData, lists } = useTypedSelector((state) => state.suppliers);
  const { suppliersGetData, suppliersDelete, suppliersGetFilter, setSuppliersUpdate } = useActions();

  const defaultColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 20,
      render: (val) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Поставщик",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: { showTitle: false },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Номер телефона",
      dataIndex: "contact_info",
      key: "contact_info",
      sorter: true,
      ellipsis: { showTitle: false },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 90,
      render: (value, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditData(record);
              setIsEditOpen(true);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить поставщика?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  suppliersDelete(record.id, () => {
                    showSuccessMessage("Поставщик успешно удален.");
                    setSuppliersUpdate(true);
                  });
                }
              });
            }}
            danger
          />
        </Space>
      )
    }
  ];

  useEffect(() => {
    suppliersGetData({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters: {}
    });
    suppliersGetFilter();
  }, []);

  const sendRequest = () => {
    suppliersGetData({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: sorting,
      filters: filters
    });
  };

  useEffect(() => {
    if (isUpdate) {
      sendRequest();
      setSuppliersUpdate(false);
    }
  }, [isUpdate]);

  return (
    <>
      <Button
        style={{ marginLeft: "", display: "flex", columnGap: "6px", alignItems: "center" }}
        type="primary"
        onClick={() => setIsAddOpen(true)}
        loading={isLoadingLists}
        className="blueButton"
      >
        {projectIcons.suppliersIcon()}
        Добавить поставщика
      </Button>
      <div
        style={{
          display: "flex",
          columnGap: "16px",
          alignItems: "center",
          marginLeft: "",
          width: "40%",
          marginTop: "10px"
        }}
      >
        <Input
          onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
          style={{ width: "fit-content", minWidth: "180px" }}
          placeholder={"Поиск по вхождению..."}
        />
        <Select
          style={{ minWidth: "150px" }}
          placeholder="Есть телефон?"
          showSearch
          allowClear
          onChange={(value) => setFilters((prev) => ({ ...prev, phone_status: [Number(value)] }))}
          filterOption={(input, option) =>
            option && option.children
              ? (option.children as unknown as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              : false
          }
        >
          {Object.entries(lists).map(([key, value]) => (
            <Select.Option key={key} value={key}>
              {value as string}
            </Select.Option>
          ))}
        </Select>
        <Button
          style={{ display: "flex", columnGap: "2px", alignItems: "center", fontSize: "14px" }}
          className="blueButton"
          onClick={() => sendRequest()}
          type={"primary"}
        >
          Поиск <SearchOutlined style={{ fontSize: "16px" }} />
        </Button>
      </div>
      <Table
          virtual={true}
        style={{marginTop:"14px"}}
        showSorterTooltip={false}
        columns={defaultColumns}
        dataSource={itemData}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        pagination={{
          position: ["bottomLeft"],
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
          defaultCurrent: 1,
          defaultPageSize: 10,
          current: pagesData?.currentPage || 1,
          total: pagesData.total,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} из ${total} поставщиков`
        }}
        onChange={(pagination, tableFilters, sorting: any) => {
          const currentSort: { [x: string]: string } = sorting?.order
            ? {
                [sorting.columnKey]: sorting.order === "ascend" ? "ASC" : "DESC"
              }
            : {};

          setSorting(currentSort);
          suppliersGetData({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorting: currentSort,
            filters: filters
          });
        }}
        size="small"
        bordered
      />
      <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} filters={filters} />
      <EditModal isOpen={isEditOpen} setIsOpen={setIsEditOpen} editData={editData} filters={filters} />
    </>
  );
};

export default SuppliersTable;
