import React, { FC, useEffect } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors, showSuccessMessage } from "../../../utils/ui"
import TextArea from "antd/es/input/TextArea";

interface Props {
    isOpen: boolean
    setIsOpen: (v: boolean) => any
}

const AddCookiesModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.sber)
  const { sberAddCookies } = useActions()

  const addCookies = (values: any) => {
    sberAddCookies(
      values,
      () => {
        showSuccessMessage("Куки успешно добавлены.")
        setIsOpen(false)
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [ isOpen ])

  return (
    <Modal
      title="Добавление кук"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{ loading: isLoading,
        className:"blueButton"
       }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addCookies}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="client_token"
          name="client_token"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="cookies"
          name="cookies"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddCookiesModal
