import { StockSliceActions } from "./stockSlice"
import { stockApi } from "../../../common/api/endpoints/stockApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const stockActions = {
  ...StockSliceActions,
  inventarizationGetList: (isSocket=false) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchInventarization()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setInventoriesData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(stockActions.setStockLoading(false))
      })
  },
  stockGetList: () => (dispatch: TAppDispatch) => {
    dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setStockData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockActions.setStockLoading(false))
      })
  },

  stockCreate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      },

  stockEdit:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: {[key: string]: string}) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .edit(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      },

  stockDelete:
    (id: string | number, onFinish?: () => void) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .delete(id)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      }
}
