import React, { SetStateAction, useState } from "react"

import { Input, Modal } from "antd"

import { AddExportTask } from "../../../redux/slices/export/types"
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui"
import { exportApi } from "../../api/endpoints/exportApi"
type Props = {
    setVisible:React.Dispatch<SetStateAction<boolean>>
    dataToExport:AddExportTask
}
const ExportTable = ({ setVisible,dataToExport }:Props) => {
  const [ filename,setFilename ] = useState("")
  const handleCancel = () => {
    setVisible(false)
  }
  const handleAddTask = async () => {
    if(filename.length === 0){
      showErrorMessage("Введите название файла.")
    }else{
      try{
        const response = await exportApi.addExportTask({ ...dataToExport, name: filename })
        if(response.status === 200){
          showSuccessMessage("Задача на экспорт успешно добавлена.")
          setVisible(false)
        }
      }catch(err){
        showErrorMessage("Произошла ошибка во время добавления задачи на экспорт.")
      }
    }
  }
  return (
    <Modal open={true} onOk={handleAddTask} onCancel={handleCancel} title={"Экспорт таблицы"} okText={"Сохранить"}>
      <Input value={filename} onChange={(e)=>setFilename(e.target.value)} maxLength={255} placeholder={"Введите название файла"} />
    </Modal>
  )
}
export default ExportTable
