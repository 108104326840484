import React from "react"

type Props = {
    toggleVisibility:()=>void;
    isVisible:boolean
    children:React.ReactNode;
    title: string
}
const Accordion = ({ isVisible,toggleVisibility,children,title }:Props) => {
  return (
    <div className="importResultsContainer">
      <div className={`header ${isVisible ? "visible" :""}`} onClick={toggleVisibility}>
        <span>{title}</span>
        <span className={isVisible ? "arrowDown" : "arrowRight"}/>
      </div>
      <div className={`resultsList ${!isVisible && "hide"}`}>
        {children}
      </div>
    </div>
  )
}
export default Accordion
