export const routeNames = {
  // main
  home: "/",
  notFound: "/404",
  error: "/error",



  // settings
  monitoring: "/settings/monitoring",
  //telegram
  telegramNotifications:"/telegram/notifications",
  //reports
  staff: "/staff",
  itemCards: "/item-cards",
  monitoringMain: "/monitoring",
  importProducts: "/import/products",
  dropshipping: "/drop-shipping",
  dropshippingSupplier: "/products/supplier",
  prices:"/prices",
  sbermarket:"/sbermarket",
  partners: "/partners",
  imports: "/imports",
  stock:"/stock",
  inventorization:"/inventories",
  stockFBS:"/stock/fbs",
  collectOrders:"/collect-orders",
  stockSuppliers: "/stock/suppliers",
  export:"/export",
  suppliers:"/suppliers",
  analytics: "/analytics/orders-by-period",

  // auth
  login: "/login",
  resetPassword: "/reset-password",
  restorePassword: "/restore"
}
