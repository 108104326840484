import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnalyticsState, IOrderRow } from "./types";

const initialState: IAnalyticsState = {
    filterList: {
        partner_list: [],
        order_status_list: [],
        marketplace_list: [],
        suppliers_list: [],
    },

    orderData: [],
    analyticsData: [],
    pagesData: {
        total: 2,
        totalPages: 1,
        currentPage: 1,
        pageSize: 10
      },
    isLoadingFilters: false,
    isLoadingOrders: false
};

export const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setFilterList: (state, action: PayloadAction<any>) => {
            state.filterList = action.payload;
        },
        setOrderData: (state, action: PayloadAction<IOrderRow[]>) => {
            state.orderData = action.payload;
        },
        setPagesData: (state, action: PayloadAction<any>) => {
            state.pagesData = action.payload;
        },
        setLoadingFilters: (state, action: PayloadAction<boolean>) => {
            state.isLoadingFilters = action.payload;
        },
        setLoadingOrders: (state, action: PayloadAction<boolean>) => {
            state.isLoadingOrders = action.payload;
        }
    }
});

export const analyticsSliceReducer = analyticsSlice.reducer;
export const analyticsSliceActions = analyticsSlice.actions;
